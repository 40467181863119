import { Box, Container, Stack } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { PropsWithChildren, useEffect } from "react";
import { Footer } from "../../connected/footer/footer";
import { Header } from "../../connected/header/header";

export const LandingLayout = ({ children }: PropsWithChildren<unknown>) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Stack
      gap={0}
      style={{
        width: "100%",
        minHeight: "100vh",
        zIndex: 10000,
      }}
    >
      <Box
        style={{
          boxShadow: `0px 10px 19.2px 0px rgba(0, 0, 0, 0.05)`,
        }}
      >
        <Container size={"lg"}>
          <Header />
        </Container>
      </Box>
      <main>
        <Stack
          style={{
            flex: 1,
            height: "100%",
          }}
        >
          {children}
        </Stack>
      </main>

      <Footer />
    </Stack>
  );
};
