import API from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import { IRequestStatus } from "../../enums/enums";

const URL = "/admin/workspace/requests";

export const useGetAllRequests = (
  page: number,
  limit: number,
  status?: IRequestStatus
) => {
  const getAllRequests = async () => {
    if (!status) {
      const response = await API.get(`${URL}?page=${page}&limit=${limit}`);
      return response;
    }
    const response = await API.get(
      `${URL}?page=${page}&limit=${limit}&status=${status}`
    );
    return response;
  };
  const query = useQuery({
    queryFn: getAllRequests,
    queryKey: ["GetAllRequests", page, limit],
  });

  return query;
};

export const useGetRequestById = (requestId: string) => {
  const getRequestById = async () => {
    const response = await API.get(`${URL}/${requestId}`);
    return response;
  };
  const query = useQuery({
    queryFn: getRequestById,
    queryKey: [`GetRequestById-${requestId}`],
  });

  return query;
};
