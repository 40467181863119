import { Text } from "@mantine/core";

function InsightComponent({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        boxShadow: "0px 1px 2px 0px #1018280f",
        gap: "1em",
        width: "100%",
        padding: "1em",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Text c={"#101828"} m={0} p={0}>
          {title}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "500px",
          width: "100%",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default InsightComponent;
