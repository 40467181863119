import API from "../../api/api";
import { toast } from "react-toastify";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { CreateCertificateRequestDTO } from "../../schema/certificates";
import { useNavigate } from "react-router-dom";

const userURL = "/certificate";

export const useCreateCertificateRequest = (workspaceId: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const createCertificateRequest = async (
    data: CreateCertificateRequestDTO
  ) => {
    const response = await API.post(`${userURL}/${workspaceId}/create`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: createCertificateRequest,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["GetCertificateRequests"],
      });
      if (data.data.paymentUrl) {
        window.location.href = data.data.paymentUrl;
        return;
      }

      //redirect to request details
      navigate(`${process.env.REACT_APP_CLIENT_URL}/overview`);
      //invalidate queries list on page 1
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useGetCertificateRequests = (
  workspaceId: string,
  page: number,
  limit: number
) => {
  const getCertificateRequest = async () => {
    const response = await API.get(
      `${userURL}/${workspaceId}?page=${page}&limit=${limit}`
    );
    return response;
  };
  const query = useQuery({
    queryFn: getCertificateRequest,
    queryKey: ["GetCertificateRequests", page, limit, workspaceId],
  });
  return query;
};

export const useGetCertificateRequest = (
  workspaceId: string,
  requestId: string
) => {
  const getCertificateRequest = async () => {
    const response = await API.get(`${userURL}/${workspaceId}/${requestId}`);
    return response;
  };
  const query = useQuery({
    queryFn: getCertificateRequest,
    queryKey: ["GetCertificateRequest"],
  });
  return query;
};
