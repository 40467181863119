import API from "../../../api/api";

const URL = "/admin/requests";
type StatusType =
  | "PENDING"
  | "ONGOING_VERIFICATION"
  | "CANCELLED"
  | "COMPLETED"
  | "REJECTED"
  | "AWAITING_ASSIGNMENT";

export interface UpdateRequestStatusDto {
  status: StatusType;
}

interface IUpdateStatus {
  requestId: string;
  data: UpdateRequestStatusDto;
}

export const updateAdminRequestStatusApi = async ({
  requestId,
  data,
}: IUpdateStatus) => {
  const response = await API.post(
    `${URL}/${requestId}/updateStatus`,
    data
  ).then((response) => response.data);
  return response;
};
