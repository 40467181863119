import {
  Box,
  Container,
  Stack,
  Divider,
  Anchor,
  Title,
  Group,
  Text,
  Button,
} from "@mantine/core";
import { Country, State, City } from "country-state-city";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { KpaliLogo } from "../../assets/icons";
import { CustomCheckBox } from "../shared/inputs";
import { SignUpDto, SignUpSchema } from "../../../schema/auth/auth.";
import { WorkspaceType } from "../../../enums/enums";
import OrangeButton from "../shared/buttons/orangeButton";
import { useSignUp } from "../../../hooks/auth/auth.hook";
import {
  CustomTextInput,
  CustomPasswordInput,
  CustomSelectInput,
  CustomPhoneInput,
} from "../shared/inputs";
import { withLanding } from "../../../hoc/withLanding";
import { CreateUserDto } from "../../../hooks/auth/interface";

function SignUp() {
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    country: "NG,Nigeria",
    state: "",
    city: "",
    accountType: WorkspaceType.PERSONAL,
    acceptTerms: false,
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SignUpDto>({
    defaultValues: initialFormData,
    resolver: yupResolver(SignUpSchema),
  });

  const [countriesSelect, setCountriesSelect] = useState<any[]>([]);
  const [statesSelect, setStatesSelect] = useState<any[]>([]);
  const [citiesSelect, setCitiesSelect] = useState<any[]>([]);

  const { mutate, isPending } = useSignUp();

  const onSubmit = (data: SignUpDto) => {
    mutate(data);
  };

  const accountTypes = [
    { value: WorkspaceType.PERSONAL, label: "Personal" },
    { value: WorkspaceType.CORPORATE, label: "Business" },
  ];

  const watchCountry = watch("country");
  const watchState = watch("state");
  const accountType = watch("accountType");

  useEffect(() => {
    const countries = Country.getAllCountries();
    const countriesSelected = countries.map((country) => ({
      value: country.isoCode + "," + country.name,
      label: country.name,
    }));
    setCountriesSelect(countriesSelected);
  }, []);

  useEffect(() => {
    const countryValue = watch("country");
    const countryCode = countryValue?.split(",")[0];
    const states = State.getStatesOfCountry(countryCode);
    const statesSelected = states.map((state) => ({
      value: state.isoCode + "," + state.name,
      label: state.name,
    }));
    setStatesSelect(statesSelected);
  }, [watchCountry, watch]);

  useEffect(() => {
    const countryValue = watch("country");
    const stateValue = watch("state");
    const countryCode = countryValue?.split(",")[0];
    const stateCode = stateValue?.split(",")[0];
    const cities = City.getCitiesOfState(countryCode, stateCode);
    const citiesSelected = cities.map((city) => ({
      value: city.name,
      label: city.name,
    }));
    setCitiesSelect(citiesSelected);
  }, [watchState, watchCountry, watch]);

  return (
    <Stack
      bg="light-grey.3"
      gap={0}
      align="center"
      style={{
        width: "100%",
        minHeight: "100vh",
        zIndex: 10000,
      }}
    >
      <Box>
        <Group justify={"center"} align={"center"}>
          <div
            style={{
              marginTop: "4em",
            }}
          >
            <Divider color={"orange"} w={"100vw"} size={3} />
          </div>
        </Group>
      </Box>
      <Container size={"lg"} mb={{ base: "5em", md: "10em" }}>
        <Stack align="center" mt={"lg"}>
          <Link to={"/"}>
            <KpaliLogo />
          </Link>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              style={{
                borderRadius: "1em",
                border: "1px solid #E8E8E8",
              }}
              w={{ base: "20em", xs: "25em", md: "30em" }}
              p={{ base: "1em", xs: "2em", md: "3em" }}
              bg={"white"}
              align="center"
            >
              <Title order={2} fw={"600"} mt={"lg"} mb={"lg"}>
                {accountType === WorkspaceType.PERSONAL
                  ? "Personal"
                  : "Business"}
              </Title>
              <CustomSelectInput
                data={accountTypes}
                register={register("accountType")}
                value={{ value: watch("accountType") }}
                error={errors.accountType?.message}
              />
              {accountType === WorkspaceType.PERSONAL && (
                <Group grow justify={"space-between"}>
                  <CustomTextInput
                    label="Firstname"
                    placeholder={"Enter your firstname"}
                    register={register("firstName")}
                    error={errors.firstName?.message}
                  />
                  <CustomTextInput
                    label="Lastname"
                    placeholder={"Enter your lastname"}
                    register={register("lastName")}
                    error={errors.lastName?.message}
                  />
                </Group>
              )}
              {accountType === WorkspaceType.CORPORATE && (
                <CustomTextInput
                  label="Business Name"
                  placeholder={"Enter your business name"}
                  register={register("businessName")}
                  error={errors.businessName?.message}
                />
              )}
              <CustomTextInput
                label="Email"
                placeholder={"Enter your email"}
                register={register("email")}
                error={errors.email?.message}
              />
              <CustomPasswordInput
                label={"Password"}
                placeholder={"Enter your password"}
                register={register("password")}
                error={errors.password?.message}
              />
              <CustomPasswordInput
                label={"Confirm Password"}
                placeholder={"Re enter password"}
                register={register("confirmPassword")}
                error={errors.confirmPassword?.message}
              />
              <CustomPhoneInput
                label="Phone Number"
                value={watch("phoneNo")}
                error={errors.phoneNo?.message}
                onChange={(value) => {
                  setValue("phoneNo", value);
                }}
              />
              <CustomSelectInput
                label={"Country"}
                data={countriesSelect}
                value={{ value: watch("country") }}
                register={register("country")}
                error={errors.country?.message}
                onChange={() => {
                  setValue("state", "");
                  setValue("city", "");
                }}
              />
              <CustomSelectInput
                label={"State"}
                data={statesSelect}
                value={{ value: watch("state") }}
                register={register("state")}
                error={errors.state?.message}
                onChange={() => {
                  setValue("city", "");
                }}
              />
              <CustomSelectInput
                label={"City"}
                data={citiesSelect}
                value={{ value: watch("city") }}
                error={errors.city?.message}
                register={register("city")}
                notRequired
              />
              <CustomCheckBox
                label={
                  <Text size="sm">
                    I agree to both{" "}
                    <Anchor
                      color="blue"
                      size="sm"
                      href="https://storage.googleapis.com/iverify-public/forms/consent/terms-of-use-01082022.docx.pdf"
                      target={"_blank"}
                    >
                      {"Terms of Service"}
                    </Anchor>{" "}
                    and{" "}
                    <Anchor
                      color="blue"
                      size="sm"
                      href="https://storage.googleapis.com/iverify-public/forms/consent/privacy-policy-01082022.pdf"
                      target={"_blank"}
                    >
                      {"Privacy Policy"}
                    </Anchor>
                  </Text>
                }
                register={register("acceptTerms")}
                error={errors.acceptTerms?.message}
              />
              {/* <OrangeButton
              buttonText={"Sign-up"}
              
              onClick={
                handleSubmit(onSubmit)
              }
              loading={isPending}
            /> */}
              <Button type="submit" fullWidth loading={isPending}>
                Sign-up
              </Button>
            </Stack>
          </form>
          <Text c={"light-grey.8"}>
            Already have an account? &nbsp;
            <Anchor c={"orange.4"} href="/login">
              Login
            </Anchor>
          </Text>
        </Stack>
      </Container>
    </Stack>
  );
}

export default withLanding(SignUp);
