import { Stack, Checkbox } from "@mantine/core";
import PropTypes from "prop-types";
import styles from "./inputs.module.css";

export function CustomCheckBox({
  disabled,
  width,
  label,
  placeholder,
  radius,
  size,
  mobileWhite,
  form,
  register,
  value,
  error,
  onChange,
  checked,
}: PropTypes.InferProps<typeof CustomCheckBox.propTypes>) {
  return (
    <Stack w={width ? width : "100%"} mb={"0.1em"}>
      <Checkbox
        disabled={disabled ? true : false}
        label={label}
        c={
          mobileWhite ? { base: "white", sm: "white", md: "dark.4" } : "dark.4"
        }
        {...(checked && { checked: checked })}
        size={size ? size : "sm"}
        error={error ? error : null}
        radius={radius ? radius : "md"}
        {...(onChange && { onChange: onChange })}
        {...value}
        {...register}
        placeholder={placeholder ? placeholder : ""}
        classNames={{
          input: styles.input,
          label: styles.label,
        }}
        {...form}
      />
    </Stack>
  );
}

CustomCheckBox.propTypes = {
  label: PropTypes.element || PropTypes.string,
  form: PropTypes.object,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  radius: PropTypes.string,
  mobileWhite: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.object,
  register: PropTypes.object,
  error: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};

export default CustomCheckBox;
