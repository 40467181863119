import { Center } from "@mantine/core";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export interface ISocialLink {
  link: string;
  icon: ReactNode;
}

export const SocialLink = ({ link, icon }: ISocialLink) => {
  return (
    <Center
      w={"1.8em"}
      h={"1.8em"}
      style={(theme) => ({
        borderRadius: "50%",
        backgroundColor: theme.colors["light-grey"][0],
      })}
    >
      <Link to={link} target="_blank">
        {icon}
      </Link>
    </Center>
  );
};
