import { Button, Group, Stack } from "@mantine/core";
import { CustomFileInput } from "../../../components/primitives/shared/inputs";
import { useForm } from "react-hook-form";
import { updateCertificateRecordApi } from "../../../hooks/admin/certificate.hook";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

export const UploadReport = ({
  record,
  closeUpload,
  refetch,
}: {
  record: any;
  closeUpload: () => void;
  refetch?: () => void;
}) => {
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      reportUrl: record.reportUrl,
    },
    // resolver: yupResolver(CreateCertificateRequestSchema),
  });

  const updateRecord = useMutation({
    mutationFn: updateCertificateRecordApi,
    onSuccess: () => {
      toast.success("Certificate Record Update Successfully");
      if (closeUpload) closeUpload();
      if (refetch) refetch();
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });

  const onSubmit = (data: any) => {
    updateRecord.mutate({
      ...data,
      id: record.id,
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <CustomFileInput
          label="Completed Report"
          register={register("reportUrl")}
          setValue={setValue}
          getValues={getValues}
          //   error={errors?.}
          placeholder={"Upload report"}
        />
        <Group mt={"md"}>
          <Button
            type="submit"
            // disabled={updateRecord.isLoading}
            // loading={updateRecord.isLoading}
          >
            Done
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
