import { Text, Stack, Flex, UnstyledButton } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../../../hooks/useResponsive";
import bannerImage from "../../../assets/images/bannerImage.png";
import { useCurrentWorkspace } from "../../../../hooks/user/user.workspace.hook";
import { greetUser } from "../../shared/reusableFunctions";

const Banner = () => {
  const { currentUser } = useCurrentWorkspace();
  const navigate = useNavigate();
  let name = currentUser?.businessName
    ? currentUser?.businessName
    : `${currentUser?.firstName} ${currentUser?.lastName}`;
  const getBackgroundFilterByTimeOfDay = () => {
    const date = new Date();
    const hours = date.getHours();
    if (hours >= 6 && hours < 12) return "brightness(1)";
    if (hours >= 12 && hours < 18) return "brightness(0.8)";
    return "brightness(0.4)";
  };
  const getBackgroundColourByTimeOfDay = () => {
    const date = new Date();
    const hours = date.getHours();
    if (hours >= 6 && hours < 12) return "rgba(232, 98, 23, 0.8)";
    if (hours >= 12 && hours < 18) return "rgba(232, 98, 23, 0.6)";
    return "rgba(232, 98, 23, 0.4)";
  };

  const Button = ({
    title,
    onClick,
  }: {
    title: string;
    onClick: () => void;
  }) => {
    return (
      <UnstyledButton
        onClick={onClick}
        c={"white"}
        td={"underline"}
        fw={500}
        ta={"center"}
        w={{ base: "100%", md: "auto" }}
        fz={{ base: "1.25rem", md: "1rem" }}
        style={{
          backgroundColor: "transparent",
        }}
      >
        {title}
      </UnstyledButton>
    );
  };

  const { isMobile } = useResponsive();

  const handleManageUsers = () => {
    navigate("/admin/users");
  };

  const handleBrowseRequests = () => {
    navigate("/admin/requests");
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundImage: `url(${bannerImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top",
        borderRadius: "8px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          //   backgroundColor: "rgba(232, 98, 23, 0.8)",
          backgroundColor: getBackgroundColourByTimeOfDay(),
          //   backdropFilter: getBackgroundFilterByTimeOfDay(),
          display: "flex",
          flexDirection: "column",
          padding: isMobile ? "20px" : "32px",
          gap: "2rem",
          borderRadius: "8px",
        }}
      >
        <Stack gap={0}>
          <Text fw={500} fz={isMobile ? "1.25rem" : "1.5rem"} c={"white"}>
            {`${greetUser()} ${name?.split(" ")[0]}!`}
          </Text>
          <Text
            fz={isMobile ? "0.8rem" : "1rem"}
            c={"white"}
            style={{ maxWidth: "500px", lineHeight: "1.1" }}
          >
            Welcome to your dashboard, here you can manage users, workspaces,
            view analytics and lots more.
          </Text>
        </Stack>
        <Flex
          align={"center"}
          justify={"flex-start"}
          gap={{ base: "10px", md: "40px" }}
          direction={isMobile ? "column" : "row"}
          ta={isMobile ? "center" : "left"}
        >
          <UnstyledButton
            onClick={() => navigate("/admin/analytics")}
            bg={"white"}
            p={"10px"}
            fw={500}
            ta={"center"}
            w={{ base: "100%", md: "auto" }}
            fz={{ base: "1.25rem", md: "1rem" }}
            style={{
              borderRadius: "8px",
            }}
          >
            View Analytics
          </UnstyledButton>
          {!isMobile && (
            <>
              <Button onClick={handleManageUsers} title="Manage Users" />
              <Button onClick={handleBrowseRequests} title="Browse Requests" />
            </>
          )}

          {isMobile && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button onClick={handleManageUsers} title="View" />
              <Button onClick={handleBrowseRequests} title="Create" />
            </div>
          )}
        </Flex>
      </div>
    </div>
  );
};

export default Banner;
