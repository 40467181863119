import { useState } from "react";
import { IoDocument } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CustomCheckBox } from "../../../shared/inputs";
import { ActitonButton } from "../users/users";
import AdminLayout from "../../../../layouts/adminLayout";
import CustomTable from "../../../shared/table/customTable";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
import { Text } from "@mantine/core";
import { useGetAllPayments } from "../../../../../hooks/admin/payments.hook";
import PaymentStatusText from "../../../shared/paymentStatus";

function AdminPayments() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, isPending } = useGetAllPayments(page, 10);

  const paymentData = data?.data?.data;
  const paymentCount = data?.data?.count;
  const paymentTotalPages = data?.data?.totalPages;
  const paymenrCurrentPage = data?.data?.currentPage;

  const TableData = paymentData?.map((payment: any, index: number) => {
    return {
      request: (
        <div
          style={{
            display: "flex",
            gap: "1em",
            alignItems: "center",
            cursor: "pointer",
            color: "#e86217",
          }}
          onClick={() => navigate(`/admin/requests/${payment?.request?.id}`)}
        >
          <IoDocument size={20} />
          <Text> {payment?.request?.serviceCategory}</Text>
        </div>
      ),
      reference: payment?.trxRef?.toUpperCase(),
      ampunt: `${payment?.currency} ${Number(
        payment?.amount
      )?.toLocaleString()}`,
      data: `${payment?.createdAt?.split("T")[0]} ${
        payment?.createdAt?.split("T")[1].split(".")[0]
      }`,
      status: <PaymentStatusText status={payment?.status} />,
      // action: <ActitonButton onClick={() => handleEdit(service?.id)} />,
    };
  });

  return (
    <AdminLayout title="Payments">
      <CustomTable
        isTableLoading={isPending}
        currentPage={paymenrCurrentPage}
        totalPages={paymentTotalPages}
        setPage={setPage}
        count={paymentCount}
        title={"Payments"}
        bodyRows={TableData}
        labels={[
          "Service Request",
          "Reference",
          "Amount",
          "Date",
          "Status",
          "",
        ]}
        minWidth={"850px"}
        emptyState={{
          title: "No Payments Found",
          description: "They are currently no payments available on Kpali",
          height: "100px",
          width: "175px",
          image: GhostIcon,
        }}
      />
    </AdminLayout>
  );
}

export default AdminPayments;
