import { Text, Group, Stack, Title, Image, Flex } from "@mantine/core";
import { LandingLayout } from "../components/layouts/landing";
import OurTeam from "../components/primitives/aboutPage/ourTeam";
import AboutImage from "../components/primitives/aboutPage/aboutImage";
import WhoWeAreImage from "../components/assets/images/whoWeAreImage.png";
import AboutDescription from "../components/primitives/aboutPage/aboutDescription";
import OrangeCheckIcon from "../../src/components/assets/svg/orangeCheckIcon.svg";
import MissionIcon from "../../src/components/assets/svg/missionIcon.svg";
import VisiionIcon from "../../src/components/assets/svg/visionIcon.svg";
import { withLanding } from "../hoc/withLanding";

const data = [
  {
    icon: OrangeCheckIcon,
    header: "About Us",
    description:
      "Kpali is a product of Iverify.ng, a digital Identity and background check company. We are technology driven and integrate proven expertise to provide holistic verification. We provides access to business, basic services and ensures compliance in business processes.",
  },
  {
    icon: MissionIcon,
    header: "Our Mission",
    description:
      "To accelerate digital inclusion and economic growth through accessed to verified data in Africa.",
  },
  {
    icon: VisiionIcon,
    header: "Our Vision",
    description:
      "To provide accurate, reliable and comprehensive information about individuals to support business processes, drive sustainability and promote data driven decision making by organizations and stakeholders.",
  },
];

const About = () => {
  return (
    <LandingLayout>
      <AboutImage image={WhoWeAreImage} headerText={"Who we are"} />
      <AboutDescription>
        <Title
          order={2}
          ta="left"
          fz={"1.75em"}
          pt={"lg"}
          style={{
            letterSpacing: " -0.00625rem",
          }}
        >
          Helping Organisations to be exceptional
        </Title>
        <Group>
          {data.map((_, index) => (
            <Flex gap={"1em"} key={index}>
              <Image
                bg={"#FEEEE5"}
                p={"0.5em"}
                radius={"50%"}
                src={_.icon}
                w={"45"}
                h={"45"}
              />
              <Stack gap={"0.3em"}>
                <Text fw={700}>{_.header}</Text>
                <Text>{_.description}</Text>
              </Stack>
            </Flex>
          ))}
        </Group>
      </AboutDescription>
      <OurTeam />
    </LandingLayout>
  );
};

export default withLanding(About);
