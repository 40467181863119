import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Anchor, Stack } from "@mantine/core";
import { CustomPasswordInput } from "../shared/inputs";
import AuthLayout from "../../layouts/auth";
import OrangeButton from "../shared/buttons/orangeButton";
import { useResetPassword } from "../../../hooks/auth/auth.hook";
import {
  ResetPasswordSchema,
  ResetPasswordDto,
} from "../../../schema/auth/auth.";

export interface IResetPassword {
  newPassword: string;
  confirmPassword: string;
}

function ResetPassword() {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const userId = urlParams.get("userId") || undefined;
  const code = urlParams.get("code") || undefined;
  const { mutate, isPending } = useResetPassword();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordDto>({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    mutate({
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
      userId,
      code,
    });
  };
  return (
    <AuthLayout title="Create New Password">
      <Stack w={"100%"}>
        <CustomPasswordInput
          mobileWhite
          label={"New Password"}
          placeholder={"Enter password"}
          register={register("newPassword")}
          error={errors.newPassword?.message}
        />
        <CustomPasswordInput
          mobileWhite
          label={"Confirm Password"}
          placeholder={"Confirm new password"}
          register={register("confirmPassword")}
          error={errors.confirmPassword?.message}
        />
        <OrangeButton
          loading={isPending}
          buttonText={"Reset password"}
          onClick={handleSubmit(onSubmit)}
        />
        <Text>
          <Anchor c={{ base: "white", sm: "white", md: "dark" }} href="/login">
            Remember password?
          </Anchor>
        </Text>
      </Stack>
    </AuthLayout>
  );
}

export default ResetPassword;
