import Skeleton from "react-loading-skeleton";
import { Stack } from "@mantine/core";

const UserMenuLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.5em",
        width: "100%",
        maxWidth: "200px",
      }}
    >
      <div style={{ width: "30px", maxWidth: "60px", borderRadius: "50%" }}>
        <Skeleton circle height={30} />
      </div>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Stack gap={"0.05em"}>
          <Skeleton height={25} />
          <Skeleton height={15} />
        </Stack>
      </div>
    </div>
  );
};

export default UserMenuLoader;
