import API from "../../api/api";
import { useQuery } from "@tanstack/react-query";

const URL = "/services";

export const useGetAllServices = () => {
  const getAllServices = async () => {
    const response = await API.get(URL);
    return response;
  };

  const query = useQuery({
    queryFn: getAllServices,
    queryKey: ["GetAllServices"],
  });

  return query;
};
