import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Text, Stack, Button } from "@mantine/core";
import { CustomCheckBox } from "../../../shared/inputs";
import { ServiceCategory } from "../../../../../enums/enums";
import { useGetAllRequests } from "../../../../../hooks/admin/requests.hook";
import { ActitonButton } from "../users/users";
import AdminLayout from "../../../../layouts/adminLayout";
import CustomTable from "../../../shared/table/customTable";
import RequestStatusText from "../../../shared/requestStatus";
import { formatDate } from "../../../shared/reusableFunctions";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
import { useMutation } from "react-query";
import { updateAdminRequestStatusApi } from "../../../../../hooks/apis/admin/requests";
import { toast } from "react-toastify";

function AdminRequests() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, isPending, refetch } = useGetAllRequests(page, 10);
  const requestsData = data?.data?.data;
  const requestsCount = data?.data?.count;
  const requestsTotalPages = data?.data?.totalPages;
  const requestsCurrentPage = data?.data?.currentPage;

  const updateStatus = useMutation({
    mutationFn: updateAdminRequestStatusApi,
    onSuccess: () => {
      toast.success("Request marked as completed");
      refetch();
    },
  });

  const handleNavigate = (id: string) => {
    navigate(`/admin/requests/${id}`);
  };

  const TableData = requestsData?.map((request: any, index: number) => {
    return {
      checbox: <CustomCheckBox />,
      id: request?.certificates[0]?.applicantName,
      serviceCategory: (
        <Stack gap={"0"}>
          <Text>{request?.serviceCategory}</Text>
          <Text fz={"sm"}>
            {request?.serviceCategory === ServiceCategory.CERTIFICATE
              ? request?.certificates[0]?.certificateType
                  ?.charAt(0)
                  .toUpperCase() +
                request?.certificates[0]?.certificateType
                  ?.slice(1)
                  .toLowerCase()
              : request?.documents[0]?.documentType?.charAt(0).toUpperCase() +
                request?.documents[0]?.documentType?.slice(1).toLowerCase()}
          </Text>
        </Stack>
      ),
      date: formatDate(request?.createdAt),
      status: <RequestStatusText status={request?.status} />,
      action: <ActitonButton onClick={() => handleNavigate(request?.id)} />,
      UpdateStatus: (
        <div>
          {request?.status !== "COMPLETED" ? (
            <Button
              color="green"
              onClick={() =>
                updateStatus.mutate({
                  requestId: request?.id,
                  data: {
                    status: "COMPLETED",
                  },
                })
              }
            >
              Mark as completed
            </Button>
          ) : null}
        </div>
      ),
    };
  });

  return (
    <AdminLayout title="Requests">
      <CustomTable
        isTableLoading={isPending}
        currentPage={requestsCurrentPage}
        totalPages={requestsTotalPages}
        setPage={setPage}
        count={requestsCount}
        title={"Requests"}
        bodyRows={TableData}
        labels={[
          <CustomCheckBox />,
          "Tracking",
          "Service",
          "Date",
          "Status",
          "",
        ]}
        minWidth={"850px"}
        emptyState={{
          title: "No Requests",
          description: "No requests have been made on Kpali yet",
          height: "100px",
          width: "175px",
          image: GhostIcon,
        }}
      />
    </AdminLayout>
  );
}

export default AdminRequests;
