import { LandingLayout } from "../components/layouts/landing";
import AboutImage from "../components/primitives/aboutPage/aboutImage";
import ContactUsImage from "../components/assets/images/contactUs.png";

import ContactUs from "../components/primitives/contactPage.tsx/contactUs";
import FaqQuestions from "../components/primitives/contactPage.tsx/questions";
import { withLanding } from "../hoc/withLanding";

const Contact = () => {
  return (
    <LandingLayout>
      <AboutImage image={ContactUsImage} headerText={"Connect with us"} />
      <ContactUs />
      <FaqQuestions />
    </LandingLayout>
  );
};

export default withLanding(Contact);
