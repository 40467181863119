import API from "../../api/api";
import { useQuery } from "@tanstack/react-query";

const URL = "/admin/dashboard";

export const useGetAdminDashboard = () => {
  const getAdminDashboard = async () => {
    const response = await API.get(URL);
    return response.data;
  };
  const query = useQuery({
    queryFn: getAdminDashboard,
    queryKey: ["GetAdminDashboard"],
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    refetchOnMount: true,
  });

  return query;
};
