import { Card, Text, Flex, Image } from "@mantine/core";

interface ProcessCardProps {
  step: string;
  description: string;
  image: string;
}

const ProcessCard = ({ step, description, image }: ProcessCardProps) => {
  return (
    <Card shadow="sm" pl={"2.8em"} pr={"2.8em"} pb={"0"} radius="xl">
      <Flex
        justify={"space-between"}
        align={"center"}
        direction={"column"}
        gap={"xs"}
      >
        <Text fw={300} c={"#A9A9A"}>
          {step}
        </Text>
        <Text fw={500} fz={"1.5em"}>
          {description}
        </Text>
        <Card h={"100%"} w={"100%"} bg={"#FDEDE4"} radius={"xl"}>
          <Image
            w={"100%"}
            radius={"xl"}
            pt={"1em"}
            pl={"1em"}
            pr={"1em"}
            src={image}
            alt={description}
          />
        </Card>
      </Flex>
    </Card>
  );
};

export default ProcessCard;
