import { Button, Modal, Menu } from "@mantine/core";

import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { IconTrash } from "@tabler/icons-react";
import { CloudUpload, Edit, MailForward } from "tabler-icons-react";
import { useDisclosure } from "@mantine/hooks";

import { EditStatusForm } from "../../../forms/admin/requestDetails/editStatus";
import { UploadReport } from "../../../forms/admin/requestDetails/uploadReport";

export const CertificateThreeMenu = ({
  record,
  workspaceId,
  refetch,
}: {
  record: any;
  refetch?: () => void;
  workspaceId?: string;
}) => {
  const [edtiOpened, { open: openEdit, close: closeEdit }] =
    useDisclosure(false);
  const [uploadOpened, { open: openUpload, close: closeUpload }] =
    useDisclosure(false);

  const hanldleCloseUploadModal = () => {
    closeUpload();
    if (refetch) refetch();
  };

  return (
    <div>
      <Menu shadow="md" width={200} position="bottom" offset={5}>
        <Menu.Target>
          <Button>
            <IoEllipsisHorizontalSharp />
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item leftSection={<Edit size={14} />} onClick={openEdit}>
            Edit Status
          </Menu.Item>
          <Menu.Item
            leftSection={<CloudUpload size={14} />}
            onClick={openUpload}
          >
            Upload Report
          </Menu.Item>
          <Menu.Item disabled leftSection={<MailForward size={14} />}>
            Resend Report
          </Menu.Item>

          <Menu.Item color="red" disabled leftSection={<IconTrash size={14} />}>
            Delete Request
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <Modal opened={edtiOpened} onClose={closeEdit} title="Edit Status">
        <EditStatusForm
          record={record}
          closeEdit={closeEdit}
          workspaceId={workspaceId!}
        />
      </Modal>
      <Modal opened={uploadOpened} onClose={closeUpload} title="Update Report">
        <UploadReport
          record={record}
          closeUpload={hanldleCloseUploadModal}
          refetch={refetch}
        />
      </Modal>
    </div>
  );
};
