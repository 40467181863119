import * as firebase from 'firebase/app';
import * as firebaseAuth from 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

const firebaseApp = firebase.initializeApp(config);
const getFirebaseAuth = firebaseAuth.getAuth(firebaseApp);

export { firebaseApp, getFirebaseAuth };
export default firebaseApp;
