import React, { useMemo, useState } from "react";
import {
  Stack,
  Group,
  Title,
  Button,
  SegmentedControl,
  Avatar,
  Text,
  Table,
  ScrollArea,
  LoadingOverlay,
  Paper,
  Select,
  Modal,
  rem,
  Divider,
} from "@mantine/core";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCurrentWorkspace,
  useGetWorkspaceMembers,
  useGetWorkspaceInvites,
  useInviteMember,
  useDeleteInvite,
  useUpdatePermission,
  useDeleteWorkspaceMember,
} from "../../../../../../hooks/user/user.workspace.hook";
import { getNameInitials } from "../../../../shared/reusableFunctions";
import EmptyState from "../../../../shared/emptyState";
import { IWorkspaceRole, WorkspaceRole } from "../../../../../../enums/enums";
import { CustomTextInput } from "../../../../shared/inputs";
import styles from "../../../../shared/inputs/inputs.module.css";
import GhostIcon from "../../../../../assets/svg/ghostIcon.svg";
import {
  CreateWorkspaceInviteSchema,
  CreateWorkspaceInviteDto,
} from "../../../../../../schema/workspace/workspace";
import WorkspaceRBAC from "./rbac";

export const selectPermissionData = [
  { value: WorkspaceRole.OWNER, label: "Owner" },
  { value: WorkspaceRole.ADMIN, label: "Admin" },
  { value: WorkspaceRole.VIEWER, label: "Viewer" },
  { value: WorkspaceRole.REQUESTER, label: "Requester" },
];

interface IChangeRole {
  defaultRole: IWorkspaceRole;
  onRoleChange: (value: IWorkspaceRole) => void;
  disabled: boolean;
  label?: string | React.ReactNode;
  size?: string;
  errors?: string;
}
const ChangeUserRole = ({
  defaultRole,
  onRoleChange,
  disabled,
  label,
  size,
  errors,
}: IChangeRole) => {
  return (
    <Select
      label={label}
      {...(defaultRole && { defaultValue: defaultRole })}
      data={selectPermissionData}
      size={size ? size : "md"}
      classNames={{
        input: styles.input,
        wrapper: styles.wrapper,
        label: styles.label,
      }}
      error={errors ? errors : null}
      disabled={disabled}
      onChange={(value: any) => {
        onRoleChange(value);
      }}
    />
  );
};

function WorkspaceMembers() {
  const { workspaceDetails: workspace, currentUser } = useCurrentWorkspace();

  type TActiveTab = "active" | "inactive";
  const [activeTab, setActiveTab] = useState<TActiveTab>("active");
  const { mutate: updatePermission, isPending: isUpdatingPermission } =
    useUpdatePermission(workspace?.id);
  const [isCreateInvitationModalOpen, setCreateInvitationModalOpen] =
    useState(false);
  const { data: members, isLoading: isTeamMembersLoading } =
    useGetWorkspaceMembers(workspace?.id);
  const { data: invites, isLoading: isInvitesLoading } = useGetWorkspaceInvites(
    workspace?.id
  );
  const { mutate: inviteMember, isPending: isInviting } = useInviteMember(
    workspace?.id
  );
  const { mutate: deleteInvite, isPending: isDeleting } = useDeleteInvite(
    workspace?.id
  );
  const { mutate: deleteMember, isPending: isDeletingMember } =
    useDeleteWorkspaceMember(workspace?.id);

  const workspaceMembers = useMemo(() => members?.data, [members]);
  const workspaceInvites = useMemo(() => invites?.data, [invites]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateWorkspaceInviteDto>({
    defaultValues: {
      email: "",
      role: WorkspaceRole.ADMIN,
    },
    resolver: yupResolver(CreateWorkspaceInviteSchema),
  });

  const role = watch("role");

  const onSubmit: SubmitHandler<CreateWorkspaceInviteDto> = (data) => {
    inviteMember(data, {
      onSuccess: () => {
        setCreateInvitationModalOpen(false);
        setActiveTab("inactive");
      },
    });
  };

  const workspaceRoleDescription = {
    REQUESTER:
      "Requesters can submit new service requests but cannot access features for an ADMIN or OWNER",
    VIEWER:
      "Viewers can only view requests. They can't create/edit requests, manage workspace. This role is fit for external invites, or analysts.",
    ADMIN:
      "Admin can manage users with their permissions, view and manage requests. They cannot manage other admins.",
    OWNER:
      "Owner has access to the entire workspace, and is the highest role level. They also have necessar permissions to delete a workspace.",
  };

  return (
    <WorkspaceRBAC action={"canViewWorkspaceMembers"} renderNoAccess>
      <Stack gap={"1em"}>
        <Group justify="space-between">
          <Title order={2}>Workspace Members</Title>
          <Button onClick={() => setCreateInvitationModalOpen(true)}>
            Invite Members
          </Button>
        </Group>
        <div>
          <SegmentedControl
            bg={"light-grey.7"}
            value={activeTab}
            onChange={(data: any) => setActiveTab(data)}
            data={[
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
            ]}
          />

          {activeTab === "active" && (
            <div>
              <Paper
                style={{
                  borderColor: "#E8E8E8",
                }}
                shadow="xs"
                withBorder={true}
                radius="md"
                mt="md"
                p={"md"}
              >
                {isTeamMembersLoading ? (
                  <div style={{ height: 200, position: "relative" }}>
                    <LoadingOverlay visible />
                  </div>
                ) : (
                  <ScrollArea>
                    <Table
                      horizontalSpacing="xl"
                      verticalSpacing="sm"
                      highlightOnHover
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Name
                          </th>

                          <th style={{ width: 150, textAlign: "left" }}>
                            Role
                          </th>
                          <th style={{ width: 100 }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {workspaceMembers?.map((member: any) => {
                          const name =
                            member?.businessName ||
                            `${member?.user?.firstName} ${member?.user?.lastName}`;
                          const email = member?.user?.email;
                          return (
                            <tr key={member?.id}>
                              <td>
                                <Group gap="sm">
                                  <Avatar radius="xl" color="#E86217">
                                    {getNameInitials({
                                      name: name,
                                      maxInitials: 2,
                                    })}
                                  </Avatar>

                                  <div>
                                    <Text size="sm" fw={500}>
                                      {name}
                                    </Text>
                                    <Text size="sm" fw={500} c="gray">
                                      {email}
                                    </Text>
                                  </div>
                                </Group>
                              </td>
                              <td>
                                <ChangeUserRole
                                  size="sm"
                                  defaultRole={member?.mainRole}
                                  disabled={
                                    member?.userId === currentUser?.id ||
                                    isUpdatingPermission
                                  }
                                  onRoleChange={(role: IWorkspaceRole) => {
                                    updatePermission({
                                      memberId: member?.userId,
                                      role,
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                {member.userId === currentUser?.id ? null : (
                                  <Button
                                    loading={isDeletingMember}
                                    color="dark"
                                    variant="subtle"
                                    size="xs"
                                    onClick={() => {
                                      deleteMember(member?.userId);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </ScrollArea>
                )}
              </Paper>
            </div>
          )}

          {activeTab === "inactive" && (
            <div>
              {workspaceInvites && workspaceInvites?.length > 0 ? (
                <Paper
                  style={{
                    borderColor: "#E8E8E8",
                  }}
                  shadow="xs"
                  withBorder={true}
                  radius="md"
                  mt="md"
                  p={"md"}
                >
                  {isInvitesLoading ? (
                    <div style={{ height: 200, position: "relative" }}>
                      <LoadingOverlay visible />
                    </div>
                  ) : (
                    <ScrollArea>
                      <Table
                        horizontalSpacing="xl"
                        verticalSpacing="sm"
                        highlightOnHover
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "left",
                              }}
                            >
                              Email
                            </th>
                            <th style={{ width: 150, textAlign: "left" }}>
                              Role
                            </th>
                            <th style={{ width: 100 }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {workspaceInvites?.map((member: any) => (
                            <tr key={member?.id}>
                              <td>
                                <Text size="sm" fw={500} c="gray">
                                  {member?.email}
                                </Text>
                              </td>
                              <td>
                                <Text size="sm" fw={500} c="gray">
                                  {member?.role}
                                </Text>
                              </td>
                              <td>
                                <Button
                                  loading={isDeleting}
                                  // color="dark"
                                  // variant="subtle"
                                  variant="default"
                                  size="xs"
                                  onClick={() => {
                                    deleteInvite(member.id);
                                  }}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </ScrollArea>
                  )}
                </Paper>
              ) : (
                <EmptyState
                  image={GhostIcon}
                  title="No Pending Invites"
                  description="There are no pending or unaccepted invites for your workspace"
                />
              )}
            </div>
          )}
        </div>
        <Modal
          opened={isCreateInvitationModalOpen}
          onClose={() => setCreateInvitationModalOpen(false)}
          title="Invite Workspace Member"
          centered
          size={"md"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={0} style={{ padding: rem(10) }}>
              <CustomTextInput
                notRequired
                label={
                  <Stack gap={0}>
                    <Text size="sm">Members Email</Text>
                  </Stack>
                }
                placeholder={"Enter email address"}
                register={register("email")}
                error={errors.email?.message}
              />
              <ChangeUserRole
                label={
                  <Stack gap={0}>
                    <Text size="sm">Members Role</Text>
                  </Stack>
                }
                defaultRole={WorkspaceRole.ADMIN}
                disabled={false}
                onRoleChange={(value) => setValue("role", value)}
                errors={errors.role?.message}
              />
              {role &&
              workspaceRoleDescription[
                role as keyof typeof workspaceRoleDescription
              ] ? (
                <Text mt={10} c={"light-grey.8"} fz={"sm"}>
                  {
                    workspaceRoleDescription[
                      role as keyof typeof workspaceRoleDescription
                    ]
                  }
                </Text>
              ) : null}
            </Stack>
            <Group justify="flex-end" mt={"lg"}>
              <Button
                onClick={() => setCreateInvitationModalOpen(false)}
                variant="default"
              >
                Cancel
              </Button>
              <Button
                loading={isInviting}
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Group>
          </form>
        </Modal>
      </Stack>
    </WorkspaceRBAC>
  );
}

export default React.memo(WorkspaceMembers);
