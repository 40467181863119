import { LoadingOverlay } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFirebaseAuth } from "../services/firebase";

//this HOC checks if user is logged in
//if YES, the user is redirected to Dashboard
//else, they are allowed on same component
//Useful for login, forgotpassword, etc...

interface IProps {
  children?: React.ReactNode;
}

export function withLanding<T extends IProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentwithAuth = (props: T) => {
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useLocalStorage<string | null>({
      key: "kpali-redirect-url",
      defaultValue: null,
    });
    const [user, loading] = useAuthState(getFirebaseAuth);
    useEffect(() => {
      if (loading) return; // Do nothing while loading
      if (user) {
        const previousReidrectUrl = redirectUrl;
        navigate(
          previousReidrectUrl && ![null, "null"].includes(previousReidrectUrl)
            ? decodeURIComponent(previousReidrectUrl)
            : "/overview"
        );
        //clear reidrect
        setTimeout(() => setRedirectUrl(null), 3000);
      } // If authenticated, redirect to dashboard
    }, [user, loading, navigate, redirectUrl, setRedirectUrl]);

    if (!loading && !user) return <WrappedComponent {...props} />;
    return <LoadingOverlay visible />;
  };

  ComponentwithAuth.displayName = `withLanding(${displayName})`;

  return ComponentwithAuth;
}
