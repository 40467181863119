import {
  Box,
  Button,
  Card,
  CheckIcon,
  Group,
  Image,
  Stack,
  Text,
} from "@mantine/core";
import { Link } from "react-router-dom";

export type PriceType = {
  currency: string;
  amount: number;
};

interface PriceCardProps {
  actionTexts: string[];
  price: PriceType;
  imgSrc: string;
  serviceType: string;
  to: string;
}

const PriceCard = ({
  actionTexts,
  price,
  imgSrc,
  serviceType,
  to,
}: PriceCardProps) => {
  return (
    <Card shadow="sm" padding="lg" radius="md">
      <Card.Section
        style={{
          position: "relative",
        }}
      >
        <Image src={imgSrc} h={120} alt="Norway" fit="fill" />
      </Card.Section>
      <Stack mt="md" mb="xs">
        <Box>
          <Text fw={700} c="orange">
            {serviceType}
          </Text>
          <Text fz={"1.5em"} fw={900} c="dark.9">
            {`${price.currency}${price.amount.toLocaleString()}`}
          </Text>
        </Box>
        <Stack>
          {actionTexts.map((_, index) => (
            <Group gap={"0.25em"} key={index}>
              <CheckIcon size={13} color="#797979" />
              <Text fz={"sm"} c={"dark"} fw={500}>
                {_}
              </Text>
            </Group>
          ))}
          <Box mt={"md"}>
            <Link to={to}>
              <Button component="a" variant="outline" radius="xl" size={"lg"}>
                Verify now
              </Button>
            </Link>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

export default PriceCard;
