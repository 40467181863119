import { Input, MantineSize, TextInput, Stack } from "@mantine/core";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styles from "./inputs.module.css";

interface IProps {
  width?: string;
  size?: MantineSize;
  value?: string;
  label: string;
  error?: string;
  onChange: (value: string) => void;
  notRequired?: boolean;
}
function CustomPhoneInput({
  size,
  value,
  onChange,
  label,
  width,
  error,
  notRequired,
}: IProps) {
  return (
    <Stack w={width ? width : "100%"} mb={"0.1em"}>
      <TextInput
        label={label}
        component="div"
        size={size ? size : "md"}
        error={error ? error : null}
        required={notRequired ? false : true}
        classNames={{
          input: styles.input,
          wrapper: styles.wrapper,
          label: styles.label,
        }}
      >
        <PhoneInput
          style={{
            border: "none",
          }}
          className={styles.input}
          label={label}
          inputComponent={Input}
          value={value}
          onChange={(value) => onChange(value as string)}
          numberInputProps={{ variant: "unstyled" }}
          defaultCountry="NG"
          international={false}
        />
      </TextInput>
    </Stack>
  );
}

export default CustomPhoneInput;
