import { Box, Container, SimpleGrid } from "@mantine/core";
import { PropsWithChildren } from "react";
function AboutDescription({ children }: PropsWithChildren<unknown>) {
  return (
    <Box pt={{ base: "2em", sm: "5em" }} pb={{ base: "2em", sm: "5em" }}>
      <Container size={"lg"}>
        <SimpleGrid cols={{ base: 1, sm: 2 }}>{children}</SimpleGrid>
      </Container>
    </Box>
  );
}

export default AboutDescription;
