import { PasswordInput, Stack } from "@mantine/core";
import PropTypes from "prop-types";
import styles from "./inputs.module.css";

export function CustomPasswordInput({
  width,
  label,
  placeholder,
  radius,
  size,
  iconLeft,
  mobileWhite,
  register,
  value,
  error,
  notRequired,
}: PropTypes.InferProps<typeof CustomPasswordInput.propTypes>) {
  return (
    <Stack w={width ? width : "100%"} mb={"0.1em"}>
      <PasswordInput
        label={label ? label : "Enter your password"}
        c={
          mobileWhite ? { base: "white", sm: "white", md: "dark.4" } : "dark.4"
        }
        leftSection={iconLeft ? iconLeft : null}
        size={size ? size : "md"}
        radius={radius ? radius : "md"}
        error={error ? error : null}
        required={notRequired ? false : true}
        placeholder={placeholder ? placeholder : "Enter your password"}
        {...value}
        classNames={{
          input: styles.input,
          innerInput: styles.innerInput,
          wrapper: styles.wrapper,
          label: styles.label,
        }}
        {...register}
      />
    </Stack>
  );
}

export default CustomPasswordInput;

CustomPasswordInput.propTypes = {
  value: PropTypes.object,
  register: PropTypes.object,
  error: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  radius: PropTypes.string,
  iconLeft: PropTypes.element,
  mobileWhite: PropTypes.bool,
  notRequired: PropTypes.bool,
};
