import {
  Image,
  Title,
  Text,
  Stack,
  Flex,
  Button,
  Box,
  Container,
  em,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";
import HeroImage from "../../assets/images/hero-image.png";

export function HeroBullets() {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  return (
    <Container
      size={"lg"}
      h={{ base: "100%", sm: "70vh" }}
      style={{
        position: isMobile ? "unset" : "relative",
      }}
    >
      <Flex
        justify={"space-between"}
        align={"center"}
        rowGap={"xl"}
        direction={{ base: "column-reverse", sm: "row" }}
        mt={{ base: "1.5em", md: "7em" }}
      >
        <Stack w={{ base: "100%", sm: "54%" }}>
          <Title
            order={1}
            fw={{ base: 800, md: 900 }}
            fz={{ base: "2em", sm: "3em" }}
            c="dark.5"
          >
            Verify{" "}
            <Text component="span" inherit c="orange">
              academic
            </Text>{" "}
            and{" "}
            <Text component="span" inherit c="orange">
              professional
            </Text>{" "}
            certificate
          </Title>
          <Text fz="1.25em" c="dark.5" mb={{ base: "xs", md: "xl" }}>
            A robust and secure platform designed to streamline the
            authentication process for all your important documents.
          </Text>

          <Link
            to={"/signup"}
            style={{
              textDecoration: "none",
            }}
          >
            <Button
              component="a"
              size="md"
              td={"none"}
              radius="0.5em"
              px={"2.5em"}
            >
              Get Started
            </Button>
          </Link>
        </Stack>
        <Box
          w={{ base: "80%", md: "auto" }}
          style={{
            position: isMobile ? "unset" : "absolute",
            bottom: -20,
            right: 0,
          }}
        >
          <Image src={HeroImage} />
        </Box>
      </Flex>
    </Container>
  );
}
