import { Group, Stack, Text } from "@mantine/core";
import { KpaliLogoWhite } from "../../assets/icons";
import { ISocialLink, SocialLink } from "./socialLinks";

interface IAddressBlockProps {
  address: string;
  socialLinks: ISocialLink[];
}

export const AddressBlock = ({ address, socialLinks }: IAddressBlockProps) => {
  return (
    <Stack gap={"1.6em"}>
      <KpaliLogoWhite />
      <Text component="address" fz="sm" fs={"normal"} fw={600} c="light-grey.1">
        {address}
      </Text>
      <Group gap={"sm"}>
        {socialLinks.map((link) => (
          <SocialLink key={link.link} icon={link.icon} link={link.link} />
        ))}
      </Group>
    </Stack>
  );
};
