import Proptypes from "prop-types";

function OverflowWrapper({
  children,
  minWidth,
}: Proptypes.InferProps<typeof OverflowWrapper.propTypes>) {
  return (
    <div
      style={{
        overflowX: "auto",
        width: "auto",
      }}
    >
      <div
        style={{
          minWidth: minWidth || "100%",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default OverflowWrapper;

OverflowWrapper.propTypes = {
  children: Proptypes.node.isRequired,
  minWidth: Proptypes.string,
};
