import API from "../../api/api";
import { useQuery } from "@tanstack/react-query";

const URL = "/admin/analytics";

export const useGetUserAnalytics = () => {
  const getUserAnalytics = async () => {
    const response = await API.get(`${URL}/users`);
    return response.data;
  };
  const query = useQuery({
    queryFn: getUserAnalytics,
    queryKey: ["GetUserAnalytics"],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
};

export const useGetUserDistribution = () => {
  const getUserDistribution = async () => {
    const response = await API.get(`${URL}/users/distribution`);
    return response.data;
  };
  const query = useQuery({
    queryFn: getUserDistribution,
    queryKey: ["GetUserDistribution"],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
};

export const useGetUserLast6Month = () => {
  const getUserLast6Month = async () => {
    const response = await API.get(`${URL}/users/last6month`);
    return response.data;
  };
  const query = useQuery({
    queryFn: getUserLast6Month,
    queryKey: ["GetUserLast6Month"],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
};

export function useGetUserLineChart(start?: Date | null, end?: Date | null) {
  const getUserLineChart = async () => {
    const response = await API.get(`${URL}/users/linechart`, {
      params: { start, end },
    });
    return response.data;
  };

  const query = useQuery({
    queryFn: getUserLineChart,
    queryKey: ["GetUserLineChart", start, end],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
}

export const useGetWorkspaceAnalytics = () => {
  const getWorkspaces = async () => {
    const response = await API.get(`${URL}/workspaces`);
    return response.data;
  };
  const query = useQuery({
    queryFn: getWorkspaces,
    queryKey: ["GetWorkspacesAnalytics"],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
};

export const useGetWorkspaceDistribution = () => {
  const getWorkspacesDistribution = async () => {
    const response = await API.get(`${URL}/workspaces/distribution`);
    return response.data;
  };
  const query = useQuery({
    queryFn: getWorkspacesDistribution,
    queryKey: ["GetWorkspacesDistribution"],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
};

export const useGetWorkspaceLast6Month = () => {
  const getWorkspacesLast6Month = async () => {
    const response = await API.get(`${URL}/workspaces/last6month`);
    return response.data;
  };
  const query = useQuery({
    queryFn: getWorkspacesLast6Month,
    queryKey: ["GetWorkspacesLast6Month"],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
};

export const useGetRequestsAnalytics = () => {
  const getRequests = async () => {
    const response = await API.get(`${URL}/requests`);
    return response.data;
  };
  const query = useQuery({
    queryFn: getRequests,
    queryKey: ["GetRequestsAnalytics"],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
};

export const useGetRequestsDistribution = () => {
  const getRequestsDistribution = async () => {
    const response = await API.get(`${URL}/requests/distribution`);
    return response.data;
  };
  const query = useQuery({
    queryFn: getRequestsDistribution,
    queryKey: ["GetRequestsDistribution"],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
};

export const useGetRequestsLast6Month = () => {
  const getRequestsLast6Month = async () => {
    const response = await API.get(`${URL}/requests/last6month`);
    return response.data;
  };
  const query = useQuery({
    queryFn: getRequestsLast6Month,
    queryKey: ["GetRequestsLast6Month"],
    refetchInterval: 1000 * 60 * 1,
    refetchOnMount: true,
  });

  return query;
};
