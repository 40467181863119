import Proptypes from "prop-types";
import { BiTrash } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { State } from "country-state-city";
import { Stack, Title } from "@mantine/core";

import {
  CustomTextInput,
  CustomSelectInput,
  CustomFileInput,
} from "../../../shared/inputs";

function CertificateFormData({
  setValue,
  active,
  certificates,
  errors,
  register,
  getValues,
  handleRemove,
}: Proptypes.InferProps<typeof CertificateFormData.propTypes>) {
  const [states, setStates] = useState<any[]>([]);

  useEffect(() => {
    const states = State.getStatesOfCountry("NG");
    const statesSelected = states.map((state) => ({
      value: state.name,
      label: state.name,
    }));
    setStates(statesSelected);
  }, []);

  return (
    <>
      {certificates.map((_: any, index: number) => (
        <Stack
          key={index}
          mb={"2em"}
          align="center"
          bg={"light-grey.4"}
          p={"2.5em"}
          style={{
            boxShadow: " 0px 4px 16px 0px #0000000D",
            border: "1.30px solid #E8E8E8",
            borderRadius: "2em",
          }}
        >
          {certificates?.length > 1 && (
            <button
              onClick={() => {
                handleRemove(index);
              }}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
            >
              <BiTrash size={25} />
            </button>
          )}

          <Title order={3} mb={"1em"}>
            {active}
          </Title>
          <CustomTextInput
            label="Subject Full Name"
            placeholder="Full Name"
            register={register(`certificates[${index}].subjectName`)}
            error={errors?.certificates?.[index]?.subjectName?.message}
          />
          <CustomTextInput
            label="Applicant Full Name"
            placeholder="Full Name"
            register={register(`certificates[${index}].applicantName`)}
            error={errors?.certificates?.[index]?.applicantName?.message}
          />

          {(active === "WAEC" || active === "NECO") && (
            <>
              <CustomTextInput
                label="Registration Number"
                placeholder="E.g 271727763HJ"
                register={register(`certificates[${index}].registrationNo`)}
                error={errors?.certificates?.[index]?.registrationNo?.message}
              />
            </>
          )}

          {active === "UNIVERSITY" && (
            <>
              <CustomTextInput
                label="University Name"
                placeholder={"E.g Babcock University"}
                register={register(`certificates[${index}].university`)}
                error={errors?.certificates?.[index]?.university?.message}
              />
              <CustomTextInput
                label="Matriculation Number"
                placeholder={"E.g BU/17C/IT/2694"}
                register={register(`certificates[${index}].matricNo`)}
                error={errors?.certificates?.[index]?.matricNo?.message}
              />
            </>
          )}

          {active === "NYSC" && (
            <>
              <CustomSelectInput
                label={"Service State"}
                data={states}
                register={register(`certificates[${index}].serviceState`)}
                error={errors?.certificates?.[index]?.serviceState?.message}
              />
              <CustomTextInput
                label="Service Year"
                placeholder={"E.g 2022"}
                register={register(`certificates[${index}].serviceYear`)}
                error={errors?.certificates?.[index]?.serviceYear?.message}
              />

              <CustomTextInput
                label="State Code"
                placeholder={"E.g NS/22A/2227"}
                register={register(`certificates[${index}].stateCode`)}
                error={errors?.certificates?.[index]?.stateCode?.message}
              />
              <CustomTextInput
                label="Certificate Number"
                placeholder={"E.g 123456"}
                register={register(`certificates[${index}].certificateNo`)}
                error={errors?.certificates?.[index]?.certificateNo?.message}
              />
              <CustomTextInput
                label="Identity Number"
                placeholder={"E.g 123456"}
                register={register(`certificates[${index}].nyscNo`)}
                error={errors?.certificates?.[index]?.nyscNo?.message}
              />

              <CustomTextInput
                label={"Verification Reason"}
                placeholder={"E.g Employment"}
                register={register(`certificates[${index}].verificationReason`)}
                error={
                  errors?.certificates?.[index]?.verificationReason?.message
                }
              />
            </>
          )}

          {active === "OTHERS" && (
            <>
              <CustomTextInput
                label="Certificate Name"
                placeholder={"E.g JAMB Certificate"}
                register={register(`certificates[${index}].documentName`)}
                error={errors?.certificates?.[index]?.documentName?.message}
              />
            </>
          )}

          {active === "PROFESSIONAL" && (
            <>
              <CustomTextInput
                label="Profesional Body"
                placeholder={"E.g ICAN"}
                register={register(`certificates[${index}].professionalBody`)}
                error={errors?.certificates?.[index]?.professionalBody?.message}
              />
              <CustomTextInput
                label="Membership/License Number"
                placeholder={"E.g 123456"}
                register={register(`certificates[${index}].licenseNo`)}
                error={errors?.certificates?.[index]?.licenseNo?.message}
              />
            </>
          )}

          {(active === "DOB" ||
            active === "MARRIAGE" ||
            active === "DEATH") && <></>}
          <CustomFileInput
            label="Upload Certificate"
            register={register(`certificates[${index}].certificateUrl`)}
            setValue={setValue}
            getValues={getValues}
            error={errors?.certificates?.[index]?.certificateUrl?.message}
            placeholder={"Upload Certificate"}
          />

          <CustomFileInput
            label="Upload Consent Letter"
            showConsentLink
            register={register(`certificates[${index}].consentUrl`)}
            setValue={setValue}
            getValues={getValues}
            placeholder={"Upload Consent Letter"}
            notRequired
            error={errors?.certificates?.[index]?.consentUrl?.message}
          />
        </Stack>
      ))}
    </>
  );
}

export default React.memo(CertificateFormData);

CertificateFormData.propTypes = {
  active: Proptypes.string.isRequired,
  setValue: Proptypes.any.isRequired,
  certificates: Proptypes.array.isRequired,
  register: Proptypes.any.isRequired,
  errors: Proptypes.any.isRequired,
  getValues: Proptypes.func.isRequired,
  handleRemove: Proptypes.func.isRequired,
};
