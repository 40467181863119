import useResponsive from "../../../../../hooks/useResponsive";
function InsightWrapper2({ children }: React.PropsWithChildren) {
  const { isTablet, isLaptop } = useResponsive();
  return (
    <div
      style={{
        maxWidth: "100%",
        display: "flex",
        gap: isLaptop ? "0.5em" : "2em",
        justifyContent: "space-between",
        flexDirection: isTablet ? "column" : "row",
        position: "relative",
      }}
    >
      {children}
    </div>
  );
}

export default InsightWrapper2;
