// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputs_input__VTvl4 {
  border: 1px solid #c6c6c6eb;
  color: #333333;
  border-radius: 0.5em;

  &::placeholder {
    color: #797979;
    padding-top: "2em";
    padding-bottom: 3em;
  }
  &:disabled {
    background-color: #e8e8e8;
    cursor: auto;
  }

  &:focus {
    outline: none;
    border: 1px solid #e86217;
    border-radius: "0.5em";
  }
}

.inputs_wrapper__GfYEF {
  margin-top: 0.25em;
}

.inputs_label__AwBE5 {
  color: "#282828";
  font-size: 14;
  font-weight: "700";
  word-wrap: "break-word";
}

.inputs_innerInput__xA1gI {
  color: #333333;
  border-radius: 0.5em;

  &::placeholder {
    color: #797979;
    padding-top: "2em";
    padding-bottom: 3em;
  }

  &:focus {
    outline: none;
    border: 1px solid #e86217;
    border-radius: "0.5em";
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/primitives/shared/inputs/inputs.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,cAAc;EACd,oBAAoB;;EAEpB;IACE,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,sBAAsB;EACxB;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,oBAAoB;;EAEpB;IACE,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,sBAAsB;EACxB;AACF","sourcesContent":[".input {\n  border: 1px solid #c6c6c6eb;\n  color: #333333;\n  border-radius: 0.5em;\n\n  &::placeholder {\n    color: #797979;\n    padding-top: \"2em\";\n    padding-bottom: 3em;\n  }\n  &:disabled {\n    background-color: #e8e8e8;\n    cursor: auto;\n  }\n\n  &:focus {\n    outline: none;\n    border: 1px solid #e86217;\n    border-radius: \"0.5em\";\n  }\n}\n\n.wrapper {\n  margin-top: 0.25em;\n}\n\n.label {\n  color: \"#282828\";\n  font-size: 14;\n  font-weight: \"700\";\n  word-wrap: \"break-word\";\n}\n\n.innerInput {\n  color: #333333;\n  border-radius: 0.5em;\n\n  &::placeholder {\n    color: #797979;\n    padding-top: \"2em\";\n    padding-bottom: 3em;\n  }\n\n  &:focus {\n    outline: none;\n    border: 1px solid #e86217;\n    border-radius: \"0.5em\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `inputs_input__VTvl4`,
	"wrapper": `inputs_wrapper__GfYEF`,
	"label": `inputs_label__AwBE5`,
	"innerInput": `inputs_innerInput__xA1gI`
};
export default ___CSS_LOADER_EXPORT___;
