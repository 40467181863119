// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes css_scrollLeft__hj3Di {
  from {
    transform: translateX(20%);
  }
  to {
    transform: translateX(-400%);
  }
}

.css_slideLeft__F9qe\\+ {
  animation: css_scrollLeft__hj3Di 6s ease-in-out infinite;
  overflow: visible;
}
`, "",{"version":3,"sources":["webpack://./src/components/primitives/homePage/css.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,wDAA6C;EAC7C,iBAAiB;AACnB","sourcesContent":["@keyframes scrollLeft {\n  from {\n    transform: translateX(20%);\n  }\n  to {\n    transform: translateX(-400%);\n  }\n}\n\n.slideLeft {\n  animation: scrollLeft 6s ease-in-out infinite;\n  overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slideLeft": `css_slideLeft__F9qe+`,
	"scrollLeft": `css_scrollLeft__hj3Di`
};
export default ___CSS_LOADER_EXPORT___;
