import {
  FileInput as _FileInput,
  Stack,
  RingProgress,
  ActionIcon,
  Tooltip,
  Text,
  Anchor,
} from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Exchange,
  X as Cancel,
  Upload,
  AlertCircle,
  Eye as Preview,
} from "tabler-icons-react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import styles from "./inputs.module.css";
import { getIconByFileExtension, getFileExtension } from "../reusableFunctions";
import { useUploadFile } from "../../../../hooks/useFileUpload";

export function CustomFileInput({
  disabled,
  width,
  label,
  radius,
  size,
  mobileWhite,
  register,
  setValue,
  getValues,
  error: _error,
  folder,
  notRequired,
  placeholder,
  showConsentLink = false,
}: PropTypes.InferProps<typeof CustomFileInput.propTypes>) {
  const { mutate, progress, status, cancel, setStatus } = useUploadFile();

  // Made the following type to any to allow placeholders to be used in file input
  const FileInput = _FileInput as any;

  const { name, onBlur, ref }: { name: string; onBlur: any; ref: any } = {
    ...register,
  };

  const [errors, setErrors] = useState(_error);
  const inputRef = useRef<HTMLInputElement>(ref);
  const [file, setFile] = useState<File | any>(null);
  const urlToSave = getValues(name);

  const FileName = getFileExtension(file?.name as string);

  const FileIcon = getIconByFileExtension(FileName);

  useEffect(() => {
    setErrors(_error);
  }, [_error]);

  const handleReUpload = async () => {
    if (!file) {
      setFile(null);
      setStatus("idle");
      setValue(name, "");
      setErrors(_error);
      return;
    }
    setErrors(_error);
    const urlId = uuidv4();
    const imgData = new FormData();
    imgData.append("files", file);
    imgData.append("folder", `private/${urlId}`);
    setStatus("loading");

    mutate(imgData, {
      onSuccess: (res) => {
        setErrors(null);
        setValue(name, res?.data?.Location);
      },
      onError: (error: any) => {
        setValue(name, "");
        setErrors(error?.response?.data?.message);
      },
    });
  };

  const handleUpload = async (file: any) => {
    if (!file) {
      setFile(null);
      setStatus("idle");
      setValue(name, "");
      setErrors(_error);
      return;
    }
    setErrors(_error);
    setFile(file);
    const urlId = uuidv4();
    const imgData = new FormData();
    imgData.append("files", file);
    imgData.append("folder", `private/${urlId}`);
    mutate(imgData, {
      onSuccess: (res) => {
        setErrors(null);
        setValue(name, res?.data?.Location);
      },
      onError: (error: any) => {
        setValue(name, "");
        setErrors(error?.response?.data?.message);
      },
    });
  };

  const openFilePicker = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const ProgressComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          marginRight: "1em",
          gap: "0em",
          alignItems: "center",
        }}
      >
        <RingProgress
          size={30}
          style={{
            marginRight: "none",
          }}
          thickness={1}
          sections={[{ value: progress, color: "blue" }]}
          label={
            <Text c="blue" fw={600} ta="center" fz={10}>
              {progress}%
            </Text>
          }
        />

        <Tooltip label="Stop upload" withArrow>
          <ActionIcon
            bg={"none"}
            onClick={cancel}
            style={{
              marginLeft: "none",
            }}
          >
            <Cancel width={14} color={"red"} />
          </ActionIcon>
        </Tooltip>
      </div>
    );
  };

  const SuccessComponent = () => {
    return (
      <div style={{ display: "flex", marginRight: "1.5em", gap: "0.25em" }}>
        <Tooltip label="Preview file" withArrow>
          <ActionIcon
            variant="default"
            onClick={() => window.open(urlToSave, "_blank")}
            style={{
              borderColor: "#E8E8E8",
            }}
          >
            <Preview width={12} color={"gray"} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Change file" withArrow>
          <ActionIcon
            variant="default"
            onClick={openFilePicker}
            style={{
              borderColor: "#E8E8E8",
            }}
          >
            <Exchange width={12} color={"gray"} />
          </ActionIcon>
        </Tooltip>
      </div>
    );
  };

  const ErrorComponent = () => {
    return (
      <div style={{ display: "flex", marginRight: "1.5em", gap: "0.25em" }}>
        <Tooltip label={errors ?? "An Error occured"}>
          <ActionIcon
            variant="default"
            style={{
              borderColor: "#E8E8E8",
            }}
          >
            <AlertCircle width={12} color={"red"} />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Retry upload" withArrow>
          <ActionIcon
            variant="default"
            onClick={handleReUpload}
            style={{
              borderColor: "#E8E8E8",
            }}
          >
            <Upload width={12} color={"gray"} />
          </ActionIcon>
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      <Stack w={width ? width : "100%"} mb={"0.1em"}>
        {showConsentLink && (
          <Anchor
            href="https://kpali-bucket.s3.eu-central-1.amazonaws.com/public/kpali-consent-form.pdf"
            target="_blank"
          >
            <Text size="sm">Download consent form</Text>
          </Anchor>
        )}
        <FileInput
          placeholder={placeholder ? placeholder : "Upload file"}
          disabled={disabled ? true : false}
          label={label}
          c={
            mobileWhite
              ? { base: "white", sm: "white", md: "dark.4" }
              : "dark.4"
          }
          accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
          leftSection={FileName && FileIcon ? <FileIcon width={22} /> : null}
          rightSection={
            status === "idle" ? (
              <Upload width={12} color={"gray"} />
            ) : status === "loading" ? (
              <ProgressComponent />
            ) : status === "success" && urlToSave ? (
              <SuccessComponent />
            ) : status === "error" ? (
              <ErrorComponent />
            ) : null
          }
          value={file}
          size={size ? size : "md"}
          error={errors ? errors : null}
          name={name}
          onChange={handleUpload}
          required={notRequired ? false : true}
          onBlur={onBlur}
          ref={inputRef ? inputRef : ref}
          radius={radius ? radius : "md"}
          className={styles.disabled}
          classNames={{
            input: styles.input,
            wrapper: styles.wrapper,
            label: styles.label,
          }}
        />
      </Stack>
    </>
  );
}

CustomFileInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  getValues: PropTypes.func.isRequired,
  register: PropTypes.any,
  error: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  radius: PropTypes.string,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  mobileWhite: PropTypes.bool,
  disabled: PropTypes.bool,
  setValue: PropTypes.any,
  folder: PropTypes.string,
  notRequired: PropTypes.bool,
  showConsentLink: PropTypes.bool,
};

export default CustomFileInput;
