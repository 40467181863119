import API from "../../api/api";
import { useQuery } from "@tanstack/react-query";

const URL = "/admin/agents";

export const useGetAvailableAgents = (
  page: number,
  limit: number,
  searchterm: string = ""
) => {
  const getAvailableAgents = async () => {
    const response =
      await API.get(`${URL}?page=${page}&limit=${limit}&searchterm=${searchterm}
   
    `);
    return response;
  };
  const query = useQuery({
    queryFn: getAvailableAgents,
    queryKey: ["GetAvailableAgents", page, limit, searchterm],
  });

  return query;
};
