import { useMediaQuery } from "@mantine/hooks";

export const useResponsive = () => {
  const isMobile = useMediaQuery("(max-width: 576px)")!;
  const isTablet = useMediaQuery("(max-width: 768px)")!;
  const isLaptop = useMediaQuery("(max-width: 992px)")!;
  const isDesktop = useMediaQuery("(min-width: 1200px)")!;

  return { isMobile, isTablet, isLaptop, isDesktop };
};

export default useResponsive;
