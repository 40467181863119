import * as Yup from "yup";
import { WorkspaceType, IWorkspaceType } from "../../enums/enums";

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  password: Yup.string()
    .required("New password is required")
    .min(6, "New password must be at least 6 characters long")
    .notOneOf(
      [Yup.ref("currentPassword")],
      "New password must be different from current password"
    ),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export type ChangePasswordDto = Yup.InferType<typeof ChangePasswordSchema>;

export const UpdateProfileSchema = Yup.object().shape({
  firstName: Yup.string().when("accountType", {
    is: (accountType: IWorkspaceType) => accountType === "PERSONAL",
    then: () => Yup.string().required("First Name is required").trim(),
  }),
  lastName: Yup.string().when("accountType", {
    is: (accountType: IWorkspaceType) => accountType === "PERSONAL",
    then: () => Yup.string().required("Last Name is required").trim(),
  }),
  businessName: Yup.string().when("accountType", {
    is: (accountType: IWorkspaceType) => accountType === "CORPORATE",
    then: () => Yup.string().required("Business Name is required").trim(),
  }),
  phoneNo: Yup.string().required("Phone Number is required").trim(),
  country: Yup.string()
    .required("Country is required")
    .min(2, "Country is required")
    .trim(),
  state: Yup.string().required("State is required").trim(),
  city: Yup.string().required("City is required").trim(),
  accountType: Yup.string()
    .oneOf(Object.values(WorkspaceType))
    .required("Account Type is required")
    .trim(),
});

export type UpdateProfileDto = Yup.InferType<typeof UpdateProfileSchema>;

export const UpdateNotificationSchema = Yup.object().shape({
  notify_new_activity: Yup.boolean().optional(),
  notify_new_request: Yup.boolean().optional(),
  notify_news_updates: Yup.boolean().optional(),
  notify_weekly_newsletter: Yup.boolean().optional(),
});

export type UpdateNotificationDto = Yup.InferType<
  typeof UpdateNotificationSchema
>;
