// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_textarea__aMsdI {
  background-color: #f8f8f8;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/primitives/contactPage.tsx/form.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".textarea {\n  background-color: #f8f8f8;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `form_textarea__aMsdI`
};
export default ___CSS_LOADER_EXPORT___;
