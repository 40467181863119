import { Box, Group, Stack, NavLink, Divider } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import AdminLayout from "../../../../layouts/adminLayout";
import useGetQueryParams from "../../../../../hooks/useGetUrlParams";
import { UserInisghts, WorkspaceInsights, RequestsInsights } from "./tabs";

function Analytics() {
  const tab = useGetQueryParams("tab");
  const navigate = useNavigate();
  const [active, setActive] = useState("users");

  useEffect(() => {
    if (tab) {
      setActive(tab);
    }
    if (!tab) {
      navigate(`/admin/analytics?tab=users`);
    }
  }, [tab, navigate]);

  const tabs = [
    {
      title: "Users",
      tab: "users",
      content: <UserInisghts />,
    },
    {
      title: "Workspaces",
      tab: "workspaces",
      content: <WorkspaceInsights />,
    },
    {
      title: "Requests",
      tab: "requests",
      content: <RequestsInsights />,
    },
    {
      title: "Payments",
      tab: "payments",
      content: <></>,
    },
    {
      title: "Agents",
      tab: "agents",
      content: <></>,
    },
  ];

  const handleTabChange = (tab: string) => {
    navigate(`/admin/analytics?tab=${tab}`);
    setActive(tab);
  };

  return (
    <AdminLayout title="Analytics">
      <Box
        style={{
          marginTop: "0em",
        }}
      >
        <Group gap={"2em"}>
          {tabs.map((tab, index) => (
            <Stack gap={"0"}>
              <NavLink
                c={active === tab.tab ? "orange.4" : "light-grey.10"}
                fw={active === tab.tab ? 700 : 500}
                label={tab.title}
                key={index}
                onClick={() => handleTabChange(tab.tab)}
              />
              {active === tab.title && <Divider color="orange.4" size={2} />}
            </Stack>
          ))}
        </Group>
        <Box>{tabs.find((tab) => tab.tab === active)?.content}</Box>
      </Box>
    </AdminLayout>
  );
}

export default Analytics;
