import Skeleton from "react-loading-skeleton";
import useResponsive from "../../../../hooks/useResponsive";

const DoughtnutCircleChartLoader = () => {
  const { isMobile } = useResponsive();
  const dimensions = isMobile ? 200 : 250;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          borderRadius: "50%",
          border: `30px solid #F2F4F7`,
        }}
      >
        <Skeleton circle={true} height={dimensions} width={dimensions} />
      </div>
    </div>
  );
};

export default DoughtnutCircleChartLoader;
