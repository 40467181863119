import { Group, Anchor, Text, Stack } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import OrangeButton from "../shared/buttons/orangeButton";
import AuthLayout from "../../layouts/auth";

import { useAcceptInvite } from "../../../hooks/user/user.workspace.hook";
import useGetQueryParams from "../../../hooks/useGetUrlParams";
import { CustomTextInput } from "../shared/inputs";

function AcceptInvite() {
  const token = useGetQueryParams("token");
  const email = useGetQueryParams("email");
  const navigate = useNavigate();
  const { mutate, isPending } = useAcceptInvite(token!);

  const { register, setValue } = useForm({
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (email) {
      setValue("email", email!);
      navigate(`/acceptinvite?token=${token}`);
    }
  }, [email, setValue, navigate, token]);

  return (
    <AuthLayout title="Accept Invite">
      <Text>Click the button below to accept this invite</Text>
      <CustomTextInput
        mobileWhite
        disabled
        label={"Email"}
        placeholder={"Email address"}
        register={register("email")}
      />
      <OrangeButton
        loading={isPending}
        buttonText={"Accept Invite"}
        onClick={() => mutate()}
      />
      <Stack w={"100%"}>
        <Group justify={"space-between"}>
          <Text c={{ base: "white", sm: "white", md: "dark" }}>
            <Anchor href="/login">Login</Anchor>
          </Text>
          <Text fw={"800"}>
            <Anchor href="/signup">Sign-up</Anchor>
          </Text>
        </Group>
      </Stack>
    </AuthLayout>
  );
}

export default AcceptInvite;
