import {
  Group,
  Text,
  Accordion,
  Stack,
  Flex,
  Title,
  Button,
} from "@mantine/core";
import classes from "../../../form/formListAccordion/formlist.module.css";
import RequestDataLayout, { PDFDownloadButton } from "./requestDataLayout";

function AccordionLabel({ index }: { index: number }) {
  return (
    <Group wrap="nowrap">
      <Text c={"orange.4"}>#{index + 1}</Text>
    </Group>
  );
}

function PreviewRequest({
  active = "Certificate Verification",
  certificates,
  onSubmit,
  onBack,
  isPending,
  totalPrice,
}: {
  totalPrice?: number;
  active?: string;
  certificates: any[];
  onSubmit: () => void;
  onBack: () => void;
  isPending: boolean;
}) {
  const items = certificates.map((certificate, index) => {
    return (
      <Accordion.Item value={index.toString()} key={index}>
        <Accordion.Control>
          <AccordionLabel index={index} />
        </Accordion.Control>
        <Accordion.Panel>
          <Stack gap={"0.5em"}>
            {certificate?.applicantName && (
              <RequestDataLayout
                label="Applicant Name"
                data={certificate.applicantName}
              />
            )}
            {certificate?.subjectName && (
              <RequestDataLayout
                label="Subject Name"
                data={certificate.subjectName}
              />
            )}
            {certificate?.university && (
              <RequestDataLayout
                label="University"
                data={certificate.university}
              />
            )}
            {certificate?.matricNo && (
              <RequestDataLayout
                label="Matriculation No"
                data={certificate.matricNo}
              />
            )}
            {certificate?.registrationNo && (
              <RequestDataLayout
                label="Registration No"
                data={certificate.registrationNo}
              />
            )}
            {/* {"Might need to change to certificateName"} */}
            {certificate?.documentName && (
              <RequestDataLayout
                label="Certificate Name"
                data={certificate.documentName}
              />
            )}
            {certificate?.professionalBody && (
              <RequestDataLayout
                label="Professional Body"
                data={certificate.professionalBody}
              />
            )}
            {certificate?.licenseNo && (
              <RequestDataLayout
                label="License No"
                data={certificate.licenseNo}
              />
            )}
            {certificate?.serviceState && (
              <RequestDataLayout
                label="Service State"
                data={certificate.serviceState}
              />
            )}
            {certificate?.serviceYear && (
              <RequestDataLayout
                label="Service Year"
                data={certificate.serviceYear}
              />
            )}
            {certificate?.certificateNo && (
              <RequestDataLayout
                label="Certificate No"
                data={certificate.certificateNo}
              />
            )}
            {certificate?.nyscNo && (
              <RequestDataLayout
                label="Identification No"
                data={certificate.nyscNo}
              />
            )}
            {certificate?.verificationReason && (
              <RequestDataLayout
                label="Verification Reason"
                data={certificate.verificationReason}
              />
            )}
            {certificate?.certificateUrl && (
              <RequestDataLayout
                label="Certificate"
                data={
                  <PDFDownloadButton
                    onClick={() =>
                      window.open(certificate?.certificateUrl, "_blank")
                    }
                  >
                    Preview
                  </PDFDownloadButton>
                }
              />
            )}
            {certificate?.consentUrl && (
              <RequestDataLayout
                label="Consent Form"
                data={
                  <PDFDownloadButton
                    onClick={() =>
                      window.open(certificate?.consentUrl, "_blank")
                    }
                  >
                    Preview
                  </PDFDownloadButton>
                }
              />
            )}
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    );
  });

  return (
    <Stack
      gap={"0.1em"}
      miw={"370px"}
      ml={"1em"}
      w={{ base: "370px", md: "420px" }}
    >
      <Stack
        mb={"2em"}
        align="center"
        justify="space-between"
        bg={"light-grey.4"}
        h={"80vh"}
        p={"1.5em"}
        style={{
          boxShadow: " 0px 4px 16px 0px #0000000D",
          border: "1.30px solid #E8E8E8",
          borderRadius: "2em",
        }}
      >
        <div>
          <Flex w={"100%"} justify={"center"}>
            <Title order={3} mb={"1em"}>
              {active}
            </Title>
          </Flex>
          <Accordion
            classNames={{
              control: classes.control,
              item: classes.item,
            }}
            chevronPosition="right"
          >
            {items}
          </Accordion>
        </div>
        <Group justify="space-between" w={"100%"}>
          <Button variant="default" onClick={onBack}>
            Edit
          </Button>
          <Button onClick={onSubmit} loading={isPending}>
            Pay Now ({totalPrice})
          </Button>
        </Group>
      </Stack>
    </Stack>
  );
}

export default PreviewRequest;
