import { Grid, Text } from "@mantine/core";
import { RequestComponent } from "./requestDetails";
import CardLoader from "../../../../shared/skeletonLoader/cardLoader";
import moment from "moment";

function PaymentSection({
  paymentDetails,
  paymentLoading,
}: {
  paymentDetails: any;
  paymentLoading: boolean;
}) {
  // const paymentDetails = [
  //   {
  //     title: "Amount",
  //     value: "NGN 1,000,000",
  //   },
  //   { title: "Payment Method", value: "Bank Transfer" },
  //   { title: "Payment Status", value: "Pending" },
  //   { title: "Payment Date", value: "12th May, 2021" },
  // ];

  return (
    <RequestComponent
      requestId={!paymentLoading ? paymentDetails.requestId : ""}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 0.5,
            minWidth: "350px",
            backgroundColor: "#0069B4",
            borderRadius: "8px",
            paddingBottom: "8em",
          }}
        >
          {!paymentLoading && (
            <Grid
              m={{
                base: "1.5em",
                sm: "lg",
              }}
            >
              <PaymentDataLayout
                title={"Amount"}
                value={paymentDetails.amount}
              />
              <PaymentDataLayout
                title={"Payment Method"}
                value={paymentDetails.provider}
              />
              <PaymentDataLayout
                title={"Payment Status"}
                value={paymentDetails.status}
              />
              <PaymentDataLayout
                title={"Payment Date"}
                value={moment(paymentDetails.createdAt).format(
                  "Do MMMM, YYYY hh:mmA"
                )}
              />
            </Grid>
          )}
          {paymentLoading &&
            Array.from({ length: 1 }).map((_, index) => (
              <CardLoader key={index} />
            ))}
        </div>
        <div
          style={{
            flex: 1,
          }}
        ></div>
      </div>
    </RequestComponent>
  );
}

export default PaymentSection;

export const PaymentDataLayout = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <Grid.Col
      span={{
        base: 12,
        md: 6,
      }}
    >
      <Text c={"light-grey.4"}>{title}</Text>
      <Text c={"light-grey.4"} fw={700}>
        {value}
      </Text>
      {/* <Text c={"light-grey.4"}>Payment Method</Text>
      <Text c={"light-grey.4"} fw={700}>
        {"Bank Tansfer"}
      </Text>
      <Text c={"light-grey.4"}>Payment Status</Text>
      <Text c={"light-grey.4"} fw={700}>
        {paymentStatus}
      </Text>
      <Text c={"light-grey.4"}>Payment Date</Text>
      <Text c={"light-grey.4"} fw={700}>
        {paymentDate}
      </Text> */}
    </Grid.Col>
  );
};
