import { Group, Burger } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { KpaliLogo } from "../../components/assets/icons";
import { HeaderNavLink } from "../../components/primitives/headerMenu/headerMenu";

const links = [
  { to: "/", label: "Home" },
  { to: "/about", label: "About" },
  { to: "/certificates", label: "Certificates" },
  { to: "/contact", label: "Contact" },
  { to: "/login", label: "Login" },
];

export function Header() {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <header>
      <Group justify={"space-between"} h="5.1em" align="center">
        <KpaliLogo />
        <Group
          visibleFrom="sm"
          style={{
            gap: "2em",
          }}
        >
          {links.map((link) => (
            <HeaderNavLink
              key={link.to}
              to={link.to}
              label={link.label}
              isLoginLink={link.label === "Login"}
            />
          ))}
        </Group>

        <Burger opened={opened} onClick={toggle} hiddenFrom="xs" size="sm" />
      </Group>
    </header>
  );
}
