import { useState } from "react";
import { Group, Code, rem, Stack, Image } from "@mantine/core";

import { Link, useNavigate } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import DashboardIcon from "../../components/assets/svg/adminDashboardIcon.svg";
import RequestsIcon from "../../components/assets/svg/adminRequestsIcon.svg";
import UsersIcon from "../../components/assets/svg/adminUsersIcon.svg";
import PaymentsIcon from "../../components/assets/svg/adminPaymentsIcon.svg";
import ServicesIcon from "../../components/assets/svg/adminServicesIcon.svg";
import WorkspacesIcon from "../../components/assets/svg/adminWorkspacesIcon.svg";
import AgentsIcon from "../../components/assets/svg/adminAgentsIcon.svg";
import AnalyticalIcon from "../../components/assets/svg/adminAnalyticsIcon.svg";
import { KpaliLogoWhite } from "../../components/assets/icons";

const adminDashboardData = [
  { link: "/admin/dashboard", label: "Dashboard", icon: DashboardIcon },
  { link: "/admin/requests", label: "Requests", icon: RequestsIcon },
  { link: "/admin/users", label: "Users", icon: UsersIcon },
  { link: "/admin/payments", label: "Payments", icon: PaymentsIcon },
  { link: "/admin/services", label: "Services", icon: ServicesIcon },
  {
    link: "/admin/workspaces",
    label: "Workspaces",
    icon: WorkspacesIcon,
  },
  { link: "/admin/agents", label: "Agents", icon: AgentsIcon },
  {
    link: "/admin/analytics",
    label: "Analytical",
    icon: AnalyticalIcon,
  },
];

export function AdminNavbar({ toggle }: { toggle?: () => void }) {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState("");
  const { isTablet, isLaptop } = useResponsive();
  const handleHover = (label: string) => () => {
    setHovered(label);
  };
  const active = window.location.pathname;

  const isActive = (link: string) => {
    return active.includes(link);
  };

  const links = adminDashboardData.map((item) => {
    const isHovered = hovered === item.label;
    return (
      <Link
        onMouseEnter={handleHover(item.label)}
        onMouseLeave={handleHover("")}
        style={{
          color: "#F5F4F3",
          backgroundColor: isHovered || isActive(item.link) ? "#424242" : "",
          fontSize: "15px",
          fontWeight: isActive(item.link) ? 700 : 400,
          padding: "0.5em 1em",
          display: "flex",
          alignItems: "center",
          gap: "1em",
          borderRadius: "5px",
          textDecoration: "none",
          marginLeft: isActive(item.link) ? "-4px" : "0",
          borderLeft: isActive(item.link) ? "4px solid #E86217" : "none",
        }}
        to={item.link}
        key={item.label}
      >
        <Image w={"20"} h={"20"} src={item.icon} />
        <span>{item.label}</span>
      </Link>
    );
  });

  return (
    <div>
      {isTablet && (
        <div
          onClick={toggle}
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: 10,
          }}
        />
      )}
      <Stack
        p={isLaptop ? "2em 1em" : "2em"}
        bg={"dark.4"}
        w={isTablet ? "250px" : isLaptop ? "225px" : "250px"}
        h={"100vh"}
        style={{
          zIndex: isTablet ? 999 : 0,
          position: isTablet ? "absolute" : "relative",
          transition: "all 0.5s",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <Group pl={"0.7em"} justify="space-between">
            {!isTablet && (
              <div
                style={{
                  marginBottom: "1em",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <KpaliLogoWhite style={{ width: rem(100) }} />
              </div>
            )}
            <Code bg={"none"} c={"#A2A0A2"} fw={700} mb={"0.5em"}>
              MAIN MENU
            </Code>
          </Group>
          {links}
        </div>
      </Stack>
    </div>
  );
}

export default AdminNavbar;
