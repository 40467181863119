import { Button, Container, Flex, SimpleGrid, Stack } from "@mantine/core";
import GreyBackground from "../backgrounds/greyBackground";
import LandingSectionHeader from "../sectionHeading/landing";
import ProcessCard from "../cards/processCard";
import CreateAccount from "../../assets/images/create-account.png";
import GetFeedback from "../../assets/images/get-feedback.png";
import UploadCertificate from "../../assets/images/upload-certificate.png";

const verificationProcessData = [
  {
    headerText: "Step 1",
    subText: "Create Account",
    image: CreateAccount,
  },
  {
    headerText: "Step 2",
    subText: "Upload Certificate",
    image: UploadCertificate,
  },
  {
    headerText: "Step 3",
    subText: "Get feedback",
    image: GetFeedback,
  },
];

function VerificationProcess() {
  return (
    <GreyBackground>
      <Container size={"lg"}>
        <Stack align={"center"}>
          <LandingSectionHeader
            headerText="Our 3 Step Verification Process"
            subText="Our solution stands out with its versatility, accommodating various."
          />
          <SimpleGrid
            cols={{ base: 1, sm: 2, md: 3 }}
            spacing={"xl"}
            mt={50}
            mb={32}
          >
            {verificationProcessData.map((process, index) => (
              <ProcessCard
                key={index}
                step={process.headerText}
                description={process.subText}
                image={process.image}
              />
            ))}
          </SimpleGrid>
          <Flex justify={"center"}>
            <Button component="a" radius="md" size="md" href="/signup">
              Get Started
            </Button>
          </Flex>
        </Stack>
      </Container>
    </GreyBackground>
  );
}

export default VerificationProcess;
