import { Button, Container, SimpleGrid, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import GreyBackground from "../backgrounds/greyBackground";
import PriceCard from "../cards/priceCard";
import WaecImg from "../../assets/images/waec-card.png";
import UniversityImg from "../../assets/images/university-card.png";
import NecoImg from "../../assets/images/neco-card.png";
import NYSCImage from "../../assets/images/nyscImage.png";
import ProfessionalImage from "../../assets/images/professionalImage.jpg";
import BioDataImage from "../../assets/images/bioDataImage.png";
import LandingSectionHeader from "../sectionHeading/landing";

export const certificates = [
  {
    ServiceName: "Waec",
    Price: 20000,
    Duration: "Duration 1-2 working days",
    Description: "Verify the validity of a potential employee's",
    Image: WaecImg,
    Link: "/service/certificate",
  },
  {
    ServiceName: "University Degree",
    Price: 40000,
    Duration: "Duration 1-6 weeks",
    Description: "Verify the validity of a potential employee's",
    Image: UniversityImg,
    Link: "/service/certificate",
  },
  {
    ServiceName: "Neco Result",
    Price: 20000,
    Duration: "Duration 1-2 working days",
    Description: "Verify the validity of a potential employee's",
    Image: NecoImg,
    Link: "/service/certificate",
  },
  {
    ServiceName: "NYSC Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify the validity of a potential employee's",
    Image: NYSCImage,
    Link: "/service/certificate",
  },
  {
    ServiceName: "Professional Certificates",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify any professional certificates ",
    Image: ProfessionalImage,
    Link: "/service/certificate",
  },
  {
    ServiceName: "Biodata",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify any birth, marital or death records",
    Image: BioDataImage,
    Link: "/service/certificate",
  },
];

export function CertificateServices() {
  const [opened, { toggle }] = useDisclosure(false);
  const [certificatesToDisplay, setCertificatesToDisplay] = useState(
    certificates.slice(0, 3)
  );

  function handleToggle() {
    toggle();
    if (opened) {
      setCertificatesToDisplay(certificates.slice(0, 3));
    } else {
      setCertificatesToDisplay(certificates);
    }
  }

  return (
    <GreyBackground>
      <Container size={"lg"}>
        <Stack align={"center"}>
          <LandingSectionHeader
            headerText="Certificate Verification Services"
            subText="Our solution stands out with its versatility, accommodating various "
          />
          <SimpleGrid
            cols={{ base: 1, sm: 2, md: 3 }}
            spacing={"xl"}
            mt={50}
            mb={32}
          >
            {certificatesToDisplay.map((certificate, index) => (
              <PriceCard
                key={index}
                actionTexts={[certificate.Duration, certificate.Description]}
                imgSrc={certificate.Image}
                serviceType={certificate.ServiceName}
                price={{ currency: "N", amount: certificate.Price }}
                to={certificate.Link}
              />
            ))}
          </SimpleGrid>

          <Button
            size="md"
            td={"none"}
            radius="0.5em"
            fullWidth={false}
            onClick={handleToggle}
          >
            {opened ? "Show Less" : "Show More"}
          </Button>
        </Stack>
      </Container>
    </GreyBackground>
  );
}
