import { Box, Text, Title } from "@mantine/core";

interface ILandingSectionHeader {
  headerText: string;
  subText?: string;
}

const LandingSectionHeader = ({
  headerText,
  subText,
}: ILandingSectionHeader) => {
  return (
    <Box>
      <Title
        order={2}
        ta="center"
        fz={"1.75em"}
        style={{
          letterSpacing: " -0.00625rem",
        }}
      >
        {headerText}
      </Title>
      {subText && (
        <Text c="dimmed" ta="center" fz={"1em"}>
          {subText}
        </Text>
      )}
    </Box>
  );
};

export default LandingSectionHeader;
