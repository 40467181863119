import {
  Stack,
  Text,
  Title,
  Group,
  Loader,
  Paper,
  LoadingOverlay,
} from "@mantine/core";
import { CustomCheckBox } from "../../../shared/inputs";
import { useUpdateNotification } from "../../../../../hooks/user/user.hook";
import { useCurrentWorkspace } from "../../../../../hooks/user/user.workspace.hook";

function Notifications() {
  const { mutate, isPending } = useUpdateNotification();
  const { currentUser, isLoading } = useCurrentWorkspace();

  const initialFormData = {
    notify_new_activity: currentUser?.notify_new_activity,
    notify_new_request: currentUser?.notify_new_request,
    notify_weekly_newsletter: currentUser?.notify_weekly_newsletter,
    notify_news_updates: currentUser?.notify_news_updates,
  };

  const notificationsData = [
    {
      label: "Alerts & Notification",
      name: "notify_new_activity",
      description:
        "Get notifications on all transactions perfomed on your account.",
      value: currentUser?.notify_new_activity,
    },
    {
      label: "Account Activities",
      name: "notify_new_request",
      description:
        "Get important notifications about activities you've missed.",
      value: currentUser?.notify_new_request,
    },
    {
      label: "Weekly Newletters",
      name: "notify_weekly_newsletter",
      description: "Tips on getting more out of Kpali.",
      value: currentUser?.notify_weekly_newsletter,
    },
    {
      label: "News and updates",
      name: "notify_news_updates",
      description: "News about product and feature updates.",
      value: currentUser?.notify_news_updates,
    },
  ];

  return (
    <form>
      <Stack gap={"1em"}>
        <Stack gap={0}>
          <Title order={2}>Notification</Title>
          <Text component="span" fw={500}>
            Choose when and how we contact you.
          </Text>
        </Stack>
        <Stack gap={"lg"}>
          <div>
            <Title order={5} style={{ fontWeight: 600 }}>
              Notification from us
            </Title>
            <Text component="span" fw={500} c={"gray"}>
              Get notifications on all transactions perfomed on your account.
            </Text>
          </div>
          {isLoading ? (
            <Paper
              style={{
                borderColor: "#E8E8E8",
              }}
              shadow="xs"
              withBorder={true}
              radius="md"
              mt="md"
              p={"md"}
            >
              <div style={{ height: 200, position: "relative" }}>
                <LoadingOverlay visible />
              </div>
            </Paper>
          ) : (
            <Stack gap={"md"}>
              <Group gap={"sm"}>
                <div>
                  <CustomCheckBox
                    disabled={isPending}
                    checked={initialFormData.notify_new_activity}
                    onChange={(e) => {
                      mutate({
                        notify_new_activity: e.target.checked,
                        notify_new_request: e.target.checked,
                        notify_weekly_newsletter: e.target.checked,
                        notify_news_updates: e.target.checked,
                      });
                    }}
                  />
                </div>
                <Stack gap={0}>
                  <Title order={5} fw={600}>
                    {notificationsData[0].label}
                  </Title>
                  <Text component="span" fw={500} c={"gray"}>
                    {notificationsData[0].description}
                  </Text>
                </Stack>
              </Group>
              <Group gap={"sm"}>
                <div>
                  <CustomCheckBox
                    disabled={isPending}
                    checked={initialFormData.notify_new_request}
                    onChange={(e) => {
                      mutate({
                        notify_new_request: e.target.checked,
                      });
                    }}
                  />
                </div>
                <Stack gap={0}>
                  <Title order={5} fw={600}>
                    {notificationsData[1].label}
                  </Title>
                  <Text component="span" fw={500} c={"gray"}>
                    {notificationsData[1].description}
                  </Text>
                </Stack>
              </Group>
              <Group gap={"sm"}>
                <div>
                  <CustomCheckBox
                    disabled={isPending}
                    checked={initialFormData.notify_weekly_newsletter}
                    onChange={(e) => {
                      mutate({
                        notify_weekly_newsletter: e.target.checked,
                      });
                    }}
                  />
                </div>
                <Stack gap={0}>
                  <Title order={5} fw={600}>
                    {notificationsData[2].label}
                  </Title>
                  <Text component="span" fw={500} c={"gray"}>
                    {notificationsData[2].description}
                  </Text>
                </Stack>
              </Group>
              <Group gap={"sm"}>
                <div>
                  <CustomCheckBox
                    disabled={isPending}
                    checked={initialFormData.notify_news_updates}
                    onChange={(e) => {
                      mutate({
                        notify_news_updates: e.target.checked,
                      });
                    }}
                  />
                </div>
                <Stack gap={0}>
                  <Title order={5} fw={600}>
                    {notificationsData[3].label}
                  </Title>
                  <Text component="span" fw={500} c={"gray"}>
                    {notificationsData[3].description}
                  </Text>
                </Stack>
              </Group>
            </Stack>
          )}
        </Stack>
      </Stack>
    </form>
  );
}

export default Notifications;
