import { IconProps } from "./overviewIcon";

export const RequestedIcon = ({
  className,
  width,
  height,
  fill,
}: IconProps) => (
  <svg
    width={width || "19"}
    height={height || "19"}
    className={className}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="carbon:request-quote">
      <path
        id="Vector"
        d="M13.0625 13.0625V16.625H3.5625V2.375H9.5V1.1875H3.5625C3.24756 1.1875 2.94551 1.31261 2.72281 1.53531C2.50011 1.75801 2.375 2.06006 2.375 2.375V16.625C2.375 16.9399 2.50011 17.242 2.72281 17.4647C2.94551 17.6874 3.24756 17.8125 3.5625 17.8125H13.0625C13.3774 17.8125 13.6795 17.6874 13.9022 17.4647C14.1249 17.242 14.25 16.9399 14.25 16.625V13.0625H13.0625Z"
        fill={fill || "#797979"}
      />
      <path
        id="Vector_2"
        d="M17.5394 3.42L15.58 1.46062C15.4024 1.28656 15.1637 1.18906 14.915 1.18906C14.6663 1.18906 14.4276 1.28656 14.25 1.46062L5.9375 9.77312V13.0625H9.22094L17.5334 4.75C17.7075 4.57241 17.805 4.33366 17.805 4.085C17.805 3.83633 17.7075 3.59758 17.5334 3.42H17.5394ZM8.72812 11.875H7.125V10.2719L12.73 4.66093L14.3391 6.27L8.72812 11.875ZM15.1762 5.43281L13.5672 3.82375L14.915 2.47593L16.5241 4.085L15.1762 5.43281Z"
        fill={fill || "#797979"}
      />
    </g>
  </svg>
);

RequestedIcon.displayName = "RequestedIcon";
