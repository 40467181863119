import { Group, Stack, Image, Text, Title } from "@mantine/core";
import Banner from "./banner";
import { useState } from "react";
import { ArrowUp, ArrowDown } from "tabler-icons-react";
import AdminLayout from "../../../layouts/adminLayout";
import UserIcon from "../../../assets/svg/adminDashboardUserIcon.svg";
import RequestedIcon from "../../../assets/svg/adminDashboardRequestedIcon.svg";
import CompletedIcon from "../../../assets/svg/adminDashboardCompletedIcon.svg";
import PendingIcon from "../../../assets/svg/adminDashboardPendingIcon.svg";
import { useGetAdminDashboard } from "../../../../hooks/admin/dashboard.hook";
import CustomTable from "../../shared/table/customTable";

import RequestStatusText from "../../shared/requestStatus";
import { formatDate } from "../../shared/reusableFunctions";
import { ActitonButton } from "./users/users";
import { useGetAllRequests } from "../../../../hooks/admin/requests.hook";
import { useNavigate } from "react-router-dom";
import { CustomCheckBox } from "../../shared/inputs";
import { ServiceCategory, RequestStatus } from "../../../../enums/enums";
import GhostIcon from "../../../assets/svg/ghostIcon.svg";

function AdminDashboard() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data: request, isPending: isRequestPending } = useGetAllRequests(
    page,
    5,
    RequestStatus.PENDING
  );
  const { data, isPending } = useGetAdminDashboard();

  const requestsData = request?.data?.data;
  const requestsCount = request?.data?.count;
  const requestsTotalPages = request?.data?.totalPages;
  const requestsCurrentPage = request?.data?.currentPage;

  const handleNavigate = (id: string) => {
    navigate(`/admin/requests/${id}`);
  };

  const TableData = requestsData?.map((request: any, index: number) => {
    return {
      checbox: <CustomCheckBox />,
      id: request?.certificates[0].applicantName,
      serviceCategory: (
        <Stack gap={"0"}>
          <Text>{request?.serviceCategory}</Text>
          <Text fz={"sm"}>
            {request?.serviceCategory === ServiceCategory.CERTIFICATE
              ? request?.certificates[0]?.certificateType
                  ?.charAt(0)
                  .toUpperCase() +
                request?.certificates[0]?.certificateType
                  ?.slice(1)
                  .toLowerCase()
              : request?.documents[0]?.documentType?.charAt(0).toUpperCase() +
                request?.documents[0]?.documentType?.slice(1).toLowerCase()}
          </Text>
        </Stack>
      ),
      date: formatDate(request?.createdAt),
      status: <RequestStatusText status={request?.status} />,
      action: <ActitonButton onClick={() => handleNavigate(request?.id)} />,
    };
  });

  const cardDetails = [
    {
      title: "USERS",
      icon: UserIcon,
      count: data?.users?.count,
      percentageIncrease: data?.users?.percentageIncrease,
    },
    {
      title: "REQUESTED SERVICES",
      icon: RequestedIcon,
      count: data?.requests?.count,
      percentageIncrease: data?.requests?.percentageIncrease,
    },
    {
      title: "COMPLETED SERVICES",
      icon: CompletedIcon,
      count: data?.completedRequests?.count,
      percentageIncrease: data?.completedRequests?.percentageIncrease,
    },
    {
      title: "PENDING SERVICES",
      icon: PendingIcon,
      count: data?.pendingRequests?.count,
      percentageIncrease: data?.pendingRequests?.percentageIncrease,
    },
  ];

  return (
    <AdminLayout title="Dashboard">
      <Banner />
      <CustomTable
        title={"Recent Requests"}
        isTableLoading={isRequestPending}
        tableLoadingCount={5}
        currentPage={requestsCurrentPage}
        totalPages={requestsTotalPages}
        setPage={setPage}
        count={requestsCount}
        minWidth={"850px"}
        bodyRows={TableData}
        labels={[
          <CustomCheckBox />,
          "Tracking",
          "Service",
          "Date",
          "Status",
          "",
        ]}
        emptyState={{
          title: "No Recent Requests Found",
          description:
            "They will appear here once users make requests for services.",
          image: GhostIcon,
        }}
      />
    </AdminLayout>
  );
}

export default AdminDashboard;

const InsightCard = ({
  title,
  icon,
  count,
  percentageIncrease,
}: {
  title: string;
  icon: any;
  count: number;
  percentageIncrease: string;
}) => {
  const isNegative = percentageIncrease?.includes("-");

  return (
    <Stack
      bg={"light-grey.4"}
      p={"1em"}
      style={{
        borderRadius: "16px",
        border: "1.3px solid #E8E8E8",
        boxShadow: "2px 2px 16px 0px #00000014",
      }}
    >
      <Group>
        <div
          style={{
            backgroundColor: "#F5F4F3",
            padding: "15px",
            borderRadius: "50%",
          }}
        >
          <Image src={icon} alt={title} />
        </div>
        <Stack gap={0}>
          <Text c={"dark.10"}>{title}</Text>
          <Title order={3}>{count}</Title>
        </Stack>
      </Group>
      <Group gap={"0.25em"}>
        <Group
          gap={0}
          style={{ borderRadius: "0.5em" }}
          p={"0.25em 0.5em"}
          align="center"
          justify="center"
          bg={isNegative ? "#F5D9DD" : "#D8F2EE"}
        >
          {isNegative ? (
            <ArrowDown size={18} color="#C22839" fontWeight={400} />
          ) : (
            <ArrowUp size={18} color="#177567" fontWeight={400} />
          )}
          <Text c={isNegative ? "#C22839" : "#177567"} fw={600}>
            {percentageIncrease?.replace("-", "")}
          </Text>
        </Group>
        <Text c={"light-grey.11"} fw={500}>
          Compared to last month
        </Text>
      </Group>
    </Stack>
  );
};
