import {
  Stack,
  Title,
  Group,
  Button,
  Divider,
  Text,
  Box,
  LoadingOverlay,
  Modal,
  Paper,
} from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  useCurrentWorkspace,
  useUpdateWorkspace,
  useDeleteWorkspace,
} from "../../../../../../hooks/user/user.workspace.hook";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomTextInput } from "../../../../shared/inputs";
import {
  UpdateWorkspaceDto,
  UpdateWorkspaceSchema,
} from "../../../../../../schema/workspace/workspace";
import WorkspaceRBAC from "./rbac";

function WorkspaceDetails() {
  const { workspaceDetails: workspace, isLoading } = useCurrentWorkspace();
  const { mutate, isPending } = useUpdateWorkspace(workspace?.id);
  const { mutate: deleteWorkspace, isPending: isDeleting } = useDeleteWorkspace(
    workspace?.id
  );
  const [isDeleteOpen, setDeleteOpen] = useState(false);

  const initialFormData = useMemo(() => {
    return {
      name: workspace?.name,
      type: workspace?.type,
      domain: workspace?.domain,
    };
  }, [workspace]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateWorkspaceDto>({
    defaultValues: initialFormData,
    resolver: yupResolver(UpdateWorkspaceSchema),
  });

  const onSubmit: SubmitHandler<UpdateWorkspaceDto> = (data) => {
    mutate({ ...data, domain: data.domain || undefined });
  };

  useEffect(() => {
    reset(initialFormData);
  }, [initialFormData, reset]);

  return (
    <WorkspaceRBAC action={"canViewWorkspaceMembers"} renderNoAccess>
      <Stack gap={"xl"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={"1em"}>
            <Stack gap={0}>
              <Title order={2}>Workspace Details</Title>
              <Text component="span" fw={500} c="gray">
                Update your workspace details.
              </Text>
            </Stack>
            {isLoading ? (
              <Paper
                style={{
                  borderColor: "#E8E8E8",
                }}
                shadow="xs"
                withBorder={true}
                radius="md"
                mt="md"
                p={"md"}
              >
                <div style={{ height: 200, position: "relative" }}>
                  <LoadingOverlay visible />
                </div>
              </Paper>
            ) : (
              <>
                <CustomTextInput
                  label="Workspace Name"
                  placeholder="Workspace name"
                  register={register("name")}
                  error={errors.name?.message}
                />
                <CustomTextInput
                  label="Domain"
                  placeholder="https://kpali.ng"
                  register={register("domain")}
                  notRequired
                  error={errors.domain?.message}
                />
                <Group justify="space-between">
                  <Button
                    type="submit"
                    disabled={isPending}
                    loading={isPending}
                  >
                    Save Changes
                  </Button>
                </Group>
              </>
            )}
          </Stack>
        </form>
        <WorkspaceRBAC action={"canDeleteWorkspace"}>
          <Stack>
            <div>
              <Title order={4}>Remove Workspace</Title>
              <Text component="span" fw={500} c="gray">
                Be careful about this operation as deleted workspaces cannot be
                retrieved.
              </Text>
            </div>

            <Box>
              <Button
                variant="outline"
                color="red"
                onClick={() => setDeleteOpen(true)}
              >
                Delete Workspace
              </Button>
            </Box>
            <Modal
              opened={isDeleteOpen}
              onClose={() => setDeleteOpen(false)}
              title="Delete Workspace"
              // centered
              size={"sm"}
            >
              <Stack gap={"lg"}>
                <Text>Are you sure you want to delete this workspace?</Text>

                <Divider c={"orange.4"} />

                <Button
                  loading={isDeleting}
                  variant="outline"
                  color="red"
                  onClick={() => {
                    deleteWorkspace();
                  }}
                >
                  Confirm Delete
                </Button>
              </Stack>
            </Modal>
          </Stack>
        </WorkspaceRBAC>
      </Stack>
    </WorkspaceRBAC>
  );
}

export default React.memo(WorkspaceDetails);
