import { Text } from "@mantine/core";
import { IPaymentStatus, PaymentStatus } from "../../../enums/enums";

const PaymentStatusText = ({ status }: { status: IPaymentStatus }) => {
  let bgColor, textColor;

  switch (status) {
    case PaymentStatus.SUCCESS:
      bgColor = "#E2F8E5";
      textColor = "#319755";
      break;
    case PaymentStatus.PENDING:
      bgColor = "#E9AA4C38";
      textColor = "#DD8705";
      break;
    case PaymentStatus.FAILED:
      bgColor = "#FFD3D9";
      textColor = "#C22839";
      break;
    case PaymentStatus.REFUNDED:
      bgColor = "#FFD3D9";
      textColor = "#C22839";
      break;
    case PaymentStatus.UNVERIFIED:
      bgColor = "#FFD3D9";
      textColor = "#C22839";
      break;
    default:
      bgColor = "#FFD3D9";
      textColor = "#C22839";
  }
  return (
    <Text
      style={{
        borderRadius: "7.5px",
      }}
      component="span"
      bg={bgColor}
      c={textColor}
      p={"0.25em 0.5em"}
    >
      {status
        ?.split("_")
        .join(" ")
        ?.split(" ")
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")}
    </Text>
  );
};

export default PaymentStatusText;
