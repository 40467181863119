import { useQuery } from "@tanstack/react-query";
import { IRequestStatus } from "../../enums/enums";
import API from "../../api/api";

const URL = "/request";

export const useGetWorkspaceRequests = (
  workspaceId: string,
  page: number,
  limit: number,
  status?: IRequestStatus
) => {
  const getWorkspaceRequests = async () => {
    if (!status) {
      const response = await API.get(
        `${URL}/${workspaceId}?page=${page}&limit=${limit}`
      );
      return response;
    }
    const response = await API.get(
      `${URL}/${workspaceId}?page=${page}&limit=${limit}&status=${status}`
    );
    return response;
  };

  const query = useQuery({
    queryFn: getWorkspaceRequests,
    queryKey: [`GetWorkspaceRequests`, page, limit, workspaceId, status],
  });
  return query;
};

export const useGetRequestDetails = (
  workspaceId: string,
  requestId: string
) => {
  const getRequestDetails = async () => {
    const response = await API.get(`${URL}/${workspaceId}/${requestId}`);
    return response;
  };

  const query = useQuery({
    queryFn: getRequestDetails,
    queryKey: [`GetRequestDetails`, workspaceId, requestId],
  });
  return query;
};
