import { Box, Flex, Stack, Grid, Text } from "@mantine/core";
import { RequestComponent } from "./requestDetails";

function RequesterSection({ requestDetails }: any) {
  const RequesterDetails = [
    {
      title: "Full Name",
      value: requestDetails?.requester?.businessName
        ? requestDetails?.requester?.businessName
        : requestDetails?.requester?.firstName +
          " " +
          requestDetails?.requester?.lastName,
    },
    { title: "Email", value: requestDetails?.requester?.email },
    { title: "Phone Number", value: requestDetails?.requester?.phoneNo },
    {
      title: "Workspace Name",
      value: requestDetails?.request?.workspace?.name,
    },

    {
      title: "Workspace Type",
      value: requestDetails?.request?.workspace?.accountType,
    },
    {
      title: "Workspace Role",
      value: requestDetails?.request?.requester?.role,
    },
  ];

  return (
    <RequestComponent requestId={requestDetails?.request?.id}>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 0.5,
            minWidth: "600px",
            borderRadius: "8px",
            border: "1.5px solid #E8E8E8",
          }}
        >
          <Grid
            m={{
              base: "1.5em",
              sm: "lg",
            }}
          >
            {RequesterDetails.map((detail, index) => (
              <RequestCard
                key={index}
                title={detail.title}
                value={detail.value}
              />
            ))}
          </Grid>
        </div>
        <div
          style={{
            flex: 1,
          }}
        ></div>
      </div>
    </RequestComponent>
  );
}

export default RequesterSection;

export const RequestCard = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <Grid.Col
      span={{
        base: 12,
        md: 6,
      }}
    >
      <Text c={"light-grey.8"}>{title}</Text>
      <Text c={"dark.4"} fw={700}>
        {value}
      </Text>
    </Grid.Col>
  );
};
