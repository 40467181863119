import { Box, em, Group, Stack, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDown } from "../../assets/icons";

interface LinkProps {
  link: string;
  label: string;
  isExternalLink?: boolean;
}

interface IFooterLinksBlock {
  title: string;
  links: LinkProps[];
}

export const FooterLinksBlock = ({ title, links }: IFooterLinksBlock) => {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const [showFooterLink, setShowFooterLink] = useState(true);

  const toggleShowFooterLink = () => {
    if (isMobile) {
      setShowFooterLink(!showFooterLink);
    }
  };

  return (
    <Stack gap={"1.7em"} w={isMobile ? "100%" : "auto"}>
      <Group justify={"space-between"} onClick={toggleShowFooterLink}>
        <Title order={5} size="1.25rem" c="light-grey">
          {title}
        </Title>
        <Box hiddenFrom={"sm"}>
          <ChevronDown
            style={{
              transform: showFooterLink ? "rotate(180deg)" : "none",
              transition: "transform 0.3s ease",
            }}
          />
        </Box>
      </Group>
      {showFooterLink && (
        <Stack gap={"1.7em"}>
          {links.map((link, index) => (
            <Link
              to={link.link}
              key={index}
              target={link.isExternalLink ? "_blank" : "_self"}
              style={{
                textDecoration: "none",
              }}
            >
              <Text fz="1em" c="light-grey.1">
                {link.label}
              </Text>
            </Link>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
