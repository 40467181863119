import {
  Button,
  Group,
  Stack,
  Text,
  Title,
  Box,
  LoadingOverlay,
  Paper,
} from "@mantine/core";
import { useState, useEffect, useMemo } from "react";
import { Country, State, City } from "country-state-city";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateProfile } from "../../../../../hooks/user/user.hook";
import {
  UpdateProfileDto,
  UpdateProfileSchema,
} from "../../../../../schema/user/user";
import {
  CustomPhoneInput,
  CustomSelectInput,
  CustomTextInput,
} from "../../../shared/inputs";
import { WorkspaceType } from "../../../../../enums/enums";
import { useCurrentWorkspace } from "../../../../../hooks/user/user.workspace.hook";

function Profile() {
  const { currentUser: user, isLoading } = useCurrentWorkspace();
  const { mutate, isPending } = useUpdateProfile();
  const [countriesSelect, setCountriesSelect] = useState<any[]>([]);
  const [statesSelect, setStatesSelect] = useState<any[]>([]);
  const [citiesSelect, setCitiesSelect] = useState<any[]>([]);

  const initialFormData = useMemo(() => {
    return {
      businessName: user?.businessName === null ? "" : user?.businessName || "",
      firstName: user?.firstName === null ? "" : user?.firstName || "",
      lastName: user?.lastName === null ? "" : user?.lastName || "",
      country: user?.country || "",
      state: user?.state || "",
      city: user?.city || "",
      phoneNo: user?.phoneNo || "",
      accountType: user?.businessName
        ? WorkspaceType.CORPORATE
        : WorkspaceType.PERSONAL,
    };
  }, [user]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<UpdateProfileDto>({
    defaultValues: initialFormData,
    resolver: yupResolver(UpdateProfileSchema),
  });

  const onSubmit: SubmitHandler<UpdateProfileDto> = (data) => {
    mutate(data);
  };

  useEffect(() => {
    reset(initialFormData);
  }, [initialFormData, reset]);

  const watchCountry = watch("country");
  const watchState = watch("state");

  useEffect(() => {
    const countries = Country.getAllCountries();
    const countriesSelected = countries.map((country) => ({
      value: country.isoCode + "," + country.name,
      label: country.name,
    }));
    setCountriesSelect(countriesSelected);
  }, []);

  useEffect(() => {
    const countryValue = watch("country");
    const countryCode = countryValue?.split(",")[0];
    const states = State.getStatesOfCountry(countryCode);
    const statesSelected = states.map((state) => ({
      value: state.isoCode + "," + state.name,
      label: state.name,
    }));
    setStatesSelect(statesSelected);
  }, [watchCountry, watch]);

  useEffect(() => {
    const countryValue = watch("country");
    const stateValue = watch("state");
    const countryCode = countryValue?.split(",")[0];
    const stateCode = stateValue?.split(",")[0];
    const cities = City.getCitiesOfState(countryCode, stateCode);
    const citiesSelected = cities.map((city) => ({
      value: city.name,
      label: city.name,
    }));
    setCitiesSelect(citiesSelected);
  }, [watchState, watchCountry, watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={"lg"}>
        <Stack gap={0}>
          <Title order={2}>Profile</Title>
          <Text component="span" fw={500} c="gray">
            Update your photo and personal details.
          </Text>
        </Stack>
        {!isLoading ? (
          <>
            {" "}
            <Stack gap="md">
              {user?.lastName && (
                <Group gap={"md"} grow justify="space-between">
                  <CustomTextInput
                    label="First Name"
                    placeholder="First Name"
                    register={register("firstName")}
                    error={errors.firstName?.message}
                  />
                  <CustomTextInput
                    label="Last Name"
                    placeholder="Last Name"
                    register={register("lastName")}
                    error={errors.lastName?.message}
                  />
                </Group>
              )}
              {user?.businessName && (
                <CustomTextInput
                  label="Business Name"
                  placeholder="Business Name"
                  register={register("businessName")}
                  error={errors.businessName?.message}
                />
              )}
              <CustomTextInput
                label="Email"
                placeholder="Email"
                value={{ value: user?.email }}
                disabled
              />
              <CustomPhoneInput
                label="Phone Number"
                value={watch("phoneNo")}
                error={errors.phoneNo?.message}
                onChange={(value) => {
                  setValue("phoneNo", value);
                }}
              />
              <Group gap={"md"} grow justify="space-between">
                <CustomSelectInput
                  label={"Country"}
                  data={countriesSelect}
                  value={{ value: watch("country") }}
                  register={register("country")}
                  error={errors.country?.message}
                  onChange={() => {
                    setValue("state", "");
                    setValue("city", "");
                  }}
                />
                <CustomSelectInput
                  label={"State"}
                  data={statesSelect}
                  value={{ value: watch("state") }}
                  register={register("state")}
                  error={errors.state?.message}
                  onChange={() => {
                    setValue("city", "");
                  }}
                />
                <CustomSelectInput
                  label={"City"}
                  data={citiesSelect}
                  value={{ value: watch("city") }}
                  error={errors.city?.message}
                  register={register("city")}
                />
              </Group>
            </Stack>
            <Box>
              <Button
                fullWidth={false}
                loading={isPending}
                onClick={handleSubmit((data) => {
                  onSubmit(data);
                })}
              >
                Save Changes
              </Button>
            </Box>
          </>
        ) : (
          <Paper
            style={{
              borderColor: "#E8E8E8",
            }}
            shadow="xs"
            withBorder={true}
            radius="md"
            mt="md"
            p={"md"}
          >
            <div style={{ height: 200, position: "relative" }}>
              <LoadingOverlay visible />
            </div>
          </Paper>
        )}
      </Stack>
    </form>
  );
}

export default Profile;
