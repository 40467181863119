import { useEffect, useState } from "react";
import { Group, Anchor, Text, Stack } from "@mantine/core";
import {
  useVerifyEmail,
  useResendEmailVerification,
} from "../../../hooks/auth/auth.hook";
import AuthLayout from "../../layouts/auth";
import useGetQueryParams from "../../../hooks/useGetUrlParams";
import OrangeButton from "../shared/buttons/orangeButton";
import { useForm } from "react-hook-form";
import { CustomTextInput } from "../shared/inputs";

function VerifyEmail() {
  const user = useGetQueryParams("user");
  const token = useGetQueryParams("token");
  const email = useGetQueryParams("email");
  const [isEmail, setIsEmail] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const { mutate, isPending } = useVerifyEmail(token!, user!);
  const { mutate: resend, isPending: isResendPending } =
    useResendEmailVerification();

  const { register, setValue } = useForm({
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (email) {
      setValue("email", email!);
      setIsEmail(true);
    }
  }, [email, setValue]);

  useEffect(() => {
    if (token && user) {
      setIsVerification(true);
    }
  }, [token, user, mutate]);

  return (
    <AuthLayout title="Verify Email">
      {isEmail && (
        <Text ta={"center"}>
          A verification email has been sent to your email address. Please check
          your email and click on the link to verify your email address.
        </Text>
      )}

      <Text ta={"center"}>
        {isEmail
          ? "If you didint receive a verification email, click the button below to resend verification"
          : "Click the button below to verify your email"}
      </Text>
      {isEmail && (
        <CustomTextInput
          mobileWhite
          disabled
          label={"Email"}
          placeholder={"Email address"}
          register={register("email")}
        />
      )}
      {isEmail && (
        <OrangeButton
          loading={isResendPending}
          buttonText={"Resend Verification Email"}
          onClick={() => resend(email!)}
        />
      )}
      {isVerification && (
        <OrangeButton
          loading={isPending}
          buttonText={"Verify Email"}
          onClick={() => mutate()}
        />
      )}
      <Stack w={"100%"}>
        <Group justify={"space-between"}>
          <Text c={{ base: "white", sm: "white", md: "dark" }}>
            <Anchor href="/login">Login</Anchor>
          </Text>
          <Text fw={"800"}>
            <Anchor href="/signup">Sign-up</Anchor>
          </Text>
        </Group>
      </Stack>
    </AuthLayout>
  );
}

export default VerifyEmail;
