import API from "../../api/api";
import { toast } from "react-toastify";
import { setCookies } from "../../components/primitives/auth/authCheck";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  SignInDto,
  ForgotPasswordDto,
  ResetPasswordDto,
  SignUpDto,
} from "../../schema/auth/auth.";
import Cookies from "js-cookie";
import { useQuery } from "react-query";

const URL = "users/auth";

export interface PreAuthCheckDto {
  email: string;
}

export const useSignUp = () => {
  const signUp = async (data: SignUpDto) => {
    const response = await API.post(`${URL}/signup`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: signUp,
    onSuccess: (res) => {
      toast.success("Veriifcation link sent to email", {
        position: "bottom-center",
        autoClose: 8000,
      });
      window.location.replace(
        `/verifyemail?email=${encodeURIComponent(res.data.email)}`
      );
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const PreAuthCheckApi = async (data: PreAuthCheckDto) => {
  const response = await API.post(`${URL}/preAuthCheck`, data);
  return response;
};

export const useSignIn = () => {
  const signIn = async (data: SignInDto) => {
    const response = await API.post(`${URL}/signin`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: signIn,
    onSuccess: (res) => {
      toast.success("Login successful");
      const token = res.data.token;
      const refreshToken = res.data.refreshToken;
      const role = res.data.user.appRole;
      setCookies({ name: "token", data: token });
      setCookies({ name: "refreshToken", data: refreshToken });
      setCookies({ name: "role", data: role });
      window.location.replace("/overview");
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const logout = () => {
  Cookies.remove("token");
  Cookies.remove("role");
  Cookies.remove("refreshToken");
  window.location.replace("/login");
};

export const useSignOut = () => {
  const queryClient = useQueryClient();
  const signOut = async () => {
    const response = await API.post(`${URL}/signout`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: signOut,
    onSuccess: () => {
      toast.success("Logout successful");
      queryClient.clear();
      logout();
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useVerifyEmail = (token: string, id: string) => {
  const verifyEmail = async () => {
    const response = await API.patch(`${URL}/verifyemail/${id}/${token}`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: verifyEmail,
    onSuccess: () => {
      toast.success("Email verification successful");
      setTimeout(() => {
        window.location.replace("/login");
      }, 500);
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useGetIdTokenFromRefreshToken = () => {
  const getIdToken = async () => {
    const data = {
      refreshToken: Cookies.get("refreshToken"),
    };
    const response = await API.post(`${URL}/getidtoken`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: getIdToken,
    onSuccess: (res) => {
      const idToken = res.data.id_token;
      const refreshToken = res.data.refresh_token;
      setCookies({ name: "token", data: idToken });
      setCookies({ name: "refreshToken", data: refreshToken });

      toast.success("Reauthentication successful");
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useResetPassword = () => {
  const resetPassword = async (data: ResetPasswordDto) => {
    const response = await API.post(`${URL}/resetpassword`, {
      ...data,
    });
    return response;
  };
  const mutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      toast.success("Password reset successful");
      setTimeout(() => {
        window.location.replace("/login");
      }, 1000);
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useForgotPassword = () => {
  const forgotPassword = async (data: ForgotPasswordDto) => {
    const response = await API.post(`${URL}/forgotpassword`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      toast.success("Password reset link sent");
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useResendEmailVerification = () => {
  const resendEmailVerification = async (email: string) => {
    const response = await API.post(`${URL}/resendemailverification`, {
      email,
    });
    return response;
  };
  const mutation = useMutation({
    mutationFn: resendEmailVerification,
    onSuccess: () => {
      toast.success("Verification link sent to email");
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useGetVerifyEmail = (code: string, userId: string) => {
  const getVerifiyEmail = async () => {
    const response = await API.get(
      `${URL}/verifyemail?code=${code}&userId=${userId}`
    );
    return response;
  };

  const query = useQuery({
    queryFn: getVerifiyEmail,
    queryKey: ["verifyEmail", code, userId],
  });
  return query;
};
