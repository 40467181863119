import { Box } from "@mantine/core";

function GreyBackground({ children }: { children: React.ReactNode }) {
  return (
    <Box
      bg="light-grey.3"
      pt={{ base: "2em", md: "4.5em" }}
      pb={"4em"}
      mb={{ base: "2em", md: "5.6em" }}
    >
      {children}
    </Box>
  );
}

export default GreyBackground;
