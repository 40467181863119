import axios from "axios";
import { getFirebaseAuth } from "../services/firebase";
import { signOut } from "firebase/auth";

const API = axios.create({
  baseURL: ` ${process.env.REACT_APP_API_URL}/api`,
  timeout: 20000,
});

API.interceptors.request.use(
  async (config) => {
    // get the authentication token from local storage if it exists
    const accessToken = await getFirebaseAuth.currentUser?.getIdToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      signOut(getFirebaseAuth);
    }
    return Promise.reject(error);
  }
);

export default API;
