import { Button, Group, Select, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { CertificateVerificationStatus } from "../../../enums/enums";
import { updateCertificateRecordApi } from "../../../hooks/admin/certificate.hook";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const EditStatusForm = ({
  record,
  closeEdit,
  workspaceId,
}: {
  record: any;
  closeEdit: () => void;
  workspaceId: string;
}) => {
  const queryClient = useQueryClient();
  const form = useForm<any>({
    initialValues: {
      status: record?.status,
    },
  });

  const updateRecord = useMutation({
    mutationFn: updateCertificateRecordApi,
    onSuccess: () => {
      toast.success("Certificate Record Update Successfully");
      if (closeEdit) closeEdit();
      queryClient.invalidateQueries([
        "GetRequestDetails",
        workspaceId,
        record.requestId,
      ]);
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });

  const onSubmit = (data: any) => {
    updateRecord.mutate(
      {
        ...data,
        id: record.id,
      },
      {}
    );
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack gap={"md"}>
        <Select
          required
          label="Status"
          data={Object.values(CertificateVerificationStatus).map((value) => ({
            label: value,
            value,
          }))}
          {...form.getInputProps("status")}
        />
        <Group mt={"md"}>
          <Button variant="default" onClick={closeEdit}>
            Cancel
          </Button>
          <Button
            type="submit"
            // disabled={updateRecord.isLoading}
            // loading={updateRecord.isLoading}
          >
            Submit
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
