import { Stack, Group, Title, Text, rem } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useMemo } from "react";
import { AppRoles } from "../../enums/enums";
import { getNameInitials } from "../../components/primitives/shared/reusableFunctions";
import { useCurrentWorkspace } from "../../hooks/user/user.workspace.hook";
import { useState } from "react";
import UserMenuLoader from "../../components/primitives/shared/skeletonLoader/userMenuLoader";
import UserMenu from "./userMenu/userMenu";
import { Burger } from "@mantine/core";
import { KpaliLogo } from "../../components/assets/icons";
import useResponsive from "../../hooks/useResponsive";
import UsernavBar from "../userDashboard/userNavbar";
import AdminNavbar from "../adminDashboard/adminNavbar";

function DashboardHeader({
  title,
  isAdmin,
}: {
  title: string;
  isAdmin?: boolean;
}) {
  const { isTablet } = useResponsive();
  const [opened, { toggle }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { currentUser, workspaceDetails, isLoading } = useCurrentWorkspace();
  const name = useMemo(() => {
    return currentUser?.businessName
      ? currentUser?.businessName
      : `${currentUser?.firstName} ${currentUser?.lastName}`;
  }, [currentUser]);

  const workspaceName = useMemo(() => {
    return window.location.pathname.includes("admin")
      ? "Admin"
      : workspaceDetails?.name;
  }, [workspaceDetails]);

  const user = {
    name: name,
    workspaceName: workspaceName,
    initials: getNameInitials({ name }),
  };

  return (
    <div>
      <Stack
        bg="light-grey.4"
        style={{
          borderBottom: "1.20px solid #E8E8E8",
          boxShadow: "#00000014",
        }}
      >
        <Group justify="space-between" p={{ base: "xs", md: "1em 3em" }}>
          {!isTablet && (
            <Title order={3} c={"dark.4"} style={{ fontWeight: "500" }}>
              {title}
            </Title>
          )}
          {isTablet && (
            <>
              <Burger opened={opened} onClick={toggle} />
              <KpaliLogo style={{ width: rem(100) }} />
            </>
          )}

          {!isLoading && (
            <UserMenu
              user={user}
              userMenuOpened={userMenuOpened}
              setUserMenuOpened={setUserMenuOpened}
            />
          )}

          {isLoading && <UserMenuLoader />}
        </Group>
      </Stack>
      {opened && isTablet && !isAdmin && <UsernavBar toggle={toggle} />}
      {opened && isTablet && isAdmin && <AdminNavbar toggle={toggle} />}
    </div>
  );
}

export default React.memo(DashboardHeader);
