import { FileImg, FilePdf, FileDoc, FileGen } from "../../assets/icons/file";

export const formatNumber = (number: number) => {
  return number?.toLocaleString() || "";
};

export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const newDate = new Date(date);
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    newDate
  );

  const day = newDate.getDate();
  let suffix = "";
  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  } else {
    suffix = "th";
  }

  return formattedDate.replace(/(\d+)(st|nd|rd|th)/, `$1${suffix}`);
};

export const calculateSN = (
  currentPage: number,
  limit: number,
  totalCount: number,
  index: number
) => {
  return totalCount - (currentPage - 1) * limit - index;
};

export const getNameInitials = (params: {
  name: string | undefined;
  maxInitials?: number;
}) => {
  if (!params.name) return "";
  return params.name
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((value) => !!value)
    .slice(0, params.maxInitials)
    .join("")
    .toUpperCase();
};

export const getIconByFileExtension = (extension: string) => {
  const ext = extension.toLowerCase();
  if (["png", "jpg", "jpeg", "svg", "gif"].includes(ext)) return FileImg;
  if (["pdf"].includes(ext)) return FilePdf;
  if (["doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt"].includes(ext))
    return FileDoc;
  return FileGen;
};

export const getFileExtension = (
  filename: string,
  options?: {
    preserveCase?: boolean;
  }
) => {
  if (!filename) return "";
  const ext = (/[^./\\]*$/.exec(filename) || [""])[0];
  return options?.preserveCase ? ext : ext.toUpperCase();
};

export const greetUser = () => {
  const date = new Date();
  const hours = date.getHours();
  if (hours < 12) return "Good Morning";
  if (hours < 18) return "Good Afternoon";
  return "Good Evening";
};
