import Skeleton from "react-loading-skeleton";
import { Stack } from "@mantine/core";

const CardLoader = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
      <div style={{ width: "20%", maxWidth: "60px" }}>
        <Skeleton circle height={60} />
      </div>

      <div style={{ width: "100%", overflowX: "auto" }}>
        <Stack gap={"0.25em"}>
          <Skeleton height={20} />
          <Skeleton height={80} />
        </Stack>
      </div>
    </div>
  );
};

export default CardLoader;
