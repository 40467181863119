import { LandingLayout } from "../components/layouts/landing";
import Services from "../components/primitives/certificatesPage/services";
import AboutImage from "../components/primitives/aboutPage/aboutImage";
import ServicesImage from "../components/assets/images/servicesWeOfferImage.png";
import AboutDescription from "../components/primitives/aboutPage/aboutDescription";
import { Divider, Flex, Title, Text } from "@mantine/core";
import { withLanding } from "../hoc/withLanding";

const Certificates = () => {
  return (
    <LandingLayout>
      <AboutImage image={ServicesImage} headerText={"Services we offer"} />
      <AboutDescription>
        <Title
          order={2}
          ta="left"
          fz={"1.75em"}
          pt={"lg"}
          style={{
            letterSpacing: " -0.00625rem",
          }}
        >
          Helping Organisations to be exceptional
        </Title>
        <Flex>
          <Divider
            orientation="vertical"
            color={"orange"}
            h={{ base: 400, sm: 300 }}
            size={3}
            mr={{ base: "1.2em", sm: "3em" }}
          />
          <Flex direction={"column"} gap={"lg"}>
            <Text c={"dark.5"}>
              A robust and secure platform designed to streamline the
              authentication process for all your important documents. Whether
              it's academic certificates, legal papers, identification
              documents, or any crucial paperwork, we ensure accurate
            </Text>
            <Text c={"dark.5"}>
              <Text component="span" inherit fw={700}>
                Our vision
              </Text>{" "}
              validation and fraud prevention. Our solution stands out with its
              versatility, accommodating various document types and formats.
              Through an intuitive
            </Text>
            <Text c={"dark.5"}>
              <Text component="span" inherit fw={700}>
                Our mission
              </Text>{" "}
              dashboard, users gain access to verification status, ensuring
              transparency and confidence in the validation process. We
              prioritize accuracy and reliability, working to thwart any
            </Text>
          </Flex>
        </Flex>
      </AboutDescription>
      <Services />
    </LandingLayout>
  );
};

export default withLanding(Certificates);
