import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Anchor, Stack, Image, Group, Button } from "@mantine/core";
import AuthLayout from "../../layouts/auth";
import { CustomTextInput, CustomPasswordInput } from "../shared/inputs";
import UserInputIcon from "../../assets/svg/userInputIcon.svg";
import PasswordLockIcon from "../../assets/svg/passwordLockIcon.svg";
import { SignInSchema, SignInDto } from "../../../schema/auth/auth.";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useMutation } from "@tanstack/react-query";
import { withLanding } from "../../../hoc/withLanding";
import { getFirebaseAuth } from "../../../services/firebase";
import { toast } from "react-toastify";
import {
  PreAuthCheckApi,
  PreAuthCheckDto,
} from "../../../hooks/auth/auth.hook";
import { useCallback, useMemo } from "react";

function LogIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInDto>({
    resolver: yupResolver(SignInSchema),
  });

  const preAuthCheckMutation = useMutation({
    mutationFn: (values: PreAuthCheckDto) => PreAuthCheckApi(values),
  });

  const loginMutation = useMutation({
    mutationFn: (formvalues: SignInDto) =>
      signInWithEmailAndPassword(
        getFirebaseAuth,
        formvalues.email,
        formvalues.password
      ),
    onError: (error: never) => {
      function getErrorMessage(code: string) {
        switch (code) {
          case "auth/user-not-found":
            return "User not found";
          case "auth/invalid-credential":
            return "Invalid password";
          default:
            return "Error occurred";
        }
      }
      toast.error(getErrorMessage(error["code"]));
    },
  });

  const onSubmit: SubmitHandler<SignInDto> = useCallback(
    (data) => {
      preAuthCheckMutation.mutate(
        { email: data.email },
        {
          onSuccess: (res) => {
            const allowed =
              res.data.exists &&
              res.data.emailVerified &&
              !res.data.deactivated;
            if (res.data.exists === false) {
              toast.error("Found no account linked to the provided email.", {
                position: "bottom-center",
                autoClose: 8000,
              });
              return;
            }
            if (res.data.deactivated) {
              toast.error(
                "Unfortunately, login access has been disabled on this account. Please contact support.",
                {
                  position: "bottom-center",
                  autoClose: 8000,
                }
              );
              return;
            }
            if (res.data.emailVerified === false) {
              toast.error("Email not verified. Please verify your email.", {
                position: "bottom-center",
                autoClose: 8000,
              });
              return;
            }
            if (allowed) {
              loginMutation.mutate(data);
            }
          },
        }
      );
    },
    [loginMutation, preAuthCheckMutation]
  );

  const isSignInLoading = useMemo(() => {
    return loginMutation.isPending || preAuthCheckMutation.isPending;
  }, [loginMutation.isPending, preAuthCheckMutation.isPending]);

  return (
    <AuthLayout title="Log In">
      <Stack w={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <CustomTextInput
              mobileWhite
              iconLeft={<Image src={UserInputIcon} />}
              label={"Email"}
              placeholder={"Enter your email"}
              register={register("email")}
              error={errors.email?.message}
            />
            <CustomPasswordInput
              mobileWhite
              label={"Password"}
              iconLeft={<Image src={PasswordLockIcon} />}
              placeholder={"Enter your password"}
              register={register("password")}
              error={errors.password?.message}
            />
            <Button
              type="submit"
              fullWidth
              loading={isSignInLoading}
              size="md"
              radius={"md"}
              px={"2.5em"}
            >
              Login
            </Button>
          </Stack>
        </form>
        <Group justify={"space-between"}>
          <Text>
            <Anchor
              c={{ base: "white", sm: "white", md: "dark" }}
              href="/forgotpassword"
            >
              Forgot password?
            </Anchor>
          </Text>
          <Text fw={"800"}>
            <Anchor href="/signup">Sign-up</Anchor>
          </Text>
        </Group>
      </Stack>
    </AuthLayout>
  );
}

export default withLanding(LogIn);
