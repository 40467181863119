// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userMenu_user__onoBN {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  transition: background-color 100ms ease;

  &:hover {
    background-color: light-dark(
      var(--mantine-color-white),
      var(--mantine-color-dark-8)
    );
  }

  /* @media (max-width: 755px) {
    display: none;
  } */
}

.userMenu_userActive__50UeO {
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-8)
  );
}

.userMenu_block__6zelE {
  height: 60;
  min-width: "100%";
  border: 1px solid var(--mantine-color-light-grey-9);
  padding-top: var(--mantine-spacing-xs);
  padding-bottom: var(--mantine-spacing-xs);
  padding-inline: var(var(--mantine-color-dark-4));
  border-radius: "8px";
  cursor: pointer;
  &:hover {
    background-color: light-dark(
      var(--mantine-color-white),
      var(--mantine-color-dark-8)
    );
  }
}

.userMenu_iconContainer__2DGLb {
  display: flex;
  align-items: center;
  padding: "30px";
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-8)
  );
  border-radius: "50%";

  &:hover {
    background-color: light-dark(
      var(--mantine-color-white),
      var(--mantine-color-dark-8)
    );
  }
}
`, "",{"version":3,"sources":["webpack://./src/connected/dashboardHeader/userMenu/userMenu.module.css"],"names":[],"mappings":"AAAA;EACE,0EAA0E;EAC1E,4DAA4D;EAC5D,uCAAuC;EACvC,uCAAuC;;EAEvC;IACE;;;KAGC;EACH;;EAEA;;KAEG;AACL;;AAEA;EACE;;;GAGC;AACH;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,mDAAmD;EACnD,sCAAsC;EACtC,yCAAyC;EACzC,gDAAgD;EAChD,oBAAoB;EACpB,eAAe;EACf;IACE;;;KAGC;EACH;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf;;;GAGC;EACD,oBAAoB;;EAEpB;IACE;;;KAGC;EACH;AACF","sourcesContent":[".user {\n  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));\n  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);\n  border-radius: var(--mantine-radius-sm);\n  transition: background-color 100ms ease;\n\n  &:hover {\n    background-color: light-dark(\n      var(--mantine-color-white),\n      var(--mantine-color-dark-8)\n    );\n  }\n\n  /* @media (max-width: 755px) {\n    display: none;\n  } */\n}\n\n.userActive {\n  background-color: light-dark(\n    var(--mantine-color-white),\n    var(--mantine-color-dark-8)\n  );\n}\n\n.block {\n  height: 60;\n  min-width: \"100%\";\n  border: 1px solid var(--mantine-color-light-grey-9);\n  padding-top: var(--mantine-spacing-xs);\n  padding-bottom: var(--mantine-spacing-xs);\n  padding-inline: var(var(--mantine-color-dark-4));\n  border-radius: \"8px\";\n  cursor: pointer;\n  &:hover {\n    background-color: light-dark(\n      var(--mantine-color-white),\n      var(--mantine-color-dark-8)\n    );\n  }\n}\n\n.iconContainer {\n  display: flex;\n  align-items: center;\n  padding: \"30px\";\n  background-color: light-dark(\n    var(--mantine-color-white),\n    var(--mantine-color-dark-8)\n  );\n  border-radius: \"50%\";\n\n  &:hover {\n    background-color: light-dark(\n      var(--mantine-color-white),\n      var(--mantine-color-dark-8)\n    );\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user": `userMenu_user__onoBN`,
	"userActive": `userMenu_userActive__50UeO`,
	"block": `userMenu_block__6zelE`,
	"iconContainer": `userMenu_iconContainer__2DGLb`
};
export default ___CSS_LOADER_EXPORT___;
