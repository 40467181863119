import API from "../../api/api";
import { useQuery } from "@tanstack/react-query";

const url = "/request/document";

export const useGetDocumentRequests = (
  workspaceId: string,
  page: number,
  limit: number
) => {
  const getDocumentRequests = async () => {
    const response = await API.get(
      `${url}/?workspaceid=${workspaceId}&page=${page}&limit=${limit}`
    );
    return response;
  };
  const query = useQuery({
    queryFn: getDocumentRequests,
    queryKey: ["GetDocumentRequests", page, limit, workspaceId],
  });
  return query;
};
