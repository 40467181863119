import PropTypes from "prop-types";
import { Group, Text, Image, Stack } from "@mantine/core";

function InsightCard({
  card,
}: {
  card: { title: string; value: string; icon: string; iconBackground: string };
}) {
  return (
    <Group
      align="center"
      gap={"0.5em"}
      bg={"light-grey.4"}
      p={"lg"}
      style={{
        borderRadius: "16px",
        boxShadow: "2px 2px 16px 0px #00000014",
      }}
    >
      <div
        style={{
          backgroundColor: card.iconBackground,
          padding: "10px",
          borderRadius: "50%",
        }}
      >
        <Image src={card.icon} />
      </div>
      <Stack gap={0}>
        <Text fz={"12px"} c={"light-grey.12"}>
          {card.title}
        </Text>
        <Text fz={"24px"} c={"dark.4"} fw={700}>
          {card.value}
        </Text>
      </Stack>
    </Group>
  );
}

InsightCard.prototype = {
  card: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconBackground: PropTypes.string.isRequired,
  }).isRequired,
};

export default InsightCard;
