// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formlist_control__CPvFQ {
  width: 370px;

  &:hover {
    background-color: var(--mantine-color-light-grey-5);
  }
}

.formlist_item__s45Rd {
  margin-bottom: 1em;
  border: 1px solid var(--mantine-color-light-grey-5);
  margin-right: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/components/primitives/form/formListAccordion/formlist.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;;EAEZ;IACE,mDAAmD;EACrD;AACF;;AAEA;EACE,kBAAkB;EAClB,mDAAmD;EACnD,mBAAmB;AACrB","sourcesContent":[".control {\n  width: 370px;\n\n  &:hover {\n    background-color: var(--mantine-color-light-grey-5);\n  }\n}\n\n.item {\n  margin-bottom: 1em;\n  border: 1px solid var(--mantine-color-light-grey-5);\n  margin-right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `formlist_control__CPvFQ`,
	"item": `formlist_item__s45Rd`
};
export default ___CSS_LOADER_EXPORT___;
