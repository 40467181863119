import { useParams, useNavigate } from "react-router-dom";
import { Stack, Text, Group } from "@mantine/core";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CustomTextInput,
  CustomSelectInput,
  CustomCheckBox,
} from "../../../shared/inputs";
import OrangeButton from "../../../shared/buttons/orangeButton";
import AdminLayout from "../../../../layouts/adminLayout";
import {
  useGetServiceById,
  useUpdateServiceById,
} from "../../../../../hooks/admin/services.hook";
import {
  UpdateServiceSchema,
  UpdateServiceDTO,
} from "../../../../../schema/services/edit-service";
import { ServiceCategory, ServiceProvider } from "../../../../../enums/enums";
import FormLoader from "../../../shared/skeletonLoader/formLoader";

function ServiceEdit({ data }: any) {
  const { serviceId } = useParams();
  const { mutate: updateService, isPending: isUpdating } = useUpdateServiceById(
    serviceId!
  );
  const navigate = useNavigate();
  const initialFormData = {
    name: data?.data?.name,
    description: data?.data?.description,
    costAmount: data?.data?.costAmount,
    available: data?.data?.available,
    visible: data?.data?.visible,
    regexFormat: data?.data?.regexFormat,
    serviceCategory: data?.data?.serviceCategory,
    serviceCode: data?.data?.serviceCode,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateServiceDTO>({
    defaultValues: initialFormData,
    resolver: yupResolver(UpdateServiceSchema),
  });

  const serviceTypes = [
    { value: ServiceCategory.CERTIFICATE, label: "Certificate" },
    { value: ServiceCategory.DOCUMENT, label: "Document" },
  ];

  const providerTypes = [
    { value: ServiceProvider.CREDIT_BUREAU, label: "Credit Bureau" },
    { value: ServiceProvider.SMILE_IDENTITY, label: "Smile Identity" },
  ];

  const onSubmit: SubmitHandler<UpdateServiceDTO> = (data) => {
    updateService(data, {
      onSuccess: () => {
        navigate("/admin/services");
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        mb={"2em"}
        maw={"500px"}
        align="center"
        bg={"light-grey.4"}
        p={"2.5em"}
        style={{
          boxShadow: " 0px 4px 16px 0px #0000000D",
          border: "1.30px solid #E8E8E8",
          borderRadius: "2em",
        }}
      >
        <Text fw={600} mb={"1em"} fz={"24px"}>
          Edit Service
        </Text>
        <CustomTextInput
          label="Service Name"
          placeholder="Service Name"
          register={register("name")}
          error={errors.name?.message}
        />
        <CustomTextInput
          label="Description"
          placeholder="Description"
          register={register("description")}
          error={errors.description?.message}
        />
        <CustomTextInput
          label="Cost Amount"
          placeholder="Cost Amount"
          register={register("costAmount")}
          error={errors.costAmount?.message}
        />
        <CustomTextInput
          label="Regex Format"
          placeholder="Regex Format"
          register={register("regexFormat")}
          error={errors.regexFormat?.message}
        />
        <CustomSelectInput
          label="Service Category"
          data={serviceTypes}
          register={register("serviceCategory")}
          error={errors.serviceCategory?.message}
        />
        <CustomSelectInput
          label="Service Provider"
          data={providerTypes}
          register={register("serviceProvider")}
          error={errors.serviceProvider?.message}
        />
        {/* <CustomTextInput
          label="Service Code"
          placeholder="Service Code"
          register={register("serviceCode")}
          error={errors.serviceCode?.message}
        /> */}
        <Group grow justify="space-between">
          <CustomCheckBox
            label={
              <Text size="sm" fw={500}>
                Available
              </Text>
            }
            register={register("available")}
          />
          <CustomCheckBox
            label={
              <Text size="sm" fw={500}>
                Visible
              </Text>
            }
            register={register("visible")}
          />
        </Group>
        <OrangeButton
          loading={isUpdating}
          buttonText={"Update Service"}
          onClick={handleSubmit(onSubmit)}
        />
      </Stack>
    </form>
  );
}

function EditService() {
  const { serviceId } = useParams();
  const { data, isLoading } = useGetServiceById(serviceId!);
  return (
    <AdminLayout title="Edit Service">
      {!isLoading && <ServiceEdit data={data} />}
      {isLoading && <FormLoader title="Edit Service" />}
    </AdminLayout>
  );
}

export default EditService;
