import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomCheckBox } from "../../../shared/inputs";
import { useGetAllServicesByAdmin } from "../../../../../hooks/admin/services.hook";
import { ActitonButton } from "../users/users";
import AdminLayout from "../../../../layouts/adminLayout";
import CustomTable from "../../../shared/table/customTable";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
function AdminServices() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, isPending } = useGetAllServicesByAdmin(page, 10);
  const servicesData = data?.data?.data;
  const servicesCount = data?.data?.count;
  const servicesTotalPages = data?.data?.totalPages;
  const servicesCurrentPage = data?.data?.currentPage;

  const TableData = servicesData?.map((service: any, index: number) => {
    return {
      checbox: <CustomCheckBox />,
      name: service?.name,
      price: service?.costAmount,
      serviceCategory: service?.serviceCategory,
      availablility: service?.available ? "Available" : "Unavailable",
      visibility: service?.visible ? "Visible" : "Hidden",
      action: <ActitonButton onClick={() => handleEdit(service?.id)} />,
    };
  });

  const handleEdit = (id: string) => {
    navigate(`/admin/services/${id}`);
  };

  return (
    <AdminLayout title="Services">
      <CustomTable
        isTableLoading={isPending}
        currentPage={servicesCurrentPage}
        totalPages={servicesTotalPages}
        setPage={setPage}
        count={servicesCount}
        title={"Services"}
        bodyRows={TableData}
        labels={[
          <CustomCheckBox />,
          "Service Name",
          "Price",
          "Service Category",
          "Availability",
          "Visibility",
          "Action",
          "",
        ]}
        minWidth={"850px"}
        emptyState={{
          title: "No Services Found",
          description: "They are currently no services available on Kpali",
          height: "100px",
          width: "175px",
          image: GhostIcon,
        }}
      />
    </AdminLayout>
  );
}

export default AdminServices;
