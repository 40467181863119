import { IconProps } from "./overviewIcon";
export const ServicesIcon = ({ className, width, height, fill }: IconProps) => (
  <svg
    width={width || "19"}
    height={height || "19"}
    className={className}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="arcticons:services">
      <path
        id="Vector"
        d="M3.7122 5.24797C4.56019 5.24797 5.24763 4.56053 5.24763 3.71253C5.24763 2.86453 4.56019 2.17709 3.7122 2.17709C2.8642 2.17709 2.17676 2.86453 2.17676 3.71253C2.17676 4.56053 2.8642 5.24797 3.7122 5.24797Z"
        stroke={fill || "#797979"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M3.7122 16.8229C4.56019 16.8229 5.24763 16.1355 5.24763 15.2875C5.24763 14.4395 4.56019 13.752 3.7122 13.752C2.8642 13.752 2.17676 14.4395 2.17676 15.2875C2.17676 16.1355 2.8642 16.8229 3.7122 16.8229Z"
        stroke={fill || "#797979"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M3.7122 11.0354C4.56019 11.0354 5.24763 10.348 5.24763 9.50001C5.24763 8.65201 4.56019 7.96457 3.7122 7.96457C2.8642 7.96457 2.17676 8.65201 2.17676 9.50001C2.17676 10.348 2.8642 11.0354 3.7122 11.0354Z"
        stroke={fill || "#797979"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M16.114 2.17709H7.60991C7.21859 2.17709 6.90137 2.49432 6.90137 2.88564V4.53943C6.90137 4.93074 7.21859 5.24797 7.60991 5.24797H16.114C16.5053 5.24797 16.8225 4.93074 16.8225 4.53943V2.88564C16.8225 2.49432 16.5053 2.17709 16.114 2.17709Z"
        stroke={fill || "#797979"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_5"
        d="M16.114 13.752H7.60991C7.21859 13.752 6.90137 14.0693 6.90137 14.4606V16.1144C6.90137 16.5057 7.21859 16.8229 7.60991 16.8229H16.114C16.5053 16.8229 16.8225 16.5057 16.8225 16.1144V14.4606C16.8225 14.0693 16.5053 13.752 16.114 13.752Z"
        stroke={fill || "#797979"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_6"
        d="M16.114 7.96457H7.60991C7.21859 7.96457 6.90137 8.28179 6.90137 8.67311V10.3269C6.90137 10.7182 7.21859 11.0354 7.60991 11.0354H16.114C16.5053 11.0354 16.8225 10.7182 16.8225 10.3269V8.67311C16.8225 8.28179 16.5053 7.96457 16.114 7.96457Z"
        stroke={fill || "#797979"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
ServicesIcon.displayName = "ServicesIcon";
