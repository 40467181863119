import {
  Flex,
  BackgroundImage,
  Container,
  Title,
  Divider,
} from "@mantine/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import AuthImage from "../assets/images/authImage.png";
import { KpaliLogo } from "../assets/icons";
import useResponsive from "../../hooks/useResponsive";

function AuthLayout({
  title,
  children,
}: PropTypes.InferProps<typeof AuthLayout.propTypes>) {
  const { isLaptop } = useResponsive();
  return (
    <Flex>
      <BackgroundImage
        h={"100vh"}
        w={{ base: "0%", sm: "0%", md: "60%" }}
        src={AuthImage}
      >
        <Container size={"lg"} w={"100%"}>
          <Flex
            w={"100%"}
            pl={"10em"}
            pr={"10em"}
            justify={"flex-start"}
            align={"center"}
            mt={"17em"}
          >
            <Flex
              direction={"column"}
              gap={"md"}
              align={{ base: "center", sm: "flex-start" }}
              w={"max-content"}
            >
              <Title
                fw={{ base: 800, md: 900 }}
                fz={{ base: "2em", sm: "3em" }}
                c="white"
              >
                {title}
              </Title>
              <Divider
                color={"orange"}
                w={{ base: "0", sm: "0", md: "10em" }}
                size={4}
              />
            </Flex>
          </Flex>
        </Container>
      </BackgroundImage>
      {!isLaptop && (
        <Container size={"lg"} w={{ base: "0%", sm: "0%", md: "40%" }}>
          <Flex justify={"center"} align={"center"} w={"100%"} mt={"15em"}>
            <Flex
              direction={"column"}
              gap={"md"}
              align={"center"}
              mr={{ base: "10%", sm: "15%", md: "20%" }}
              ml={{ base: "10%", sm: "15%", md: "20%" }}
              w={{ base: "100%", sm: "100%", md: "100%" }}
            >
              <div
                style={{
                  marginBottom: "1em",
                }}
              >
                <Link to={"/"}>
                  <KpaliLogo />
                </Link>
              </div>
              {children}
            </Flex>
          </Flex>
        </Container>
      )}
      {isLaptop && (
        <BackgroundImage
          h={"100vh"}
          w={{ base: "100%", sm: "100%", md: "40%" }}
          src={AuthImage}
        >
          <Flex
            justify={"center"}
            align={"center"}
            w={"100%"}
            mt={{ base: "10em", sm: "12em", md: "15em" }}
          >
            <Flex
              direction={"column"}
              gap={"md"}
              // bg={"white"}
              align={"center"}
              mr={{ base: "10%", sm: "15%", md: "20%" }}
              ml={{ base: "10%", sm: "15%", md: "20%" }}
              w={{ base: "100%", sm: "100%", md: "100%" }}
            >
              <div
                style={{
                  marginBottom: "1em",
                }}
              >
                <Link to={"/"}>
                  <KpaliLogo />
                </Link>
              </div>
              {children}
            </Flex>
          </Flex>
        </BackgroundImage>
      )}
    </Flex>
  );
}

AuthLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
