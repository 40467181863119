import "chart.js/auto";
import { useEffect, useRef } from "react";
import propTypes from "prop-types";
import { Chart } from "react-chartjs-2";
import DoughtnutCircleChartLoader from "../../../../shared/skeletonLoader/doughnutLoader";
import { randomColor } from "../../../../../../constants/colors";

function DoughnutChart({
  count,
  title,
  isLoading,
  labels,
  data,
  callback,
}: propTypes.InferProps<typeof DoughnutChart.propTypes>) {
  const defaultBackgroundColor = ["#4E5D67", "#EB862A", "#287F71", "#ABBDD3"];

  if (labels.length > defaultBackgroundColor.length) {
    for (let i = defaultBackgroundColor.length; i < labels.length; i++) {
      defaultBackgroundColor.push(randomColor());
    }
  }

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "520px",
          maxHeight: "400px",
        }}
      >
        {!isLoading && (
          <Chart
            type={"doughnut"}
            data={{
              labels,
              datasets: [{ data, backgroundColor: defaultBackgroundColor }],
            }}
            options={{
              cutout: "80%",
              plugins: {
                tooltip: {
                  callbacks: {
                    label: callback,
                  },
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        )}
        {isLoading && <DoughtnutCircleChartLoader />}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "1.5rem",
              fontWeight: 600,
              margin: "0",
              padding: "0",
            }}
          >
            {count}
          </p>
          <p
            style={{
              color: "#667085",
              fontSize: "1rem",
              margin: "0",
              padding: "0",
            }}
          >
            {title}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DoughnutChart;

DoughnutChart.propTypes = {
  count: propTypes.number,
  title: propTypes.string,
  isLoading: propTypes.bool,
  labels: propTypes.array as propTypes.Validator<string[]>,
  data: propTypes.array,
  callback: propTypes.func.isRequired,
};
