import { useState } from "react";
import { Tooltip } from "@mantine/core";
import CustomTable from "../../../shared/table/customTable";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
import { useGetCertificateRequests } from "../../../../../hooks/certificates/user.certificates.hook";
import { formatDate } from "../../../shared/reusableFunctions";
import { ActitonButton } from "../../admin/users/users";
import { CustomCheckBox } from "../../../shared/inputs";
import RequestStatusText from "../../../shared/requestStatus";
import { useNavigate } from "react-router-dom";

function CertificatesTable({ workspaceId }: { workspaceId: string }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, isPending } = useGetCertificateRequests(workspaceId, page, 5);
  const {
    count: certificateCount,
    currentPage: certificateCurrentPage,
    totalPages: certificateTotalPages,
    data: certificates,
  } = data?.data || {};
  const labels = [
    <CustomCheckBox />,
    "requester",
    "certificate type",
    "date",
    "status",
    "action",
  ];

  const handleNavigate = (id: string) => {
    navigate(`/requested/${id}`);
  };

  const TableData =
    certificates?.map((certificate: any) => {
      const requesterName = certificate?.requester?.businessName
        ? certificate?.requester?.userId?.businessName
        : certificate?.requester?.userId?.firstName +
          " " +
          certificate?.requester?.userId?.lastName;
      const requesterEmail = certificate?.requester?.userId?.email;
      return {
        checkbox: <CustomCheckBox />,
        name: (
          <Tooltip label={requesterEmail}>
            <span>{requesterName}</span>
          </Tooltip>
        ),
        certificateType: certificate?.certificates[0]?.certificateType,
        date: formatDate(certificate?.createdAt),
        status: <RequestStatusText status={certificate?.status} />,
        action: (
          <ActitonButton onClick={() => handleNavigate(certificate?.id)} />
        ),
      };
    }) || [];
  return (
    <CustomTable
      isTableLoading={isPending}
      tableLoadingCount={5}
      count={certificateCount}
      totalPages={certificateTotalPages}
      currentPage={certificateCurrentPage}
      setPage={setPage}
      title="Certificate"
      labels={labels}
      minWidth={"850px"}
      bodyRows={TableData}
      emptyState={{
        title: "No Certificate Request Found",
        description:
          "To get started on your verification process, click the button below",
        image: GhostIcon,
        buttonText: "Make Certificate Request",
        onclick: () => navigate("/service/certificate"),
      }}
    />
  );
}

export default CertificatesTable;
