import Skeleton from "react-loading-skeleton";
import useResponsive from "../../../../hooks/useResponsive";
import { Stack } from "@mantine/core";

const ServicesLoader = () => {
  const { isMobile, isTablet } = useResponsive();
  return (
    <Stack gap={0}>
      {Array.from({ length: 5 }).map((_, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            width: "100%",
            padding: "0.5em",
            maxWidth: isMobile ? "100%" : isTablet ? "375px" : "400px",
          }}
        >
          <div style={{ width: "30px", maxWidth: "60px", borderRadius: "50%" }}>
            <Skeleton circle height={30} />
          </div>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <Stack gap={"0.05em"}>
              <Skeleton height={35} />
              {/* <Skeleton height={15} /> */}
            </Stack>
          </div>
        </div>
      ))}
    </Stack>
  );
};

export default ServicesLoader;
