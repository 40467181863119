import { Box, Text, Stack } from "@mantine/core";
const DataPrivacy = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <Box pr={isMobile ? 0 : 80}>
      <Text
        mb={24}
        style={{
          fontWeight: 700,
          fontSize: isMobile ? "24px" : "36px",
          lineHeight: isMobile ? "32px" : "44px",
        }}
      >
        Data Protection & Privacy Policy
      </Text>
      <Stack gap={16}>
        <Box>
          <Text c="#364152" ta="justify">
            Kpali.ng, a subsidiary of Rentcheck Referencing Company Limited, a
            limited liability company, registered under the laws of the Federal
            Republic of Nigeria (whose website and brand name is Kpali.ng),
            understands the importance of data protection and privacy.
            <br />
            <br />
            We are committed to ensuring that the personal information of our
            clients, vendors and customers is collected, used, and disclosed in
            accordance with all applicable laws and regulations of the Federal
            Republic of Nigeria, the Nigerian Data protection Regulation 2019,
            including the global best practices referenced on Data Protection
            and privacy Regulations. This policy sets out our approach to data
            protection and privacy, and explains how we collect, use, and
            disclose personal information.
            <br />
            <br />
            Kpali.ng is committed to treating information of employees,
            customers, and other stakeholders with the utmost care and
            confidentiality.
            <br />
            <br />
            In line with the company’s commitment and compliance with the NDPR
            2019, this Data Protection Policy has been developed to ensure that
            the company limited through all channels of services, including
            their platforms Kpali.ng, manages data ethically, protect data
            subject, ensure compliance to regulatory authorities, maintains
            confidentiality, integrity of data.
            <br />
            <br />
            Additionally, ensures that data protection and privacy procedures,
            systems, controls and measures appropriate to the company’s risk
            profile have been implemented.
            <br />
            <br />
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            Purpose of the Policy:
          </Text>
          <Text c="#364152" ta={"justify"}>
            The purpose of this policy is:
            <br />
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                To bring to the attention of all staff the need to improve and
                maintain security of information systems, and to advise managers
                of the approach being adopted to achieve the appropriate level
                of security.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                To bring to the attention of all managers and staff, their
                responsibilities under the requirements of relevant legislation,
                including Data Protection and Human Rights legislation and
                guidance, and the importance of ensuring the confidentiality of
                personal and sensitive data.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                To ensure that there is compliance with current legislation and
                FG Directives, meets its statutory obligations and observes
                standards of good practice. To minimize the risk of security
                breach and prosecution.
              </li>
            </ul>
            This policy is compiled with the aim to accomplish the following:
            <br />
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Protect Kpali.ng, a subsidiary of Rentcheck Referencing Company
                Limited from the risks of a data breach;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Transparently inform the public on how Kpali.ng processes
                individuals’ Personal Data;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Protect the rights of employees, members and stakeholders, data,
                data subject ; and
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Comply with the NDPR 2019.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Personal data shall be processed for specified, explicit and
                legitimate purpose
              </li>
            </ul>
          </Text>
        </Box>

        <Box>
          <Text fw={600} ta="justify" mb={8}>
            Roles and Responsibility
          </Text>
          <Text c="#364152">
            Kpali.ng has designated key stakeholders identified to drive the
            adherence and implementation of the Policy and necessary data
            protection controls or protocols.
          </Text>
        </Box>
        <Box>
          <Text fw={600} ta="justify" mb={8}>
            Head of compliance and Legal services
          </Text>
          <Text c="#364152" ta="justify">
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Responsible for the development and implementation of the Data
                Protection & Privacy Policy
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Audit and provide routine updates on the Data protection and
                Privacy Policy in Line with NDPR and the global Standards.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Inform and advise Management and employees about their
                obligations in line with the NDPR and international privacy
                standards
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Handle data privacy and subject access requests from Data
                Subject
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Prepare data protection & privacy training plan for employees
                and management and subsequently conduct and arrange internal &
                external trainings respectively
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Advise on Data Protection Impact Assessment
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Ensure that actual or suspected data breaches are duly
                identified and appropriately contained to mitigate the risk of
                future occurrences
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Monitor company-wide compliance with the NDPR
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Act as the main point of contact for NITDA
              </li>
            </ul>
          </Text>
        </Box>
        <Box>
          <Text fw={600} ta="justify" mb={8}>
            Board
          </Text>
          <Text c="#364152" ta="justify">
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Ensure that data protection objectives are established and are
                aligned with the strategic direction of the company
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Ensure that the resources needed for the protection of Personal
                Data are available
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Ensure that the company conforms with the NDPR and best data
                protection standards
              </li>
            </ul>
          </Text>
        </Box>
        <Box>
          <Text fw={600} ta="justify" mb={8}>
            Management:
          </Text>
          <Text c="#364152" ta="justify">
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Ensure that data protection objectives are established and are
                aligned with the strategic direction of the company
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Ensure that the resources needed for the protection of Personal
                Data are available
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Ensure that the company conforms with the NDPR and best data
                protection standards
              </li>
            </ul>
          </Text>
        </Box>
        <Box>
          <Text fw={600} ta="justify" mb={8}>
            Employees
          </Text>
          <Text c="#364152" ta="justify">
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Fully comply with the policy
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Adhere to data security procedures put in place by the company
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Report any data breach to the head of Compliance within 1 hour
                of being aware of it
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Direct all breach protocols to the compliance officer
              </li>
            </ul>
          </Text>
        </Box>
        <Box>
          <Text fw={600} ta="justify" mb={8}>
            Data Protection and Governing Principles
          </Text>
          <Text c="#364152" ta="justify">
            Kpali.ng, shall ensure that such data obtained from data subjects
            are used in accordance with the data protection regulation in
            Nigeria. Personal data shall be handled with the greatest care and
            used only for legitimate and specified business purposes.
            <br />
            <br />
            Kpali.ng, shall be guided by the following principles when handling
            personal data:
          </Text>
        </Box>
        <Box>
          <Text fw={600} ta="justify" mb={8}>
            Obtaining Consent
          </Text>
          <Text c="#364152" ta="justify">
            The <strong> NDPR </strong> mandates that consent provided by Data
            Subjects must be:
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Head of compliance must ensure that Consent must be obtained
                without fraud, coercion or undue influence.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                For a specific and unambiguous purpose
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                In accordance with supporting information provided to the data
                subject so that they can make an informed decision about whether
                to provide their consent for the particular processing;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Confirmed by a positive action, thus an act of silence or
                inactivity do not apply
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Able to be withdrawn at any time, in a manner not complex than
                it was originally provided
              </li>
            </ul>
          </Text>
        </Box>
        <Box>
          <Text fw={600} ta="justify" mb={8}>
            To effectively manage consent, Kpali.ng shall:
          </Text>
          <Text c="#364152" ta="justify">
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Ensure that consent request is prominent, concise, easy to
                understand and separate from other terms and conditions
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Ensure that all consent obtained is voluntary, specific and
                informed. The specific purpose of personal data collection will
                be made known to Data Subjects
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Operate an established and effective means of obtaining and
                managing the specific consent of Data Subjects (e.g. the
                addition of consent clause on all its data collection mediums)
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Obtain the consent of Data Subjects only in respect of one or
                more specific data processing activities and ensure that given
                consent is not applied to other activities for which the Data
                Subject’s approval has not been provided.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Retain records to support the granting of consent by Data
                Subjects.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Communicate to Data Subjects their right to amend or withdraw
                their consent at any time, the process for doing so, including
                any reference to statutory or legal obligations that may still
                apply even after the Data Subject has withdrawn their consent.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Act promptly to identify all personal data for which consent for
                processing has been withdrawn and unless another valid reason
                for its processing is communicated to the Data Subject, ensure
                that this is no longer processed for the purpose for which
                consent had been withdrawn.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Explain to Data Subjects that the withdrawal of consent does not
                affect the processing of personal data which had already taken
                place before the withdrawal.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Retain records to support the withdrawal of consent by Data
                Subjects.
              </li>
            </ul>
          </Text>
        </Box>
        <Box>
          <Text fw={600} ta="justify" mb={8}>
            Lawful Data Processing:
          </Text>
          <Text c="#364152" ta="justify">
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Kpali.ng shall only process adequate data for relevant purposes
                and in a limited capacity.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Data Subject must give consent to the processing of his or her
                Personal Data for one or more specific purposes.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Where processing is based on consent, the Compliance officer
                shall be able to demonstrate that the Data Subject has consented
                to processing of his or her Personal Data and the legal capacity
                to give consent;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Data processing is necessary for the performance of a contract
                to which the Data Subject is party or in order to take steps at
                the request of the Data Subject prior to entering into a
                contract.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Data processing is necessary for compliance with a legal
                obligation to which the Controller is subject.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Data processing is necessary in order to protect the vital
                interests of the Data Subject or of another natural person.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Data processing is necessary for the performance of a task
                carried out in the public interest or in exercise of official
                public mandate vested in the controller
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                <strong> Data Accuracy: </strong> Kpali.ng shall maintain
                accurate data that is incessantly kept up to date. Similarly,
                inaccurate personal data shall be either erased or promptly
                rectified. Kpali shall be responsible for omissions, negligence
                or errors provided that such omissions, negligence or errors are
                not as a result of misrepresentation by the data subject.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                <strong> Data storage: </strong> Kpali.ng shall keep personal
                data of Data Subjects in a form that permits identification of
                Data Subjects for no longer than is necessary or a period of 2
                years provided that all data above 2000 is submitted to the
                authorities before March 1 of the following year. Data must be
                kept for the purposes in which the personal data is being
                processed.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                <strong> Data Integrity and Confidentiality: </strong>
                Kpali.ng shall protect personal data by implementing appropriate
                technical and organizational measures in order to safeguard
                personal data in its custody.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                <strong> Data Accountability: </strong>
                Kpali.ng holds itself accountable to demonstrate compliance with
                applicable legal and regulatory requirements and understand its
                roles and responsibilities for efficient data protection. At
                Kpali.ng, we take full responsibility and account for every data
                processed via our platforms. In order to ensure the safety of
                personal data of Data Subjects, Kpali.ng, has implemented the
                following information security measures:
              </li>
            </ul>
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            Network Access Control
          </Text>
          <Text c="#364152" ta="justify">
            To prevent unauthorized access that may lead to data breach through
            the company’s network, only devices on Kpali.ng access control lists
            have the permission to utilize our networks. We make use of
            authentication and authorization mechanisms that ensure unauthorized
            users are not granted access to our platform or cloud resources.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            Passwords and Access Control
          </Text>
          <Text c="#364152" ta="justify">
            Each individual is responsible for keeping their own password
            secure, and must ensure it is neither disclosed to nor used by
            anyone else, under any circumstances. Staff must only access systems
            using their own login and password. All staff are accountable for
            any activity carried out under their login and password, and this is
            audited.
            <br />
            <br />
            Access is controlled on the basis of service requirements.
            Procedures are in place for allocating and controlling access, and
            passwords.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            Hierarchy Access:
          </Text>
          <Text c="#364152" ta="justify">
            Access is categorized into super admins, admins and No access to
            classified networks. Authorized personnels are allowed access with
            assigned username and passwords. The Office has designated the
            following authorized personnel:
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "10px",
                marginBottom: "8px",
                textAlign: "justify",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Technology Advisor
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Head of Compliance
              </li>
            </ul>
            They are responsible for implementing, monitoring, documenting and
            communicating information security policies in the Office.
            <br />
            <br />
            Information security must be addressed at recruitment stage for all
            staff, and a confidentiality clause included in contracts of
            employment.
            <br />
            <br />
            There is a formal procedure for reporting, investigating and
            recording Information security incidents. Any incidents should be
            reported to the ICT Services Helpdesk immediately
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            Risk Analysis
          </Text>
          <Text c="#364152" ta="justify">
            In order to make the best use of resources, it is important to
            ensure that each Information system is secured to a level
            appropriate to the measure of risk associated with it. A risk
            assessment should be carried out for each of the Office information
            systems and measures put in place to ensure each system is secured
            to an appropriate level.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            Intrusion Prevention System
          </Text>
          <Text c="#364152" ta="justify">
            In order to protect personal data and sensitive information,
            Kpali.ng has implemented an Intrusion Prevention System in the form
            of a firewall solution. The firewall solution protects the company’s
            network and connected systems from malicious attacks and hacking
            from cybercriminals by filtering and blocking unwanted data packets
            from accessing its computer network. We make use of Google Cloud’s
            Web Security Scanner to discover vulnerabilities within our
            applications.
            <br />
            <br />
            The google cloud web security scanner has a pre-emptive approach to
            network security as it is able to identify potential threats and
            respond to them swiftly
            <br />
            <br />
            When a threat is detected, it deploys a lateral movement protection
            defense response, which isolates the threat from spreading, system
            from communicating with other systems or back to the host
          </Text>
        </Box>
        <Box>
          <Text fw={600}>Endpoint Security System</Text>

          <Text c="#364152" mb={8}>
            Our applications are deployed on Google Cloud infrastructure and we
            leverage Google Cloud Endpoints as our API Gateway. This comes with
            out-of-the box configurations for API protection and monitoring. The
            platform comes with an endpoint protection system that combines
            antimalware, data loss prevention (DLP), a client firewall,
            application and device control as well as a host-based intrusion
            prevention system. This also offers website browsing protection and
            filtering, email protection (such as anti-spam) and patch
            assessment. All network management controls and procedures will
            conform to the NIMC Security Policy code of connection and
            associated guidance.
          </Text>
        </Box>
        <Box>
          <Text c="#364152" mb={8}>
            <strong> Network Management </strong> is the responsibility of the
            ICT Services Directorate; all devices connected to Kpali.ng’s
            Network must meet the required standards. Failure to do so will
            result in immediate disconnection. No direct connection is permitted
            between our LAN, other LAN or Internet services accessed via public
            service providers.
            <br />
            <br />
            The endpoint protection system offers protection from zero-day
            attacks and drive-by downloads to minimize damage from breaches.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            Data Backup
          </Text>
          <Text c="#364152">
            We have automated backup configured on our database (hosted on
            Google Cloud SQL) every 24 hours with a 7-days retention period.
            Backups at Kpali.ng are done daily. Backups are encrypted with
            industry standard solutions and backed up data can only be accessed
            by authorized personnel for control purposes.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            Third Party Disclosure
          </Text>
          <Text fw={600}>Requesting Party:</Text>
          <Text c="#364152">
            The above disclosures to a requesting party shall be made only to
            the extent necessary for the specific purpose for which the data is
            furnished provided that a requesting party has obtained consent of
            the data subject and the data subject has furnished them with the
            data and consent to verify alongside.
          </Text>
          <Text fw={600}>Requesting Party:</Text>
          <Text c="#364152">
            The above disclosures to a requesting party shall be made only to
            the extent necessary for the specific purpose for which the data is
            furnished provided that a requesting party has obtained consent of
            the data subject and the data subject has furnished them with the
            data and consent to verify alongside.
          </Text>
        </Box>
        <Box>
          <Text fw={600}>Employees:</Text>
          <Text c="#364152">
            Employees shall only have access to, and process personal data based
            on a “need to verify” basis in order to perform their job.
            <br />
            <br />
            All employees are enjoined to ensure that they do not indulge in
            activities that can result in the compromise or breach of data. In
            addition, it is the responsibility of everyone to adhere to the
            dictates of this policy.
            <br />
            <br />
            Failure to comply with this policy, whether intentional, will lead
            to disciplinary action (up to and including dismissal).
            <br />
            <br />
            Employees of Kpali.ng are the most important element of its
            adherence to the protection of Data Subjects’ personal data as they
            are involved in every step of the data lifecycle, including
            collecting personal data, processing it in compliance with laws and
            regulations, employing safeguards, and establishing the means and
            schedules of retention and deletion. It is therefore imperative that
            employees understand their role and be committed to safeguarding
            personal data.
            <br />
            <br />
            Kpali.ng will ensure that a data protection training program focused
            on emerging trends is developed annually. More importantly, data
            protection training will be conducted for employees and directors at
            least annually; and regular data protection and information security
            awareness campaigns will be run. Additionally, other knowledge
            resources on data protection and privacy, including guidance on ways
            the company can better process and safeguard personal data will be
            constantly shared.
            <br />
            <br />
            It is important that employees understand the significance of
            protecting personal data and respecting privacy rights, with the
            ability to relate this back to the risks and consequences from an
            individual perspective.
            <br />
            <br />
            Kpali.ng remains committed to its goal of ensuring employees and
            other stakeholders understand their respective roles and
            responsibilities for compliance with the NDPR. In line with the
            provisions of the <strong> NDPR </strong>, the company shall conduct
            a detailed annual internal audit of its data protection and privacy
            practices with at least each audit stating:
            <br />
            <br />
            <ul
              style={{
                color: "#364152",
                // listStylePosition: "inside",
                marginLeft: "-20px",
                marginBottom: "8px",
                textAlign: "justify",
                listStyleType: "'-   '",
              }}
            >
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Personally identifiable information the company collects on
                employees and members of the public;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Any purpose for which the personally identifiable information is
                collected.
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Any notice given to individuals regarding the collection and use
                of personal information relating to that individual;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Any access given to individuals to review, amend, correct,
                supplement, or delete personal information relating to that
                individual;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                Whether or not consent is obtained from an individual before
                personally identifiable information is collected, used,
                transferred, or disclosed and any method used to obtain consent;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                The company’s policies and practices for the proper use of
                personally identifiable information;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                The company’s policies and procedures for privacy and data
                protection;
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                The company’s policies and procedures for monitoring and
                reporting violations of privacy and data protection policies;
                and
              </li>
              <li
                style={{
                  marginBottom: "12px",
                }}
              >
                The company’s policies and procedures for assessing the impact
                of technologies on the stated privacy and security policies.
              </li>
            </ul>
            Similarly, where the company processes the personnel of more than
            2,000 data subjects annually, it shall conduct a Data Protection
            Audit through a licensed DPCO and submit the report to NITDA no
            later than 15th of March of every year.
          </Text>
        </Box>
        <Box>
          <Text fw={600}>Legal Requirements and Regulations</Text>
          <Text c="#364152" ta="justify">
            The office and all its staff are governed by laws & regulations.
            Unauthorized or unlicensed software is not permitted on Kpali.ng
            equipment. It is expressly forbidden for any user to load or operate
            software gained from the Internet, magazine gifts or other sources
            unless authorized by ICT Services. Our company is known and
            incorporated as Kpali.ng, a subsidiary of Rentcheck Referencing
            Company Limited Our website www. Kpali. ng is our registered
            trademark exclusive to Kpali Nigeria by Rentcheck Limited. You can
            contact us by sending an email to{" "}
            <a
              href="mailto:info@Kpali.ng"
              style={{
                textDecoration: "none",
              }}
            >
              {" "}
              info@Kpali.ng{" "}
            </a>
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default DataPrivacy;
