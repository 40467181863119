import CustomTextInput from "./textInput";
import CustomSelectInput from "./selectInput";
import CustomCheckBox from "./checkInput";
import CustomPhoneInput from "./phoneInput";
import CustomSearchInput from "./searchInput";
import CustomPasswordInput from "./passwordInput";
import CustomFileInput from "./fileInput";

export {
  CustomTextInput,
  CustomSelectInput,
  CustomCheckBox,
  CustomPhoneInput,
  CustomSearchInput,
  CustomPasswordInput,
  CustomFileInput,
};
