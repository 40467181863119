export const WorkspaceType = {
  PERSONAL: "PERSONAL",
  CORPORATE: "CORPORATE",
};

export type IWorkspaceType = (typeof WorkspaceType)[keyof typeof WorkspaceType];

export const WorkspaceRole = {
  OWNER: "OWNER",
  ADMIN: "ADMIN",
  VIEWER: "VIEWER",
  REQUESTER: "REQUESTER",
};

export type IWorkspaceRole = (typeof WorkspaceRole)[keyof typeof WorkspaceRole];

export const WorkspacePermission = {};

export const ServiceCategory = {
  CERTIFICATE: "CERTIFICATE",
  DOCUMENT: "DOCUMENT",
};

export type IServiceCategory =
  (typeof ServiceCategory)[keyof typeof ServiceCategory];

export const ServiceProvider = {
  SMILE_IDENTITY: "SMILE_IDENTITY",
  CREDIT_BUREAU: "CREDIT_BUREAU",
};

export type IServiceProvider =
  (typeof ServiceProvider)[keyof typeof ServiceProvider];

export const CertificateType = {
  UNIVERSITY: "UNIVERSITY",
  WAEC: "WAEC",
  NECO: "NECO",
  OTHERS: "OTHERS_CERTIFICATE",
  PROFESSIONAL: "PROFESSIONAL",
  NYSC: "NYSC",
  BIODATA: "BIODATA",
};

export type ICertificateType =
  (typeof CertificateType)[keyof typeof CertificateType];

export const BiodataType = {
  DOB: "DOB",
  MARRIAGE: "MARRIAGE",
  DEATH: "DEATH",
  OTHERS: "OTHERS",
};

export type IBiodataType = (typeof BiodataType)[keyof typeof BiodataType];

export const ServiceCodes = {
  NECO: "NECO",
  PROFESSIONAL: "PROFESSIONAL",
  DRIVERS_LICENSE: "DRIVERS_LICENSE",
  NIN: "NIN",
  UNIVERSITY: "UNIVERSITY",
  VOTERS_CARD: "VOTERS_CARD",
  BIODATA: "BIODATA",
  OTHERS_CERTIFICATE: "OTHERS_CERTIFICATE",
  OTHERS_DOCUMENT: "OTHERS_DOCUMENT",
  INTERNATIONAL_PASSPORT: "INTERNATIONAL_PASSPORT",
  NYSC: "NYSC",
  WAEC: "WAEC",
};

export type IServiceCodes = (typeof ServiceCodes)[keyof typeof ServiceCodes];

export const AppRoles = {
  ADMIN: "ADMIN",
  USER: "USER",
  AGENT: "AGENT",
};

export type IAppRoles = (typeof AppRoles)[keyof typeof AppRoles];

export const RequestStatus = {
  PENDING: "PENDING",
  ONGOING_VERIFICATION: "ONGOING_VERIFICATION",
  CANCELLED: "CANCELLED",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  AWAITING_ASSIGNMENT: "AWAITING_ASSIGNMENT",
};

export type IRequestStatus = (typeof RequestStatus)[keyof typeof RequestStatus];

export const DocumentVerificationStatus = {
  INPROGRESS: "INPROGRESS",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  VERIFIED: "VERIFIED",
  UNVERIFIED: "UNVERIFIED",
};

export type IDocumentVerificationStatus =
  (typeof DocumentVerificationStatus)[keyof typeof DocumentVerificationStatus];

export const CertificateVerificationStatus = {
  INPROGRESS: "INPROGRESS",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  VERIFIED: "VERIFIED",
  UNVERIFIED: "UNVERIFIED",
};

export type ICertificateVerificationStatus =
  (typeof CertificateVerificationStatus)[keyof typeof CertificateVerificationStatus];

export const PaymentStatus = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  PENDING: "PENDING",
  REFUNDED: "REFUNDED",
  UNVERIFIED: "UNVERIFIED",
};

export type IPaymentStatus = (typeof PaymentStatus)[keyof typeof PaymentStatus];
