import { Stack, NativeSelect, Image } from "@mantine/core";
import PropTypes from "prop-types";
import DropDownIcon from "../../../assets/svg/dropDownIcon.svg";
import styles from "./inputs.module.css";

function CustomSelectInput({
  width,
  label,
  data,
  radius,
  size,
  iconRight,
  mobileWhite,
  register,
  value,
  onChange,
  error,
  notRequired,
}: PropTypes.InferProps<typeof CustomSelectInput.propTypes>) {
  return (
    <Stack w={width ? width : "100%"} mb={"0.1em"}>
      <NativeSelect
        label={label ? label : "Account Type"}
        c={
          mobileWhite ? { base: "white", sm: "white", md: "dark.4" } : "dark.4"
        }
        rightSection={iconRight ? iconRight : <Image src={DropDownIcon} />}
        size={size ? size : "md"}
        {...value}
        required={notRequired ? false : true}
        radius={radius ? radius : "md"}
        error={error ? error : null}
        data={data}
        classNames={{
          input: styles.input,
          wrapper: styles.wrapper,
          label: styles.label,
        }}
        {...onChange}
        {...register}
      />
    </Stack>
  );
}

CustomSelectInput.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.object,
  register: PropTypes.object,
  onChange: PropTypes.object,
  iconRight: PropTypes.element,
  width: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  radius: PropTypes.string,
  iconLeft: PropTypes.element,
  mobileWhite: PropTypes.bool,
  error: PropTypes.string,
  notRequired: PropTypes.bool,
};

export default CustomSelectInput;
