import { Flex, Stack } from "@mantine/core";
import useResponsive from "../../hooks/useResponsive";
import DashboardHeader from "../../connected/dashboardHeader/header";
import PropTypes from "prop-types";
import UsernavBar from "../../connected/userDashboard/userNavbar";
import React from "react";

function UserLayout({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  const { isTablet, isMobile } = useResponsive();
  return (
    <Flex justify="flex-start" align="flex-start" bg="light-grey.3">
      {!isTablet && <UsernavBar />}
      <Stack
        gap={"0"}
        style={{
          width: "100%",
          minHeight: "100vh",
          maxHeight: "100vh",
          overflowY: "hidden",
        }}
      >
        <DashboardHeader title={title} />
        <main
          style={{
            padding: isMobile
              ? "1em 0.5em"
              : isTablet
              ? "1em 1.5em"
              : "1.5em 2em",
            overflowY: "auto",
          }}
        >
          {children}
        </main>
      </Stack>
    </Flex>
  );
}

UserLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default React.memo(UserLayout);
