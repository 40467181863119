import { Avatar, Group, Text, Tooltip } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { getNameInitials } from "./reusableFunctions";

// export const FacePile = ({
//   users,
//   max = 1,
// }: {
//   users: {
//     businessName: string;
//     firstName: string;
//     lastName: string;
//     id: string;
//   }[];
//   max: number;
// }) => {
//   const navigate = useNavigate();
//   const remaining = users.length - max;
//   return (
//     <Group gap={0} align="center" justify="flex-start" style={{}}>
//       {users.slice(0, max).map((user, index) => {
//         const name = user?.businessName
//           ? user?.businessName
//           : `${user?.firstName} ${user?.lastName}`;
//         return (
//           <Avatar
//             onClick={() => navigate(`/admin/users/${user?.id}`)}
//             key={index}
//             size="md"
//             alt={name}
//             radius="xl"
//             color="#E86217"
//             style={{
//               cursor: "pointer",
//               border: "2px solid white",
//               boxShadow: "0 0 0 2px white",
//             }}
//           >
//             {getNameInitials({ name, maxInitials: 2 })}
//           </Avatar>
//         );
//       })}
//       {remaining > 0 && (
//         <Avatar
//           size="md"
//           alt="more"
//           radius="xl"
//           color="#E86217"
//           style={{
//             cursor: "pointer",
//             border: "2px solid white",
//             boxShadow: "0 0 0 2px white",
//           }}
//         >
//           <Text
//             style={{
//               fontSize: "0.8em",
//               fontWeight: 700,
//               color: "white",
//             }}
//           >
//             +{remaining}
//           </Text>
//         </Avatar>
//       )}
//     </Group>
//   );
// };

export const FacePile = ({
  users,
  max,
}: {
  users: {
    businessName: string;
    firstName: string;
    lastName: string;
    id: string;
  }[];
  max: number;
}) => {
  const navigate = useNavigate();
  const remaining = users.length - max;
  return (
    <Group gap={0} align="center" justify="flex-start" style={{}}>
      {users.slice(0, max).map((user, index) => {
        const name = user?.businessName
          ? user?.businessName
          : `${user?.firstName} ${user?.lastName}`;
        return (
          <Tooltip label={name} color="#E86217">
            <Avatar
              onClick={() => navigate(`/admin/users/${user?.id}`)}
              key={index}
              m={0}
              size="md"
              alt={name}
              radius="xl"
              color="#E86217"
              style={{
                cursor: "pointer",
                border: "2px solid white",
                boxShadow: "0 0 0 2px white",
              }}
            >
              {getNameInitials({ name, maxInitials: 2 })}
            </Avatar>
          </Tooltip>
        );
      })}
      {remaining > 0 && (
        <Avatar
          size="md"
          alt="more"
          radius="xl"
          p={0}
          style={{
            cursor: "pointer",
            backgroundColor: "white",
            border: "2px solid white",
            boxShadow: "0 0 0 2px white",
          }}
        >
          <Text c={"dark.4"} fz={"sm"} m={0} p={0}>
            +{remaining}
          </Text>
        </Avatar>
      )}
    </Group>
  );
};
