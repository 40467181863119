import ErrorPage from "./error/error-page";
import Home from "./homepage";
import About from "./aboutPage";
import Certificates from "./certificatesPage";
import Contact from "./contactPage";
import SignUp from "./signupPage";
import LogIn from "./loginPage";
import ResetPassword from "./resetPasswordPage";
import ForgotPassword from "./forgotPassowrd";
import Dashboard from "./dashboardPage";
import UserCertificateVerification from "./certificateVerifictionPage";
import UserDocumentVerification from "./documentVerificationPage";
import AdminDashboardPage from "./admindashboard";
import Users from "./usersPage";
import Workspaces from "./workspacesPage";
import AdminServices from "./adminServicesPage";
import AdminRequests from "./adminRequestsPage";
import AdminRequestDetails from "./adminRequestDetails";
import Settings from "./settings";
import EditUser from "./editUser";
import Agents from "./agents";
import RequestedServices from "./requestsServicesPage";
import PendingRequests from "./pendingRequestsPage";
import CompletededRequests from "./completedRequests";
import EditService from "./editServicePage";
import AcceptInvite from "./acceptInvitePage";
import RequestDetails from "./requestDetailsPage";
import VerifyEmail from "./verifyEmailPage";
import Analytics from "./analyticsPage";
import EmailVerified from "./emailVerifiedPage";
import Legal from "./legalPage";

export {
  ErrorPage,
  Home,
  About,
  Certificates,
  Contact,
  SignUp,
  LogIn,
  ResetPassword,
  ForgotPassword,
  Dashboard,
  UserCertificateVerification,
  UserDocumentVerification,
  AdminDashboardPage,
  Users,
  Workspaces,
  AdminServices,
  AdminRequests,
  AdminRequestDetails,
  Settings,
  EditUser,
  Agents,
  RequestedServices,
  PendingRequests,
  CompletededRequests,
  EditService,
  AcceptInvite,
  RequestDetails,
  VerifyEmail,
  Analytics,
  EmailVerified,
  Legal,
};
