import { useEffect, useState } from "react";
import { Text } from "@mantine/core";
import AdminLayout from "../../../../layouts/adminLayout";
import { ActitonButton } from "../users/users";
import { CustomCheckBox, CustomSearchInput } from "../../../shared/inputs";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
import CustomTable from "../../../shared/table/customTable";
import { useGetAvailableAgents } from "../../../../../hooks/admin/agents.hook";

function Agents() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetAvailableAgents(page, 40, search);
  const availableAgents = data?.data?.data;
  const availableAgentsCount = data?.data?.count;
  const availableAgentsTotalPages = data?.data?.totalPages;
  const availableAgentsCurrentPage = data?.data?.currentPage;

  const TableData = availableAgents?.map((agent: any) => {
    return {
      checbox: <CustomCheckBox />,
      institution: agent?.institution,
      email: agent?.email,
      name: agent?.businessName || agent?.firstName + " " + agent?.lastName,
      phone: agent?.phoneNo,
      amount: agent?.amount,
      action: <ActitonButton />,
    };
  });

  useEffect(() => {
    // Reset page to 1 when search changes
    setPage(1);
  }, [search]);

  return (
    <AdminLayout title="Agents">
      <CustomTable
        title={"Available Agents"}
        rightItem={
          <CustomSearchInput
            setSearch={setSearch}
            placeholder={"Serch by institution, email"}
          />
        }
        isTableLoading={isLoading}
        currentPage={availableAgentsCurrentPage}
        totalPages={availableAgentsTotalPages}
        setPage={setPage}
        count={availableAgentsCount}
        bodyRows={TableData}
        minWidth={"850px"}
        labels={[
          <CustomCheckBox />,
          "Institution",
          "Email",
          "Name",

          "Phone",
          "Amount",
          "Action",
        ]}
        emptyState={{
          title: "No Agent Found",
          description:
            "They are currently no agents available on Kpali, please onboard new agents to get started.",
          image: GhostIcon,
          height: "100px",
          width: "175px",
        }}
      />
    </AdminLayout>
  );
}

export default Agents;
