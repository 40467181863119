import InsightWrapper from "../insightWrapper";
import { useEffect, useState } from "react";
import InsightCardWrapper from "../insightCardWrapper";
import InsightCard from "../insightCard";
import InsightWrapper2 from "../insightWrapper2";
import InsightComponent from "../insightComponent";
import DoughnutChart from "../charts/dougnnut";
import BarChart from "../charts/barChart";
import LineChart from "../charts/lineChart";
import {
  useGetUserAnalytics,
  useGetUserDistribution,
  useGetUserLast6Month,
  useGetUserLineChart,
} from "../../../../../../hooks/admin/analytics.hook";
import InsightCardLoader from "../../../../shared/skeletonLoader/insightCardLoader";
import BarChartLoaderVertical from "../../../../shared/skeletonLoader/barChartLoader";
import { formatDate } from "../../../../shared/reusableFunctions";
import { set } from "react-hook-form";

function UserInisghts() {
  const [start, setStart] = useState<Date | null>(null);

  const [end, setEnd] = useState<Date | null>(null);

  const { data: user, isLoading: isGeneralUserLoading } = useGetUserAnalytics();
  const { data: distributionData, isLoading: distributionLoading } =
    useGetUserDistribution();
  const { data: last6MonthData, isLoading: last6MonthLoading } =
    useGetUserLast6Month();
  const { data: lineChartData, isLoading: lineChartLoading } =
    useGetUserLineChart(start, end);
  const [lineChartLabels, setLineChartLabels] = useState<string[]>([]);
  const [lineChartsData, setLineChartData] = useState<number[]>([]);
  const [last6MonthsLabels, setLast6MonthsLabels] = useState<string[]>([]);
  const [last6MonthsData, setLast6MonthsData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);

  const CardData = [
    { title: "All Users", value: user?.totalUsers },
    { title: "Active", value: user?.activeUsers },
    { title: "Verified", value: user?.verifiedUsers },
    { title: "Disabled", value: user?.disabledUsers },
  ];

  useEffect(() => {
    console.log({ start });
    console.log({ end });
  }, [start, end]);

  useEffect(() => {
    if (distributionData) {
      setLabels(Object.keys(distributionData));
      setData(Object.values(distributionData) as number[]);
    }
  }, [distributionData]);

  useEffect(() => {
    if (last6MonthData) {
      setLast6MonthsLabels(Object.keys(last6MonthData));
      setLast6MonthsData(Object.values(last6MonthData) as number[]);
    }
  }, [last6MonthData]);

  useEffect(() => {
    if (lineChartData) {
      setLineChartLabels(Object.keys(lineChartData));
      setLineChartData(Object.values(lineChartData) as number[]);
    }
  }, [lineChartData, start, end]);

  const distributionCallback = (context: any) => {
    const value = Number(context?.formattedValue);
    const label = context?.label;
    return `${value} users from ${label} in Kpali`;
  };

  const last6MonthsCallback = (context: any) => {
    const value = Number(context?.formattedValue);
    const label = context?.label;
    return `${value} Users in ${label}`;
  };

  const lineChartCallback = (context: any) => {
    const value = Number(context?.formattedValue);
    const label = context?.label;
    return `${value} Users in ${formatDate(label)}`;
  };

  return (
    <InsightWrapper>
      <InsightCardWrapper>
        {!isGeneralUserLoading && (
          <>
            {CardData.map((data, index) => (
              <InsightCard
                title={data.title}
                value={data.value}
                index={index}
              />
            ))}
          </>
        )}
        {isGeneralUserLoading && (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <InsightCardLoader key={index} />
            ))}
          </>
        )}
      </InsightCardWrapper>
      <InsightWrapper2>
        <InsightComponent title="Users Distribution">
          <DoughnutChart
            count={user?.totalUsers}
            callback={distributionCallback}
            title={"Total Users"}
            labels={labels}
            data={data}
            isLoading={distributionLoading}
          />
        </InsightComponent>
        <InsightComponent title="User Growth last 6 months">
          {!last6MonthLoading && (
            <>
              <BarChart
                labels={last6MonthsLabels}
                data={last6MonthsData}
                isLoading={last6MonthLoading}
                callback={last6MonthsCallback}
              />
            </>
          )}
          {last6MonthLoading && <BarChartLoaderVertical />}
        </InsightComponent>
      </InsightWrapper2>
      <InsightComponent title="Line Chart">
        <LineChart
          startDate={start}
          endDate={end}
          labels={lineChartLabels}
          data={lineChartsData}
          callback={lineChartCallback}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          {/* <input
            type="date"
            id="start"
            onChange={(e) => setStart(new Date(e.target.value))}
          />
          <input
            type="date"
            id="end"
            onChange={(e) => setEnd(new Date(e.target.value))}
          /> */}
        </div>
      </InsightComponent>
    </InsightWrapper>
  );
}

export default UserInisghts;
