import { Container, SimpleGrid } from "@mantine/core";
import GreyBackground from "../backgrounds/greyBackground";
import LandingSectionHeader from "../sectionHeading/landing";
import TeamCard from "../cards/teamCard";
import ProfileImage1 from "../../assets/images/profile1Image.png";
import ProfileImage2 from "../../assets/images/profile2Image.png";
import ProfileImage3 from "../../assets/images/profile3Image.png";
import ProfileImage4 from "../../assets/images/profile4Image.png";
import Bola from "../../assets/images/Bola.png";
import Zita from "../../assets/images/Zita.png";
import Nifemi from "../../assets/images/Nifemi.png";
import Tajudeen from "../../assets/images/Tajudeen.png";

const team = [
  {
    profileImage: Zita,
    name: "Mrs Zita Agwunobi",
    position: "Founder & Ceo",
  },
  {
    profileImage: Bola,
    name: "Mrs Bolanle Agbaje",
    position: "Business Operation Manager",
  },
  {
    profileImage: Tajudeen,
    name: "Mr Tajudeen ABdullahi",
    position: "Senior Verification Analyst",
  },
  {
    profileImage: Nifemi,
    name: "Barr Nifemi Adegun",
    position: "Legal Officer",
  },
];

function OurTeam() {
  return (
    <GreyBackground>
      <Container size={"lg"}>
        <LandingSectionHeader
          headerText="Our Team"
          subText="Our solution stands out with its versatility accoodating various."
        />
        <SimpleGrid cols={{ base: 2, md: 4 }}>
          {team.map((member, index) => (
            <TeamCard
              key={index}
              profile1Image={member.profileImage}
              name={member.name}
              position={member.position}
            />
          ))}
        </SimpleGrid>
      </Container>
    </GreyBackground>
  );
}

export default OurTeam;
