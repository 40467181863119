import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Country, State, City } from "country-state-city";
import { Stack, Text, Group, Button } from "@mantine/core";
import { useForm, SubmitHandler } from "react-hook-form";

import {
  useGetUserById,
  useUpdateUserById,
  useReactivateUser,
  useDeactivateUser,
} from "../../../../../hooks/admin/user.hook";
import { AppRoles } from "../../../../../enums/enums";
import AdminLayout from "../../../../layouts/adminLayout";
import {
  CustomTextInput,
  CustomSelectInput,
  CustomPhoneInput,
} from "../../../shared/inputs";
import OrangeButton from "../../../shared/buttons/orangeButton";
import { UpdateUserDto } from "../../../../../schema/admin/user";
import FormLoader from "../../../shared/skeletonLoader/formLoader";

function UserEdit({ data }: any) {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { mutate: updateUser, isPending: isUpdating } = useUpdateUserById(
    userId!
  );
  const { mutate: deactivateUser, isPending: isDeactivating } =
    useDeactivateUser(userId!);
  const { mutate: reactivateUser, isPending: isReactivating } =
    useReactivateUser(userId!);

  const [countriesSelect, setCountriesSelect] = useState<any[]>([]);
  const [statesSelect, setStatesSelect] = useState<any[]>([]);
  const [citiesSelect, setCitiesSelect] = useState<any[]>([]);

  const initialFormData = {
    firstName: data?.data?.firstName,
    lastName: data?.data?.lastName,
    businessName: data?.data?.businessName,
    email: data?.data?.email,
    phoneNo: data?.data?.phoneNo ?? "",
    country: data?.data?.country,
    state: data?.data?.state,
    city: data?.data?.city,
    appRole: data?.data?.appRole,
    // accountType: data?.data?.businessName
    //   ? WorkspaceType.CORPORATE
    //   : WorkspaceType.PERSONAL,
  };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UpdateUserDto>({
    defaultValues: initialFormData,
    // resolver: yupResolver(UpdateUserSchema),
  });

  const appRoleTypes = [
    { value: AppRoles.ADMIN, label: "Admin" },
    { value: "WORKSPACE", label: "User" },
    { value: AppRoles.AGENT, label: "Agent" },
  ];

  const handleDeactivate = () => {
    deactivateUser();
  };

  const handleReactivate = () => {
    reactivateUser();
  };

  const onSubmit: SubmitHandler<UpdateUserDto> = (data) => {
    updateUser(
      {
        firstName: data.firstName,
        appRole: [data.appRole],
        lastName: data.lastName,
        businessName: data.businessName,
        phoneNo: data.phoneNo,
        country: data.country,
        state: data.state,
        city: data.city,
      },
      {
        onSuccess: () => {
          navigate("/admin/users");
        },
      }
    );
  };

  const watchCountry = watch("country");
  const watchState = watch("state");
  const watchBusinessName = watch("businessName");

  useEffect(() => {
    const countries = Country.getAllCountries();
    const countriesSelected = countries.map((country) => ({
      value: country.isoCode + "," + country.name,
      label: country.name,
    }));
    setCountriesSelect(countriesSelected);
  }, []);

  useEffect(() => {
    const countryValue = watch("country");
    const countryCode = countryValue?.split(",")[0];
    const states = State.getStatesOfCountry(countryCode);
    const statesSelected = states.map((state) => ({
      value: state.isoCode + "," + state.name,
      label: state.name,
    }));
    setStatesSelect(statesSelected);
  }, [watchCountry, watch]);

  useEffect(() => {
    const countryValue = watch("country");
    const stateValue = watch("state");
    const countryCode = countryValue?.split(",")[0];
    const stateCode = stateValue?.split(",")[0];
    const cities = City.getCitiesOfState(countryCode, stateCode);
    const citiesSelected = cities.map((city) => ({
      value: city.name,
      label: city.name,
    }));
    setCitiesSelect(citiesSelected);
  }, [watchState, watchCountry, watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        mb={"2em"}
        maw={"500px"}
        align="center"
        bg={"light-grey.4"}
        p={"2.5em"}
        style={{
          boxShadow: " 0px 4px 16px 0px #0000000D",
          border: "1.30px solid #E8E8E8",
          borderRadius: "2em",
        }}
      >
        <Text fw={600} mb={"1em"} fz={"24px"}>
          Edit User Profile
        </Text>

        {watchBusinessName ? (
          <CustomTextInput
            label="Business Name"
            placeholder="Business Name"
            register={register("businessName")}
          />
        ) : (
          <Group grow>
            <CustomTextInput
              label="First Name"
              placeholder="First Name"
              register={register("firstName")}
              error={errors.firstName?.message}
            />
            <CustomTextInput
              label="Last Name"
              placeholder="Last Name"
              register={register("lastName")}
              error={errors.lastName?.message}
            />
          </Group>
        )}
        <CustomTextInput
          disabled
          label="Email"
          placeholder="Email"
          value={{ value: data?.data?.email }}
        />
        <CustomPhoneInput
          label="Phone Number"
          value={watch("phoneNo")}
          onChange={(value) => {
            setValue("phoneNo", value);
          }}
          error={errors.phoneNo?.message}
        />
        <CustomSelectInput
          data={appRoleTypes}
          label="App Role"
          value={{ value: watch("appRole") }}
          register={register("appRole")}
          error={errors.appRole?.message}
        />
        <CustomSelectInput
          data={countriesSelect}
          label="Country"
          value={{ value: watch("country") }}
          register={register("country")}
          onChange={() => {
            setValue("state", "");
            setValue("city", "");
          }}
          error={errors.country?.message}
        />
        <CustomSelectInput
          data={statesSelect}
          label="State"
          value={{ value: watch("state") }}
          register={register("state")}
          onChange={() => {
            setValue("city", "");
          }}
          error={errors.state?.message}
        />
        <CustomSelectInput
          data={citiesSelect}
          label={"City"}
          value={{ value: watch("city") }}
          register={register("city")}
        />
        {data?.data?.user?.deactivate === true ? (
          <Button
            variant="light"
            color="red"
            onClick={handleReactivate}
            loading={isReactivating}
          >
            Activate User
          </Button>
        ) : (
          <Button
            variant="light"
            color="red"
            onClick={handleDeactivate}
            loading={isDeactivating}
          >
            Deactivate User
          </Button>
        )}

        <OrangeButton
          onClick={handleSubmit((data) => {
            onSubmit(data);
          })}
          loading={isUpdating}
        />
      </Stack>
    </form>
  );
}

function EditUser() {
  const { userId } = useParams();
  const { data, isLoading } = useGetUserById(userId!);
  return (
    <AdminLayout title="Users">
      {!isLoading && <UserEdit data={data} />}
      {isLoading && <FormLoader title="Edit User Profile" />}
    </AdminLayout>
  );
}

export default EditUser;
