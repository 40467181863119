import { LandingLayout } from "../components/layouts/landing";
import { CertificateServices } from "../components/primitives/certificatesPage/certificateCards";
import { HeroBullets } from "../components/primitives/homePage/hero";
import VerificationProcess from "../components/primitives/homePage/verificationProcesses";
import ReputableBrands from "../components/primitives/homePage/reputableBrands";
import { withLanding } from "../hoc/withLanding";

const Home = () => {
  return (
    <LandingLayout>
      <HeroBullets />
      <VerificationProcess />
      <ReputableBrands />
      <CertificateServices />
    </LandingLayout>
  );
};

export default withLanding(Home);
