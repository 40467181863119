import "chart.js/auto";
import moment from "moment";
import { useEffect, useRef } from "react";
import propTypes from "prop-types";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-moment";

function LineChart({
  labels,
  data,
  callback,
  startDate,
  endDate,
}: propTypes.InferProps<typeof LineChart.propTypes>) {
  const chartRef = useRef(null);
  let timeUnit = false as
    | false
    | "millisecond"
    | "second"
    | "minute"
    | "hour"
    | "day"
    | "week"
    | "month"
    | "quarter"
    | "year"
    | undefined;

  const diffInDays = moment(endDate).diff(moment(startDate), "days");

  if (diffInDays >= 365) {
    timeUnit = "year";
  } else if (diffInDays >= 90) {
    timeUnit = "quarter";
  } else if (diffInDays >= 30) {
    timeUnit = "month";
  } else if (diffInDays >= 7) {
    timeUnit = "week";
  } else if (diffInDays >= 1) {
    timeUnit = "day";
  }
  const maxTicksLimit =
    timeUnit === "day"
      ? 7
      : timeUnit === "week"
      ? 4
      : timeUnit === "month"
      ? 6
      : timeUnit === "quarter"
      ? 4
      : timeUnit === "year"
      ? 12
      : 7;
  return (
    <Chart
      ref={chartRef}
      type="line"
      data={{
        labels,
        datasets: [
          {
            label: "My First dataset",
            data,
            fill: true,
            tension: 0.4,
            backgroundColor: (context) => {
              // const bgColor = [
              //   "rgba(255, 99, 132, 0.3)",
              //   "rgba(54, 162, 235, 0.3)",
              //   "rgba(255, 206, 86, 0.3)",
              //   "rgba(75, 192, 192, 0.3)",
              //   "rgba(153, 102, 255, 0.3)",
              //   "rgba(255, 159, 64, 0.3)",
              //   "rgba(255, 99, 132, 0.3)",
              // ];
              const bgColor = [
                "rgba(235, 134, 42, 1)", // Original color
                "rgba(243, 187, 110, 1)", // Shade 1
                "rgba(251, 239, 177, 1)", // Shade 2
                "rgba(255, 255, 221, 1)", // Shade 3
                "rgba(235, 134, 42, 1)", // Original color
                "rgba(235, 134, 42, 1)", // Original color
                "rgba(243, 187, 110, 1)", // Shade 1
                "rgba(251, 239, 177, 1)", // Shade 2
                "rgba(255, 255, 221, 1)", // Shade 3
                "rgba(235, 134, 42, 1)", // Original color
                "rgba(216, 119, 32, 1)", // Shade 1
              ];

              if (!context?.chart?.chartArea) {
                return;
              }
              const {
                ctx,
                data,
                chartArea: { top, bottom },
              } = context.chart;
              const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
              const colorTrances = 1 / (bgColor.length - 1);
              for (let i = 0; i < bgColor.length; i++) {
                gradientBg.addColorStop(0 + i * colorTrances, bgColor[i]);
              }
              return gradientBg;
            },
            borderColor: [
              "rgba(255, 26, 104, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(0, 0, 0, 1)",
            ],
            pointBackgroundColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(0, 0, 0, 1)",
            ],
            borderWidth: 1,
          },
        ],
      }}
      options={{
        scales: {
          x: {
            type: "time",
            time: {
              unit: timeUnit,
              displayFormats: {
                month: "MMM YYYY",
              },
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: maxTicksLimit,
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            // beginAtZero: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: callback,
            },
          },
          legend: {
            display: false,
          },
        },
      }}
    />
  );
}

LineChart.propTypes = {
  labels: propTypes.array.isRequired,
  data: propTypes.array.isRequired,
  callback: propTypes.func.isRequired,
  startDate: propTypes.instanceOf(Date),
  endDate: propTypes.instanceOf(Date),
};

export default LineChart;
