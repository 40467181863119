import Skeleton from "react-loading-skeleton";

const InsightCardLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        <Skeleton height={20} />
        <Skeleton height={80} />
      </div>
    </div>
  );
};

export default InsightCardLoader;
