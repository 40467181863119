import { IconProps } from "./overviewIcon";

export const PendingIcon = ({ className, width, height, fill }: IconProps) => (
  <svg
    width={width || "19"}
    height={height || "19"}
    className={className}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="material-symbols-light:pending-actions">
      <path
        id="Vector"
        d="M13.7635 16.625C12.881 16.625 12.1326 16.3178 11.5183 15.7035C10.904 15.0881 10.5968 14.3397 10.5968 13.4583C10.5968 12.5769 10.904 11.8286 11.5183 11.2132C12.1326 10.5988 12.881 10.2917 13.7635 10.2917C14.6448 10.2917 15.393 10.5988 16.0078 11.2132C16.6227 11.8275 16.9301 12.5759 16.9301 13.4583C16.9301 14.3402 16.6227 15.0886 16.0078 15.7035C15.393 16.3184 14.6448 16.6255 13.7635 16.625ZM15.0737 15.2016L15.5067 14.7693L14.0675 13.3301V11.1744H13.4587V13.5866L15.0737 15.2016ZM4.44553 15.8333C4.09192 15.8333 3.7903 15.7088 3.54066 15.4597C3.29155 15.2106 3.16699 14.9089 3.16699 14.5548V4.44521C3.16699 4.09159 3.29155 3.78997 3.54066 3.54033C3.78977 3.29122 4.09139 3.16666 4.44553 3.16666H8.11649C8.19038 2.85686 8.35478 2.59666 8.6097 2.38608C8.86462 2.1755 9.16149 2.07021 9.50033 2.07021C9.84549 2.07021 10.1447 2.17576 10.3981 2.38687C10.6514 2.59693 10.815 2.85686 10.8889 3.16666H14.5543C14.909 3.16666 15.2109 3.29122 15.46 3.54033C15.7091 3.78944 15.8337 4.09107 15.8337 4.44521V9.21104C15.6975 9.14084 15.5687 9.07936 15.4473 9.02658C15.3259 8.9738 15.1908 8.92657 15.042 8.88487V4.44521C15.042 4.32382 14.9913 4.21219 14.89 4.11033C14.7881 4.009 14.6765 3.95833 14.5551 3.95833H12.667V5.72375H6.33366V3.95833H4.44553C4.32414 3.95833 4.21252 4.009 4.11066 4.11033C4.00933 4.21219 3.95866 4.32382 3.95866 4.44521V14.5548C3.95866 14.6968 4.00431 14.8134 4.09562 14.9047C4.18692 14.996 4.30356 15.0417 4.44553 15.0417H9.29608C9.3383 15.1894 9.38817 15.3269 9.4457 15.4541C9.50376 15.5808 9.57765 15.7072 9.66737 15.8333H4.44633H4.44553ZM9.50033 4.14041C9.68399 4.14041 9.83652 4.07998 9.95791 3.95912C10.0793 3.83773 10.14 3.68521 10.14 3.50154C10.14 3.31787 10.0793 3.16534 9.95791 3.04396C9.83652 2.92257 9.68399 2.86187 9.50033 2.86187C9.31666 2.86187 9.16413 2.92257 9.04274 3.04396C8.92135 3.16534 8.86066 3.31787 8.86066 3.50154C8.86066 3.68521 8.92135 3.83773 9.04274 3.95912C9.16413 4.08051 9.31666 4.14041 9.50033 4.14041Z"
        stroke={fill || "#797979"}
      />
    </g>
  </svg>
);

PendingIcon.displayName = "PendingIcon";
