import PropTypes from "prop-types";
import { Group, Stack, Image, Text } from "@mantine/core";
import OrangeButton from "./buttons/orangeButton";

function EmptyState({
  title,
  description,
  image,
  buttonText,
  onClick,
  width,
  height,
}: {
  title: string;
  description: string;
  image: string;
  buttonText?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
}) {
  return (
    <Group w={"100%"} mt={"5%"} align="center" justify="center">
      <Stack
        align="center"
        justify="center"
        gap={"0.7rem"}
        ta={"center"}
        p={"2rem"}
      >
        <Image
          w={width ?? 150}
          h={height ?? 300}
          src={image}
          alt="Empty Request"
        />
        <Stack w={"100%"} justify="center" align="center" gap={0}>
          <Text
            c={"dark.4"}
            fz={{ base: "20px", md: "24px" }}
            fw={600}
            ta={"center"}
          >
            {title}
          </Text>
          <Text
            w={"70%"}
            c={"light-grey.12"}
            ta="center"
            fz={{
              base: "14px",
              md: "16px",
            }}
          >
            {description}
          </Text>
        </Stack>
        {buttonText && (
          <Group align="center">
            <OrangeButton
              size={"sm"}
              paddingX={"1.5em"}
              buttonText={buttonText}
              onClick={onClick}
            />
          </Group>
        )}
      </Stack>
    </Group>
  );
}

EmptyState.prototype = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.element.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onclick: PropTypes.func,
  buttonText: PropTypes.string,
};

export default EmptyState;
