import { Box, NavLink, Flex, Container } from "@mantine/core";
import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { LandingLayout } from "../../layouts/landing";
import { withLanding } from "../../../hoc/withLanding";
import TOS from "./TOS";
import PrivacyPolicy from "./privacyPolicy";
import DataPrivacy from "./dataPrivacy";
import useResponsive from "../../../hooks/useResponsive";

const Legal = () => {
  const legalTabs = useMemo(
    () => [
      { label: "Terms of Service", tab: "tos" },
      { label: "Privacy Policy", tab: "privacy" },
      { label: "Data Protection Policy", tab: "data" },
    ],
    []
  );

  const [activeTab, setActiveTab] = useState("tos");
  const navigate = useNavigate();
  const { isTablet, isMobile } = useResponsive();
  const currentTab = new URLSearchParams(window.location.search).get("tab");

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
      navigate(`?tab=${currentTab}`);
    }
    if (!currentTab || !legalTabs.map((tab) => tab.tab).includes(currentTab)) {
      setActiveTab("tos");
      navigate("?tab=tos");
    }
  }, [navigate, legalTabs, currentTab]);

  return (
    <LandingLayout>
      <Container size={"lg"} w={"100%"}>
        <Flex
          direction={isTablet ? "column" : "row"}
          p={isTablet ? "1em" : "3em 1em"}
          gap={isMobile ? "0.5em" : "1em"}
          align="flex-start"
          justify={"flex-start"}
        >
          <Box
            style={{
              borderRight: "none",
              width: isTablet ? "100%" : "200px",
              height: "100%",
            }}
          >
            {legalTabs.map((tab, index) => (
              <NavLink
                onClick={() => {
                  setActiveTab(tab.tab);
                  navigate(`?tab=${tab.tab}`);
                }}
                key={index}
                label={tab.label}
                active={tab.tab === activeTab}
                style={{ marginBottom: "0.5em", borderRadius: "5px" }}
              />
            ))}
          </Box>
          <Box style={{ flex: 1 }}>
            {activeTab === "tos" && <TOS isMobile={isMobile} />}
            {activeTab === "privacy" && <PrivacyPolicy isMobile={isMobile} />}
            {activeTab === "data" && <DataPrivacy isMobile={isMobile} />}
          </Box>
        </Flex>
      </Container>
    </LandingLayout>
  );
};

export default withLanding(Legal);
