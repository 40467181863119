import * as Yup from "yup";
import {
  WorkspaceType,
  IWorkspaceType,
  WorkspaceRole,
  IWorkspaceRole,
} from "../../enums/enums";

export const CreateWorkspaceSchema = Yup.object().shape({
  name: Yup.string().required("Workspace name is required").trim(),
  type: Yup.mixed<IWorkspaceType>()
    .oneOf(Object.values(WorkspaceType))
    .required("Workspace type is required"),
});

export type CreateWorkspaceDto = Yup.InferType<typeof CreateWorkspaceSchema>;

export const UpdateWorkspaceSchema = Yup.object().shape({
  name: Yup.string().required("Workspace name is required").trim(),
  type: Yup.mixed<IWorkspaceType>().oneOf(Object.values(WorkspaceType)),
  domain: Yup.string().url("Invalid domain").optional(),
});

export type UpdateWorkspaceDto = Yup.InferType<typeof UpdateWorkspaceSchema>;

export const CreateWorkspaceInviteSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required")
    .trim(),
  role: Yup.mixed<IWorkspaceRole>()
    .oneOf(Object.values(WorkspaceRole))
    .required("Role is required"),
});

export type CreateWorkspaceInviteDto = Yup.InferType<
  typeof CreateWorkspaceInviteSchema
>;

export const UpdateWorkspacePermissionSchema = Yup.object().shape({
  role: Yup.mixed<IWorkspaceRole>()
    .oneOf(Object.values(WorkspaceRole))
    .required("Role is required"),
});
