import proptypes from "prop-types";
import { Text, Title } from "@mantine/core";
import useResponsive from "../../../../../hooks/useResponsive";
function InsightCard({
  title,
  value,
  index,
}: proptypes.InferProps<typeof InsightCard.propTypes>) {
  const { isMobile } = useResponsive();
  return (
    <div
      key={index}
      style={{
        width: "100%",
        padding: isMobile ? "0.5em" : "1em",
        borderRadius: "10px",
        backgroundColor: "white",
        boxShadow: "0px 1px 2px 0px #1018280f",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "0.25em",
      }}
    >
      <Text c={"#667085"} m={0} p={0}>
        {title}
      </Text>
      <Title order={1} fw={600} c={"#101828"} m={0} p={0}>
        {value}
      </Title>
    </div>
  );
}

InsightCard.propTypes = {
  title: proptypes.string.isRequired,
  value: proptypes.string.isRequired || proptypes.number.isRequired,
  index: proptypes.number.isRequired,
};

export default InsightCard;
