import {
  Image,
  Button,
  Modal,
  Stack,
  Group,
  Text,
  Divider,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CustomCheckBox } from "../../../shared/inputs";
import { useGetAllUsers } from "../../../../../hooks/admin/user.hook";
import useResponsive from "../../../../../hooks/useResponsive";
import AdminLayout from "../../../../layouts/adminLayout";
import CustomTable from "../../../shared/table/customTable";
import TableActionIcon from "../../../../assets/svg/tableActionIcon.svg";
import TransparentTable from "../../../shared/table/transparentTable";
import { formatDate } from "../../../shared/reusableFunctions";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";

export const ActitonButton = ({ onClick }: any) => {
  return (
    <Button radius={"0.5em"} onClick={onClick}>
      <Image src={TableActionIcon} alt="action" />
    </Button>
  );
};

function Users() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [labels, setLabels] = useState([] as any[]);
  const [values, setValues] = useState([] as any[]);
  const { data, isPending } = useGetAllUsers(page, 10);
  const [active, setActive] = useState<any>(null);
  const { isMobile } = useResponsive();
  const usersData = data?.data?.data;
  const usersCount = data?.data?.count;
  const usersTotalPages = data?.data?.totalPages;
  const usersCurrentPage = data?.data?.currentPage;

  const TableData = usersData?.map((user: any, index: number) => {
    const fullName = `${user?.firstName} ${user?.lastName}`;
    return {
      checbox: <CustomCheckBox />,
      name: user?.firstName ? fullName : user?.businessName,
      email: user?.email,
      dateJoined: new Date(user?.createdAt).toDateString(),
      status:
        user?.deactivate === true ? (
          <Text
            style={{
              borderRadius: "7.5px",
            }}
            c={"dark.4"}
            p={"xs"}
            bg={"red"}
            component="span"
          >
            Disabled
          </Text>
        ) : user?.emailVerified === true ? (
          <Text
            style={{
              borderRadius: "7.5px",
            }}
            c={"green.10"}
            p={"xs"}
            bg={"green.11"}
            component="span"
          >
            Active
          </Text>
        ) : (
          <Text
            style={{
              borderRadius: "7.5px",
            }}
            c={"orange.10"}
            p={"xs"}
            bg={"orange.11"}
            component="span"
          >
            Pending
          </Text>
        ),
      action: <ActitonButton onClick={() => setActive(user)} />,
    };
  });

  useEffect(() => {
    if (isPending) return;
    const labels = [
      "Name",
      "Email",
      "PhoneNo",
      "Country",
      "State",
      "City",
      "Status",
      "App Role",
      "Date Joined",
    ];
    const values = [
      active?.businessName
        ? active?.businessName
        : `${active?.firstName} ${active?.lastName}`,
      active?.email,
      active?.phoneNo ?? "N/A",
      active?.country?.split(",")[1],
      active?.state.split(",")[1],
      active?.city,
      active?.deactivate === true
        ? "Disabled"
        : active?.emailVerified === true
        ? "Active"
        : "Pending",
      active?.appRole,
      active?.createdAt ? formatDate(active?.createdAt) : null,
    ];
    setLabels(labels);
    setValues(values);
  }, [active, isPending, usersData]);

  const handleEdit = () => {
    navigate(`/admin/users/${active?.id}`);
  };

  return (
    <AdminLayout title="Users">
      {active && (
        <Modal
          opened={true}
          onClose={() => setActive(null)}
          fullScreen={isMobile}
          title={
            <Text c={"light-grey.8"} fw={600} fz={"24px"}>
              Users
            </Text>
          }
        >
          <Divider c={"light-grey.7"} size={1} />
          <Modal.Body>
            <TransparentTable labels={labels} values={values} />
          </Modal.Body>

          <Button
            radius={"0.5em"}
            bg={"light-grey.0"}
            c={"dark.4"}
            style={{
              border: "1px solid #E8E8E8",
            }}
            fw={700}
            onClick={handleEdit}
            variant="default"
          >
            Edit
          </Button>
        </Modal>
      )}
      <CustomTable
        isTableLoading={isPending}
        currentPage={usersCurrentPage}
        totalPages={usersTotalPages}
        setPage={setPage}
        count={usersCount}
        bodyRows={TableData}
        minWidth={"850px"}
        title={"Users"}
        labels={[
          <CustomCheckBox />,
          "Name",
          "Email",
          "Date Joined",
          "Status",
          "Action",
          "",
          "",
        ]}
        emptyState={{
          title: "No Users Found",
          description: "They are currently no users available on Kpali",
          height: "100px",
          width: "175px",
          image: GhostIcon,
        }}
      />
    </AdminLayout>
  );
}

export default Users;
