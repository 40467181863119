import { Table, Box, Group, Title, Text, Stack } from "@mantine/core";
import Proptypes from "prop-types";
import OverflowWrapper from "../overflowWrapper";
import PaginationComponent from "../pagination/pagination";
import EmptyState from "../emptyState";
import useResponsive from "../../../../hooks/useResponsive";
import TableLoader from "../skeletonLoader/tableLoader";

function CustomTable({
  labels,
  bodyRows,
  title,
  count,
  leftItem,
  rightItem,
  minWidth,
  currentPage,
  totalPages,
  setPage,
  emptyState,
  isTableLoading,
  tableLoadingCount,
}: Proptypes.InferProps<typeof CustomTable.propTypes>) {
  const { isLaptop } = useResponsive();
  return (
    <Stack gap={"0.5em"}>
      <Box
        bg={"light-grey.4"}
        style={{
          boxShadow: "0px 4px 16px 0px #0000000D",
          border: "1.3px solid #E8E8E8",
          borderRadius: "16px",
          // height: "80vh",
          // height: emptyState && !tableLoadingCount ? "75vh" : "auto",
        }}
      >
        <Group
          bg={"light-grey.4"}
          p={"1.5em 1em"}
          // p={bodyRows?.length === 0 ? "1.5em 1em 0em 1em" : "1.5em 1em"}
          style={{
            borderRadius: "16px",
          }}
        >
          {!rightItem && title && (
            <>
              <Title order={3} fw={600}>
                {title}
              </Title>
              {count && (
                <Text c={"light-grey.8"} fz={"sm"}>
                  total
                  <Text c={"orange.4"} fw={600} span>
                    {count}
                  </Text>
                </Text>
              )}
            </>
          )}
          {leftItem && rightItem && (
            <Group
              w={"100%"}
              justify="space-between"
              style={{
                borderBottom: "1.30px solid #E8E8E8",
              }}
            >
              {leftItem}
              {rightItem}
            </Group>
          )}
          {rightItem && title && (
            <Group
              w={"100%"}
              justify="space-between"
              style={{
                borderBottom: "1.30px solid #E8E8E8",
              }}
            >
              <Group>
                <Title order={3} fw={600}>
                  {title}
                </Title>
                {count && (
                  <Text c={"light-grey.8"} fz={"sm"}>
                    total
                    <Text c={"orange.4"} fw={600} span>
                      {count}
                    </Text>
                  </Text>
                )}
              </Group>
              {rightItem}
            </Group>
          )}
        </Group>
        {bodyRows?.length !== 0 && (
          <OverflowWrapper minWidth={minWidth}>
            <Table>
              <Table.Thead>
                <Table.Tr
                  bg={"light-grey.3"}
                  style={{
                    borderBottom: "1.30px solid #E8E8E8",
                  }}
                >
                  {labels?.map((label: string, index: number) => {
                    return (
                      <Table.Th
                        p={"0.5em 1em"}
                        c={"light-grey.8"}
                        fz={"xs"}
                        fw={600}
                        key={index}
                      >
                        {typeof label === "string"
                          ? label.toUpperCase()
                          : label}
                      </Table.Th>
                    );
                  })}
                </Table.Tr>
              </Table.Thead>
              {bodyRows && (
                <Table.Tbody>
                  {bodyRows?.map(
                    (
                      rowData: { [key: string]: string | JSX.Element },
                      index: number
                    ) => {
                      return (
                        <Table.Tr
                          key={index}
                          style={{
                            borderBottom: "1.4px solid #E8E8E8",
                          }}
                        >
                          {Object?.keys(rowData)?.map((key, i) => {
                            return (
                              <Table.Td p={"1em"} key={i}>
                                {rowData[key]}
                              </Table.Td>
                            );
                          })}
                        </Table.Tr>
                      );
                    }
                  )}
                </Table.Tbody>
              )}
            </Table>
          </OverflowWrapper>
        )}
        {!isTableLoading && bodyRows?.length === 0 && emptyState && (
          <EmptyState
            title={emptyState?.title}
            description={emptyState?.description}
            image={emptyState?.image}
            buttonText={emptyState?.buttonText ?? undefined}
            onClick={emptyState?.onclick ?? undefined}
            width={emptyState?.width ?? "150px"}
            height={emptyState?.height ?? "300px"}
          />
        )}
        {isTableLoading && <TableLoader count={tableLoadingCount ?? 10} />}
      </Box>
      {totalPages !== 1 && bodyRows?.length !== 0 && (
        <>
          {currentPage && setPage && totalPages && (
            <div
              style={{
                backgroundColor: "none",
                padding: isLaptop ? "0em 0em" : "0em 4em",
              }}
            >
              <PaginationComponent
                setPage={setPage}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </div>
          )}
        </>
      )}
    </Stack>
  );
}

CustomTable.propTypes = {
  labels: Proptypes.array.isRequired,
  bodyRows: Proptypes.array.isRequired,
  title: Proptypes.string,
  count: Proptypes.number,
  leftItem: Proptypes.node,
  rightItem: Proptypes.node,
  minWidth: Proptypes.string,
  currentPage: Proptypes.number,
  totalPages: Proptypes.number,
  setPage: Proptypes.func,
  isTableLoading: Proptypes.bool,
  tableLoadingCount: Proptypes.number,
  emptyState: Proptypes.shape({
    title: Proptypes.string.isRequired,
    description: Proptypes.string.isRequired,
    image: Proptypes.any.isRequired,
    buttonText: Proptypes.string,
    onclick: Proptypes.func,
    width: Proptypes.string,
    height: Proptypes.string,
  }),
};

export default CustomTable;
