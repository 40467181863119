import { useState } from "react";
import { Button, Modal, Text, Divider, Group } from "@mantine/core";
import useResponsive from "../../../../../../hooks/useResponsive";
import CustomTable from "../../../../shared/table/customTable";
import TransparentTable from "../../../../shared/table/transparentTable";
import { CustomCheckBox, CustomSearchInput } from "../../../../shared/inputs";
import { useGetAvailableAgents } from "../../../../../../hooks/admin/agents.hook";
function AgentSection() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const { isMobile } = useResponsive();
  const { data, isLoading } = useGetAvailableAgents(page, 5, search);
  const [active, setActive] = useState(null as any);
  const availableAgents = data?.data?.data;
  const availableAgentsCount = data?.data?.count;
  const availableAgentsTotalPages = data?.data?.totalPages;
  const availableAgentsCurrentPage = data?.data?.currentPage;

  const TableData = availableAgents?.map((agent: any, index: number) => {
    return {
      checbox: <CustomCheckBox />,
      name: agent?.businessName || agent?.firstName + " " + agent?.lastName,
      email: agent?.email,
      phone: agent?.phoneNo,
      state: agent?.state?.split(",")[1],
      action: (
        <Button
          onClick={() => {
            setActive(agent);
          }}
        >
          Assign
        </Button>
      ),
    };
  });

  const agentlabels = [
    "Agent Full Name",
    "Email Address",
    "Phone Number",
    "State",
    "Current Tasks",
    "Tasks Completed",
  ];
  const agentValues = [
    active?.businessName || active?.firstName + " " + active?.lastName,
    active?.email,
    active?.phoneNo,
    active?.state?.split(",")[1],
    active?.currentTasks || 0,
    active?.tasksCompleted || "0/5",
  ];

  const requestlabels = [
    "Request ID",
    "Request Type",
    "Request Date",
    "Status",
  ];

  const requestValues = ["1234", "Certificate", "12/12/2021", "Pending"];

  return (
    <>
      <Modal
        opened={active !== null}
        fullScreen={isMobile}
        onClose={() => setActive(null)}
        title={
          <Text c={"dark.4"} fw={600} fz={"24px"}>
            Assign Agent
          </Text>
        }
      >
        <Modal.Body>
          <Text c={"light-grey.8"}>AGENT</Text>
          <TransparentTable labels={agentlabels} values={agentValues} />
          {/* <Divider c={"#E8E8E8"} /> */}
          <Text c={"light-grey.8"}>TASK</Text>
          <TransparentTable labels={requestlabels} values={requestValues} />
          <Group justify="space-between">
            <Button
              bg={"light-grey.5"}
              c={"dark.4"}
              size="md"
              radius={"8px"}
              onClick={() => setActive(null)}
            >
              Cancel
            </Button>
            <Button bg={"blue.0"} radius={"8px"} size="md">
              Assign
            </Button>
          </Group>
        </Modal.Body>
      </Modal>
      <CustomTable
        leftItem={<Text c={"light-grey.8"}>Available Agents</Text>}
        rightItem={
          <CustomSearchInput
            setSearch={setSearch}
            placeholder={"Serch by name, state, email, phone number"}
          />
        }
        currentPage={availableAgentsCurrentPage}
        totalPages={availableAgentsTotalPages}
        setPage={setPage}
        count={availableAgentsCount}
        bodyRows={TableData}
        minWidth={"850px"}
        labels={[
          <CustomCheckBox />,
          "Name",
          "Email",
          "Phone",
          "State",
          "Action",
        ]}
      />
    </>
  );
}

export default AgentSection;
