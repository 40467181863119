import { Flex, Stack } from "@mantine/core";
import useResponsive from "../../hooks/useResponsive";
import AdminNavbar from "../../connected/adminDashboard/adminNavbar";
import DashboardHeader from "../../connected/dashboardHeader/header";
import PropTypes from "prop-types";

function AdminLayout({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  const { isTablet, isMobile, isLaptop } = useResponsive();

  return (
    <>
      <Flex justify="flex-start" align="flex-start" bg="light-grey.3">
        {!isTablet && <AdminNavbar />}
        <Stack
          gap={"0"}
          style={{
            width: "100%",
            minHeight: "100vh",
            maxHeight: "100vh",
            overflowY: "hidden",
          }}
        >
          <DashboardHeader title={title} isAdmin={true} />
          <main
            style={{
              padding: isMobile
                ? "1em 0.5em"
                : isTablet
                ? "1em 1.5em"
                : isLaptop
                ? "1em"
                : "2em 2.5em",
              overflowY: "auto",
            }}
          >
            {children}
          </main>
        </Stack>
      </Flex>
    </>
  );
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default AdminLayout;
