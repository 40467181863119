import InsightWrapper from "../insightWrapper";
import { useEffect, useState } from "react";
import InsightCardWrapper from "../insightCardWrapper";
import InsightCard from "../insightCard";
import InsightWrapper2 from "../insightWrapper2";
import InsightComponent from "../insightComponent";
import DoughnutChart from "../charts/dougnnut";
import BarChart from "../charts/barChart";
import {
  useGetWorkspaceAnalytics,
  useGetWorkspaceDistribution,
  useGetWorkspaceLast6Month,
} from "../../../../../../hooks/admin/analytics.hook";
import InsightCardLoader from "../../../../shared/skeletonLoader/insightCardLoader";
import BarChartLoaderVertical from "../../../../shared/skeletonLoader/barChartLoader";

function WorkspaceInsights() {
  const { data: workspace, isLoading: isGeneralWorkspaceLoading } =
    useGetWorkspaceAnalytics();
  const { data: distributionData, isLoading: distributionLoading } =
    useGetWorkspaceDistribution();
  const { data: last6MonthData, isLoading: last6MonthLoading } =
    useGetWorkspaceLast6Month();

  const [last6MonthsLabels, setLast6MonthsLabels] = useState<string[]>([]);
  const [last6MonthsData, setLast6MonthsData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);

  const CardData = [
    { title: "All Workspaces", value: workspace?.allWorkspaces },
    { title: "Active", value: workspace?.activeWorkspaces },
    { title: "Deleted", value: workspace?.deletedWorkspaces },
    {
      title: "Avg User per Workspace",
      value: workspace?.averageUserPerWorkspace,
    },
  ];

  useEffect(() => {
    if (distributionData) {
      setLabels(Object.keys(distributionData).splice(0, 2));
      setData(Object.values(distributionData).splice(0, 2) as number[]);
    }
  }, [distributionData]);

  useEffect(() => {
    if (last6MonthData) {
      setLast6MonthsLabels(Object.keys(last6MonthData));
      setLast6MonthsData(Object.values(last6MonthData) as number[]);
    }
  }, [last6MonthData]);

  const distributionCallback = (context: any) => {
    const value = Number(context?.formattedValue);
    const label = context?.label;
    return `${value} ${label} accounts in Kpali`;
  };

  const last6MonthsCallback = (context: any) => {
    const value = Number(context?.formattedValue);
    const label = context?.label;
    return `${value} Workspaces in ${label}`;
  };

  return (
    <InsightWrapper>
      <InsightCardWrapper>
        {!isGeneralWorkspaceLoading && (
          <>
            {CardData.map((data, index) => (
              <InsightCard
                title={data.title}
                value={data.value}
                index={index}
              />
            ))}
          </>
        )}
        {isGeneralWorkspaceLoading && (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <InsightCardLoader key={index} />
            ))}
          </>
        )}
      </InsightCardWrapper>
      <InsightWrapper2>
        <InsightComponent title="Workspace Distribution">
          <DoughnutChart
            count={distributionData?.total}
            callback={distributionCallback}
            title={"Total Workspace"}
            labels={labels}
            data={data}
            isLoading={distributionLoading}
          />
        </InsightComponent>
        <InsightComponent title="Workspace Growth last months">
          {!last6MonthLoading && (
            <>
              <BarChart
                labels={last6MonthsLabels}
                data={last6MonthsData}
                isLoading={last6MonthLoading}
                callback={last6MonthsCallback}
              />
            </>
          )}
          {last6MonthLoading && <BarChartLoaderVertical />}
        </InsightComponent>
      </InsightWrapper2>
      <InsightComponent title="Line Chart">
        <h1>hey</h1>
      </InsightComponent>
    </InsightWrapper>
  );
}

export default WorkspaceInsights;
