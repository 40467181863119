import cx from "clsx";
import { signOut } from "firebase/auth";

import { useMemo, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  UnstyledButton,
  Group,
  Text,
  Menu,
  rem,
  Stack,
  Modal,
  Avatar,
  Radio,
  Box,
  Button,
} from "@mantine/core";
import {
  IconLogout,
  IconUser,
  IconSettings,
  IconSwitchHorizontal,
  IconChevronDown,
} from "@tabler/icons-react";
import { Plus } from "tabler-icons-react";
import classes from "./userMenu.module.css";
import useResponsive from "../../../hooks/useResponsive";
import {
  useCurrentWorkspace,
  useCreateWorkspace,
} from "../../../hooks/user/user.workspace.hook";
import { CustomTextInput } from "../../../components/primitives/shared/inputs";
import { getNameInitials } from "../../../components/primitives/shared/reusableFunctions";
import {
  CreateWorkspaceSchema,
  CreateWorkspaceDto,
} from "../../../schema/workspace/workspace";
import { WorkspaceType } from "../../../enums/enums";
import { getFirebaseAuth } from "../../../services/firebase";

function UserMenu({
  user,
  userMenuOpened,
  setUserMenuOpened,
}: PropTypes.InferProps<typeof UserMenu.propTypes>) {
  // const { mutate, isPending } = useSignOut();
  const { mutate: createWorkspace, isPending: isCreatingWorkspace } =
    useCreateWorkspace();
  const [isWorkspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const [isCreateWorkspaceModalOpen, setCreateWorkspaceModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const { isTablet } = useResponsive();
  const { currentUser, isLoading, workspaceId, setWorkspaceId } =
    useCurrentWorkspace();

  const userAccounts = useMemo(() => {
    if (isLoading) return null;
    return (currentUser?.workspaces || []).map((workspace: any) => {
      return (
        <Group gap={"0.5em"}>
          <Radio
            size="sm"
            value={workspace?.workspace?.id}
            checked={workspace?.workspace?.id === workspaceId}
            onClick={() => {
              setWorkspaceId(workspace?.workspaceId);
              setWorkspaceModalOpen(false);
              navigate("/overview");
              toast.success("Workspace switched successfully");
            }}
            name="change-workspace"
          />
          <Group gap={"0.5em"}>
            {workspace?.workspace?.name && (
              <Avatar
                alt={workspace?.workspace?.name}
                radius="xl"
                color="#E86217"
              >
                {getNameInitials({
                  name: workspace?.workspace?.name,
                  maxInitials: 2,
                })}
              </Avatar>
            )}
            <Box>
              <Text size="sm" fw={600}>
                {workspace?.workspace?.name}
              </Text>
              <Text size="xs">{workspace?.workspace?.type}</Text>
            </Box>
          </Group>
        </Group>
      );
    });
  }, [currentUser, isLoading, workspaceId, setWorkspaceId, navigate]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateWorkspaceDto>({
    defaultValues: {
      name: "",
      type: WorkspaceType.PERSONAL,
    },
    resolver: yupResolver(CreateWorkspaceSchema),
  });

  const isAdmin = currentUser?.appRole.includes("ADMIN");
  const isAdminPath = window.location.pathname.includes("admin");

  const personalWorkspace = watch("type") === WorkspaceType.PERSONAL;
  const corporateWorkspace = watch("type") === WorkspaceType.CORPORATE;

  const onSubmit: SubmitHandler<CreateWorkspaceDto> = (data) => {
    createWorkspace(data, {
      onSuccess: (data) => {
        reset();
        setWorkspaceId(data?.data?.id);
        setCreateWorkspaceModalOpen(false);
        window.setTimeout(() => {
          navigate("/overview");
        }, 1000);
      },
    });
  };

  // useEffect(() => {
  //   if (isPending) {
  //     toast.info("Signing out...");
  //   }
  // }, [isPending]);

  return (
    <Menu
      width={260}
      position="bottom-end"
      transitionProps={{ transition: "pop-top-right" }}
      onClose={() => setUserMenuOpened(false)}
      onOpen={() => setUserMenuOpened(true)}
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
        >
          <Group gap={7}>
            <Avatar alt={user.name} radius="xl" color="#E86217">
              {user.initials}
            </Avatar>
            {!isTablet && (
              <Stack gap={1} justify="center">
                <Text fw={500} size="xs" lh={1} mr={3}>
                  {user.name}
                </Text>
                <Text fw={350} size="xs" lh={1}>
                  {user.workspaceName}
                </Text>
              </Stack>
            )}
            <IconChevronDown
              style={{ width: rem(20), height: rem(20) }}
              stroke={1.5}
            />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown
        style={{
          border: "1px solid #E8E8E8",
        }}
      >
        <Menu.Label>Settings</Menu.Label>
        <Menu.Item
          onClick={() => {
            navigate("/settings");
          }}
          leftSection={
            <IconSettings
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
        >
          Account settings
        </Menu.Item>
        {isAdmin && (
          <Menu.Item
            onClick={() => {
              navigate(isAdminPath ? "/overview" : "/admin/dashboard");
            }}
            leftSection={
              <IconUser
                style={{ width: rem(16), height: rem(16) }}
                stroke={1.5}
              />
            }
          >
            {isAdminPath ? "User Dashboard" : "Admin Dashboard"}
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            setWorkspaceModalOpen(true);
          }}
          leftSection={
            <IconSwitchHorizontal
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
        >
          Change workspace
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          onClick={() => signOut(getFirebaseAuth)}
          leftSection={
            <IconLogout
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
        >
          {"Sgin Out"}
          {/* {isPending ? "Signing out..." : "Sign out"} */}
        </Menu.Item>
      </Menu.Dropdown>
      <Modal
        opened={isWorkspaceModalOpen}
        onClose={() => setWorkspaceModalOpen(false)}
        title="Switch Workspace"
        size="md"
      >
        <Stack gap={10} style={{ padding: rem(20) }}>
          <>{userAccounts}</>
          <Button
            onClick={() => {
              setWorkspaceModalOpen(false);
              setCreateWorkspaceModalOpen(true);
            }}
            variant="default"
            size="md"
            style={{
              border: "1px solid #E8E8E8",
            }}
          >
            <Plus size={20} style={{ marginRight: "1em" }} />
            <Text fw={600}>Add new Workspace</Text>
          </Button>
        </Stack>
      </Modal>
      <Modal
        opened={!isWorkspaceModalOpen && isCreateWorkspaceModalOpen}
        onClose={() => setCreateWorkspaceModalOpen(false)}
        title="Create Workspace"
        size={"md"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={20} style={{ padding: rem(10) }}>
            <CustomTextInput
              notRequired
              label={
                <Stack gap={0}>
                  <Text size="sm">Workspace Name</Text>
                  <Text size="xs" c={"light-grey.8"}>
                    What's your space called?
                  </Text>
                </Stack>
              }
              register={register("name")}
              error={errors.name?.message}
            />
            <Stack>
              <Stack gap={0}>
                <Text size="sm">Workspace Type</Text>
                <Text size="xs" c={"light-grey.8"}>
                  Choose between personal or cooperate workspace
                </Text>
              </Stack>
              <Group>
                <Radio
                  label="Personal"
                  checked={personalWorkspace}
                  onClick={() => {
                    setValue("type", WorkspaceType.PERSONAL);
                  }}
                  name="type"
                />
                <Radio
                  label="Cooperate"
                  checked={corporateWorkspace}
                  onClick={() => {
                    setValue("type", WorkspaceType.CORPORATE);
                  }}
                  name="type"
                />
              </Group>
            </Stack>
          </Stack>
          <Group justify="flex-end" mt={"lg"}>
            <Button
              onClick={() => setCreateWorkspaceModalOpen(false)}
              variant="default"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isCreatingWorkspace}
              loading={isCreatingWorkspace}
            >
              Submit
            </Button>
          </Group>
        </form>
      </Modal>
    </Menu>
  );
}

UserMenu.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    workspaceName: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
  }).isRequired,
  userMenuOpened: PropTypes.bool.isRequired,
  setUserMenuOpened: PropTypes.func.isRequired,
};

export default React.memo(UserMenu);
