const colors = [
  "#FFE7D9",
  "#FDCBAF",
  "#FFAC7D",
  "#FB8C4E",
  "#E86217",
  "#BA4605",
  "#933906",
  "#652501",
  "#3D1600",
  "#A0522D",
  "#DD8705",
  "#FFF0DA",
  "#FFF2EB",
  "#a6691e",
  "#8b8f14",
  "#15734a",
  "#154773",
  "#7a5199",
  "#944d93",
  "#944d62",
  "#A4BCFD",
  "#FDA29B",
  "#FEB273",
  "#FEA3B4",
  "#5CA2FF",
  "#2A9E20",
  "#207419",
  "#1A5A14",
  "#155010",
  "#16380B",
  "#355E3B",
  "#319755",
  "#E2F8E5",
  "#F2F2F2",
  "#D9D9D9",
  "#FAFAFA",
  "#F8F8F8",
  "#FFF",
  "#E8E8E8",
  "#E7E7E7",
  "#F5F4F3",
  "#797979",
  "#CDCDCD",
  "#B5B5B5",
  "#A9B3C3",
  "#979797",
  "#C9C9C9",
  "#B8B8B8",
  "#828282",
  "#696969",
  "#282828",
  "#4A4A4A",
  "#404040",
  "#383838",
  "#2E2E2E",
  "#393939",
  "#6A778B",
  "#0C8CE9",
  "#E6F7FF",
  "#BAE7FF",
  "#91D5FF",
  "#69C0FF",
  "#40A9FF",
  "#1890FF",
  "#E6F7FF",
  "#BAE7FF",
  "#91D5FF",
  "#69C0FF",
  "#40A9FF",
  "#1890FF",
];

export const randomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};
