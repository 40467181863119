import API from "../../api/api";
import { toast } from "react-toastify";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useLocalStorage } from "@mantine/hooks";
import { useGetUserProfile } from "./user.hook";
import {
  UpdateWorkspaceDto,
  CreateWorkspaceDto,
  CreateWorkspaceInviteDto,
} from "../../schema/workspace/workspace";

const URL = "/workspaces";

export const useCurrentWorkspace = () => {
  const [workspaceId, setWorkspaceId] = useLocalStorage<string | null>({
    key: "kpali-workspace-id",
    defaultValue: localStorage.getItem("kpali-workspace-id") || null,
  });
  const { isLoading, data } = useGetUserProfile();
  const currentUser = useMemo(() => data?.data, [data]);

  useEffect(() => {
    if (isLoading) return;
    if (currentUser) {
      if (currentUser?.workspaces && currentUser?.workspaces.length) {
        const cleanWorkspaceId = (id: string) =>
          id.replace(/-/g, "").replace(/"/g, "");
        const existingWorkspace = currentUser?.workspaces.find((wrk: any) => {
          return (
            cleanWorkspaceId(wrk.workspaceId) ===
            cleanWorkspaceId(workspaceId ?? "")
          );
        });
        if (!existingWorkspace || !workspaceId) {
          const value = currentUser.workspaces[0].workspaceId;
          setWorkspaceId(value);
        }
      }
    }
  }, [workspaceId, currentUser, setWorkspaceId, isLoading]);
  // useEffect(() => {
  //   if (isLoading) return; // Do nothing while loading
  //   if (currentUser) {
  //     //...process the active workspace Id

  //     if (currentUser.workspaces && currentUser.workspaces.length) {
  //       const existingWorkspace = currentUser.workspaces.find(
  //         (wrk: any) => wrk.workspaceId === workspaceId
  //       );
  //       if (!existingWorkspace || !workspaceId) {
  //         const value = currentUser.workspaces[0].workspaceId;
  //         setWorkspaceId(value);
  //       }
  //     }
  //   }
  // }, [workspaceId, currentUser, setWorkspaceId, isLoading]);

  const workspaceDetails = useMemo(() => {
    if (!!currentUser && workspaceId) {
      const workspaceUser = currentUser.workspaces.find(
        (wrk: any) => wrk.workspaceId === workspaceId
      );
      return workspaceUser;
    }
    return null;
  }, [currentUser, workspaceId]);

  return {
    isLoading,
    workspaceId,
    setWorkspaceId,
    currentUser,
    workspaceDetails: workspaceDetails?.workspace,
    workspaceUserRole: workspaceDetails?.mainRole,
  };
};

export const useDashboard = (id: string) => {
  const getDashboard = async () => {
    const response = await API.get(`${URL}/dashboard/${id}`);
    return response;
  };

  const query = useQuery({
    queryFn: getDashboard,
    queryKey: ["GetDashboard", id],
  });
  return query;
};

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  const createWorkspace = async (data: CreateWorkspaceDto) => {
    const response = await API.post(`${URL}/create`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: createWorkspace,
    onSuccess: () => {
      toast.success("Workspace created successfully");
      queryClient.invalidateQueries({
        queryKey: ["GetUserProfile"],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useUpdateWorkspace = (id: string) => {
  const queryClient = useQueryClient();
  const updateWorkspace = async (data: UpdateWorkspaceDto) => {
    const response = await API.put(`${URL}/${id}`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: updateWorkspace,
    onSuccess: () => {
      toast.success("Workspace updated successfully");
      queryClient.invalidateQueries({
        queryKey: ["GetUserProfile"],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useDeleteWorkspace = (id: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const deleteWorkspace = async () => {
    const response = await API.delete(`${URL}/${id}`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: deleteWorkspace,
    onSuccess: () => {
      toast.success("Workspace deleted successfully");
      navigate("/overview");
      queryClient.invalidateQueries({
        queryKey: ["GetUserProfile"],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useGetWorkspaceMembers = (id: string) => {
  const getWorkspaceMembers = async () => {
    const response = await API.get(`${URL}/${id}/members`);
    return response;
  };

  const query = useQuery({
    queryFn: getWorkspaceMembers,
    queryKey: ["GetWorkspaceMembers", id],
  });
  return query;
};

export const useDeleteWorkspaceMember = (workspaceId: string) => {
  const queryClient = useQueryClient();
  const deleteWorkspaceMember = async (memberId: string) => {
    const response = await API.delete(`${URL}/${workspaceId}/user/${memberId}`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: deleteWorkspaceMember,
    onSuccess: () => {
      toast.success("Member removed successfully");
      queryClient.invalidateQueries({
        queryKey: ["GetWorkspaceMembers", workspaceId],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useGetWorkspaceInvites = (id: string) => {
  const getWorkspaceInvites = async () => {
    const response = await API.get(`${URL}/invites/${id}`);
    return response;
  };

  const query = useQuery({
    queryFn: getWorkspaceInvites,
    queryKey: ["GetWorkspaceInvites", id],
  });
  return query;
};

export const useInviteMember = (id: string) => {
  const queryClient = useQueryClient();
  const inviteMember = async (data: CreateWorkspaceInviteDto) => {
    const response = await API.post(`${URL}/invites/${id}`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: inviteMember,
    onSuccess: () => {
      toast.success("Member invited successfully");
      queryClient.invalidateQueries({
        queryKey: ["GetWorkspaceInvites", id],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useAcceptInvite = (id: string) => {
  const queryClient = useQueryClient();
  const acceptInvite = async () => {
    const response = await API.post(`${URL}/invites/accept/${id}`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: acceptInvite,
    onSuccess: () => {
      toast.success("Invite accepted successfully");
      setTimeout(() => {
        window.location.replace(`/overview`);
      }, 1000);

      queryClient.invalidateQueries({
        queryKey: ["GetUserProfile"],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useDeleteInvite = (workspaceId: string) => {
  const queryClient = useQueryClient();
  const deleteInvite = async (id: string) => {
    const response = await API.delete(`${URL}/invites/${workspaceId}/${id}`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: deleteInvite,
    onSuccess: () => {
      toast.success("Invite deleted successfully");
      queryClient.invalidateQueries({
        queryKey: ["GetWorkspaceInvites", workspaceId],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useUpdatePermission = (workspaceId: string) => {
  const queryClient = useQueryClient();
  const updatePermission = async (data: { memberId: string; role: string }) => {
    const response = await API.put(`${URL}/${workspaceId}/member/changerole`, {
      role: data.role,
      userId: data.memberId,
    });
    return response;
  };
  const mutation = useMutation({
    mutationFn: updatePermission,
    onSuccess: () => {
      toast.success("Permission updated successfully");
      queryClient.invalidateQueries({
        queryKey: ["GetWorkspaceMembers", workspaceId],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      queryClient.invalidateQueries({
        queryKey: ["GetWorkspaceMembers", workspaceId],
      });
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};
