import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Group, Modal, Radio, rem, Stack, Text } from "@mantine/core";
import AdminLayout from "../../../../layouts/adminLayout";
import { CustomCheckBox, CustomTextInput } from "../../../shared/inputs";
import CustomTable from "../../../shared/table/customTable";
import { useGetAllWorkspaces } from "../../../../../hooks/admin/workspace.hook";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
import { ActitonButton } from "../users/users";
import { FacePile } from "../../../shared/facePile";
import {
  CreateWorkspaceDto,
  CreateWorkspaceSchema,
} from "../../../../../schema/workspace/workspace";
import { WorkspaceType } from "../../../../../enums/enums";

function Workspaces() {
  const [page, setPage] = useState(1);
  const { data, isPending } = useGetAllWorkspaces(page, 10);
  const workspaceData = data?.data?.data;
  const workspaceCount = data?.data?.count;
  const workspaceTotalPages = data?.data?.totalPages;
  const workspaceCurrentPage = data?.data?.currentPage;
  const [isUpdateWorkspaceModalOpen, setIsUpdateWorkspaceModalOpen] =
    useState<any>(null);

  const TableData = workspaceData?.map((workspace: any) => {
    const workspaceUsers = [] as any;
    workspace?.users?.map((member: any) => {
      return workspaceUsers.push(member?.user);
    });
    return {
      checbox: <CustomCheckBox />,
      name: workspace?.name,
      accountType: workspace?.type,
      members: <FacePile users={workspaceUsers} max={4} />,
      status: workspace?.deleted === true ? "Deleted" : "Active",
      action: (
        <ActitonButton
          onClick={() => {
            setIsUpdateWorkspaceModalOpen(workspace);
          }}
        />
      ),
    };
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateWorkspaceDto>({
    defaultValues: {
      name: isUpdateWorkspaceModalOpen?.name,
      type: isUpdateWorkspaceModalOpen?.type,
    },
    resolver: yupResolver(CreateWorkspaceSchema),
  });

  useEffect(() => {
    if (isUpdateWorkspaceModalOpen) {
      reset({
        name: isUpdateWorkspaceModalOpen?.name,
        type: isUpdateWorkspaceModalOpen?.type,
      });
    }
  }, [isUpdateWorkspaceModalOpen, reset]);

  const personalWorkspace = watch("type") === WorkspaceType.PERSONAL;
  const corporateWorkspace = watch("type") === WorkspaceType.CORPORATE;

  const onSubmit: SubmitHandler<CreateWorkspaceDto> = (data) => {
    window.alert("No API to update workspace");
  };

  return (
    <AdminLayout title="Workspaces">
      <CustomTable
        isTableLoading={isPending}
        currentPage={workspaceCurrentPage}
        totalPages={workspaceTotalPages}
        setPage={setPage}
        count={workspaceCount}
        bodyRows={TableData}
        minWidth={"850px"}
        title={"Workspaces"}
        labels={[
          <CustomCheckBox />,
          "Workspace Name",
          "Account Type",
          "Members",
          "Status",
          "Action",
        ]}
        emptyState={{
          title: "No Workspaces Found",
          description: "They are currently no workspaces available on Kpali",
          height: "100px",
          width: "175px",
          image: GhostIcon,
        }}
      />
      <Modal
        opened={isUpdateWorkspaceModalOpen !== null}
        onClose={() => setIsUpdateWorkspaceModalOpen(null)}
        title="Update Workspace"
        size={"md"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={20} style={{ padding: rem(10) }}>
            <CustomTextInput
              notRequired
              label={
                <Stack gap={0}>
                  <Text size="sm">Workspace Name</Text>
                  <Text size="xs" c={"light-grey.8"}>
                    What's the workspace called?
                  </Text>
                </Stack>
              }
              register={register("name")}
              error={errors.name?.message}
            />
            <Stack>
              <Stack gap={0}>
                <Text size="sm">Workspace Type</Text>
                <Text size="xs" c={"light-grey.8"}>
                  Choose between personal or cooperate workspace
                </Text>
              </Stack>
              <Group>
                <Radio
                  label="Personal"
                  checked={personalWorkspace}
                  onClick={() => {
                    setValue("type", WorkspaceType.PERSONAL);
                  }}
                  name="accountType"
                />
                <Radio
                  label="Cooperate"
                  checked={corporateWorkspace}
                  onClick={() => {
                    setValue("type", WorkspaceType.CORPORATE);
                  }}
                  name="accountType"
                />
              </Group>
            </Stack>
          </Stack>
          <Group justify="flex-end" mt={"lg"}>
            <Button
              onClick={() => setIsUpdateWorkspaceModalOpen(null)}
              variant="default"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              // disabled={isCreatingWorkspace}
              // loading={isCreatingWorkspace}
            >
              Update
            </Button>
          </Group>
        </form>
      </Modal>
    </AdminLayout>
  );
}

export default Workspaces;
