import { useState } from "react";
import { Tooltip } from "@mantine/core";
import CustomTable from "../../../shared/table/customTable";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
import { useGetDocumentRequests } from "../../../../../hooks/documents/user.documents.hook";
import { formatDate } from "../../../shared/reusableFunctions";
import { ActitonButton } from "../../admin/users/users";
import { CustomCheckBox } from "../../../shared/inputs";
import RequestStatusText from "../../../shared/requestStatus";
import { useNavigate } from "react-router-dom";

function DocumentsTable({ workspaceId }: { workspaceId: string }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, isPending } = useGetDocumentRequests(workspaceId, page, 5);
  const {
    count: documentCount,
    currentPage: documentCurrentPage,
    totalPages: documentTotalPages,
    data: documents,
  } = data?.data || {};

  const labels = [
    <CustomCheckBox />,
    "requester",
    "document type",
    "date",
    "status",
    "action",
  ];

  const handleNavigate = (id: string) => {
    navigate(`/requested/${id}`);
  };

  const TableData =
    documents?.map((document: any) => {
      const requesterName = document?.requester?.businessName
        ? document?.requester?.userId?.businessName
        : document?.requester?.userId?.firstName +
          " " +
          document?.requester?.userId?.lastName;

      const email = document?.requester?.userId?.email;
      return {
        checkbox: <CustomCheckBox />,
        name: (
          <Tooltip label={email}>
            <span>{requesterName}</span>
          </Tooltip>
        ),
        documentType: document?.documentType,
        date: formatDate(document?.createdAt),
        status: <RequestStatusText status={document?.status} />,
        action: <ActitonButton onClick={() => handleNavigate(document?.id)} />,
      };
    }) || [];
  return (
    <CustomTable
      isTableLoading={isPending}
      tableLoadingCount={5}
      count={documentCount}
      totalPages={documentTotalPages}
      currentPage={documentCurrentPage}
      setPage={setPage}
      title="Document"
      labels={labels}
      minWidth={"850px"}
      bodyRows={TableData}
      emptyState={{
        title: "No Document Request Found",
        description:
          "To get started on your verification process, click the button below",
        image: GhostIcon,
        buttonText: "Make Document Request",
        onclick: () => navigate("/service/document"),
      }}
    />
  );
}

export default DocumentsTable;
