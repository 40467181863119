// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_root__wmuaZ {
    padding-top: 5em;
    padding-bottom: 5em;
}

.error_label__bz8yM {
    text-align: center;
    font-weight: 900;
    font-size: 3.5em;
    line-height: 1;
    margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
    color: var(--mantine-color-gray-2);

    @media (max-width: \$mantine-breakpoint-sm) {
        font-size: 2em;
    }
}

.error_description__DMMnq {
    max-width: 30em;
    margin: auto;
    margin-top: var(--mantine-spacing-xl);
    margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
}

.error_title__KsI2T {
    font-family: Greycliff CF, var(--mantine-font-family);
    text-align: center;
    font-weight: 900;
    font-size: 2.2em;

    @media (max-width: \$mantine-breakpoint-sm) {
        font-size: 2em;
    }
}`, "",{"version":3,"sources":["webpack://./src/containers/error/error.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,oDAAoD;IACpD,kCAAkC;;IAElC;QACI,cAAc;IAClB;AACJ;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,qCAAqC;IACrC,oDAAoD;AACxD;;AAEA;IACI,qDAAqD;IACrD,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;;IAEhB;QACI,cAAc;IAClB;AACJ","sourcesContent":[".root {\n    padding-top: 5em;\n    padding-bottom: 5em;\n}\n\n.label {\n    text-align: center;\n    font-weight: 900;\n    font-size: 3.5em;\n    line-height: 1;\n    margin-bottom: calc(1.5 * var(--mantine-spacing-xl));\n    color: var(--mantine-color-gray-2);\n\n    @media (max-width: $mantine-breakpoint-sm) {\n        font-size: 2em;\n    }\n}\n\n.description {\n    max-width: 30em;\n    margin: auto;\n    margin-top: var(--mantine-spacing-xl);\n    margin-bottom: calc(1.5 * var(--mantine-spacing-xl));\n}\n\n.title {\n    font-family: Greycliff CF, var(--mantine-font-family);\n    text-align: center;\n    font-weight: 900;\n    font-size: 2.2em;\n\n    @media (max-width: $mantine-breakpoint-sm) {\n        font-size: 2em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `error_root__wmuaZ`,
	"label": `error_label__bz8yM`,
	"description": `error_description__DMMnq`,
	"title": `error_title__KsI2T`
};
export default ___CSS_LOADER_EXPORT___;
