import { Button } from "@mantine/core";
import PropTypes from "prop-types";

function OrangeButton({
  paddingX,
  loading,
  width,
  height,
  radius,
  buttonText,
  size,
  onClick,
}: PropTypes.InferProps<typeof OrangeButton.propTypes>) {
  return (
    <Button
      {...(onClick ? { onClick } : null)}
      loading={loading === true ? true : false}
      size={size ? size : "md"}
      h={height ? height : "3em"}
      w={width ? width : "100%"}
      radius={radius ? radius : "0.5em"}
      px={paddingX ? paddingX : "2.5em"}
      td={"none"}
    >
      {buttonText ? buttonText : "Submit"}
    </Button>
  );
}

OrangeButton.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  loading: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  radius: PropTypes.string,
  size: PropTypes.string,
  paddingX: PropTypes.string,
};

export default OrangeButton;
