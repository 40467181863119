import { Stack, SimpleGrid } from "@mantine/core";
import UserLayout from "../../../../layouts/userLayout";
import { useCurrentWorkspace } from "../../../../../hooks/user/user.workspace.hook";
import UserDashboardCertificateIcon from "../../../../assets/svg/userDashboardCertificateIcon.svg";
import UserDashboardDocumentIcon from "../../../../assets/svg/userDashboardocumentsIcon.svg";
import UserdashboardCompletedIcon from "../../../../assets/svg/userDashboardCompletedIcon.svg";
import UserashboardUserIcon from "../../../../assets/svg/userDashboardUserIcon.svg";
import InsightCard from "./insightCard";
import { useDashboard } from "../../../../../hooks/user/user.workspace.hook";
import { formatNumber } from "../../../shared/reusableFunctions";
import CertificatesTable from "./certificatesTable";
import DocumentsTable from "./documentsTable";
import CardLoader from "../../../shared/skeletonLoader/cardLoader";

function Overview() {
  const { workspaceId } = useCurrentWorkspace();
  const { data, isPending } = useDashboard(workspaceId!);

  

  const {
    totalVerification,
    totalCertificatesVerified,
    totalDocumentsVerified,
    completedVerification,
  } = data?.data || {};

  const dashboardData = [
    {
      title: "TOTAL VERIFICATIONS",
      value: formatNumber(totalVerification),
      icon: UserashboardUserIcon,
      iconBackground: "#F5FBFF",
    },
    {
      title: "TOTAL CERTIFICATES VERIFIED",
      value: formatNumber(totalCertificatesVerified),
      icon: UserDashboardCertificateIcon,
      iconBackground: "#FDF7F8",
    },
    {
      title: "TOTAL DOCUMENTS VERIFIED",
      value: formatNumber(totalDocumentsVerified),
      icon: UserDashboardDocumentIcon,
      iconBackground: "#FFF9F0",
    },
    {
      title: "COMPLETED VERIFICATION",
      value: formatNumber(completedVerification),
      icon: UserdashboardCompletedIcon,
      iconBackground: "#FFF4EF",
    },
  ];

  return (
    <UserLayout title="Overview">
      <Stack gap="2.5em">
        <SimpleGrid
          cols={{
            xs: 1,
            sm: 2,
            md: 4,
          }}
          spacing={{ base: 10, md: "xs", lg: "xl" }}
        >
          {!isPending && (
            <>
              {dashboardData.map((card, index) => (
                <InsightCard key={index} card={card} />
              ))}
            </>
          )}
          {isPending && (
            <>
              {Array(4)
                .fill(0)
                .map((_, index) => (
                  <CardLoader key={index} />
                ))}
            </>
          )}
        </SimpleGrid>
        <CertificatesTable workspaceId={workspaceId!} />
        <DocumentsTable workspaceId={workspaceId!} />
      </Stack>
    </UserLayout>
  );
}

export default Overview;
