import { Button, Stack, Text, Title, Box } from "@mantine/core";
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { CustomPasswordInput } from "../../../shared/inputs";
import { useChangePassword } from "../../../../../hooks/user/user.hook";
import {
  ChangePasswordSchema,
  ChangePasswordDto,
} from "../../../../../schema/user/user";
import { useCurrentWorkspace } from "../../../../../hooks/user/user.workspace.hook";

function ChangePassword() {
  const auth = getAuth();
  const { currentUser } = useCurrentWorkspace();
  const { mutate, isPending } = useChangePassword();

  const initialFormData = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangePasswordDto>({
    defaultValues: initialFormData,
    resolver: yupResolver(ChangePasswordSchema),
  });

  const onSubmit: SubmitHandler<ChangePasswordDto> = async (data) => {
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        currentUser?.email,
        data.currentPassword
      );
      await reauthenticateWithCredential(user!, credential);
      mutate(data, {
        onSuccess: () => {
          reset();
        },
      });
    } catch (err: any) {
      if (err instanceof FirebaseError) {
        if (err.code === "auth/invalid-credential") {
          toast.error("Invalid Credentials");
          return;
        }
        toast.error(err.message);
        return;
      }
      toast.error(err?.message ?? "Something went wrong");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={"lg"}>
        <Stack gap={0}>
          <Title order={2}>Password</Title>
          <Text component="span" fw={500} c="gray">
            Please enter your current password to change your password.
          </Text>
        </Stack>

        <Stack gap="md">
          <CustomPasswordInput
            placeholder="******"
            label="Current Password"
            register={register("currentPassword")}
            error={errors.currentPassword?.message}
          />
          <CustomPasswordInput
            placeholder="*******"
            label="New Password"
            register={register("password")}
            error={errors.password?.message}
          />
          <CustomPasswordInput
            placeholder="******"
            label="Confirm New Password"
            register={register("confirmPassword")}
            error={errors.confirmPassword?.message}
          />
        </Stack>
        <Box>
          <Button
            fullWidth={false}
            loading={isPending}
            onClick={handleSubmit((data) => {
              onSubmit(data);
            })}
          >
            Save Changes
          </Button>
        </Box>
      </Stack>
    </form>
  );
}

export default ChangePassword;
