import {
  Container,
  Title,
  Divider,
  BackgroundImage,
  Flex,
} from "@mantine/core";

interface AboutImageProps {
  image: string;
  headerText: string;
}

function AboutImage({ image, headerText }: AboutImageProps) {
  return (
    <BackgroundImage
      src={image}
      h={{ base: "30vh", sm: "45vh" }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",

        // maskImage: "linear-gradient(92deg, black 9%, rgba(0, 0, 0, 0) 100%)",
        // maskImage:
        //   "linear-gradient(92deg, rgba(0, 0, 0, 0.6) 9%, rgba(0, 0, 0, 0) 100%)",
        // maskImage: "linear-gradient(0deg, #282828 0%, #282828 100%)",
        // maskImage:
        //   "linear-gradient(to right, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 100%)",

        // filter: "brightness(0.1)",
        boxShadow: `0px 10px 19.2px 0px rgba(0, 0, 0, 0.15)`,
      }}
    >
      <Container size={"lg"} w={"100%"}>
        <Flex
          justify={{ base: "center", sm: "flex-start" }}
          align={{ base: "center", sm: "flex-start" }}
        >
          <Flex
            direction={"column"}
            gap={"md"}
            align={{ base: "center", sm: "flex-start" }}
            w={"max-content"}
          >
            <Title
              fw={{ base: 800, md: 900 }}
              fz={{ base: "2em", sm: "3em" }}
              c="white"
            >
              {headerText}
            </Title>
            <Divider color={"orange"} w={{ base: "50%", sm: "50%" }} size={4} />
          </Flex>
        </Flex>
      </Container>
    </BackgroundImage>
  );
}

export default AboutImage;
