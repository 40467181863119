import useResponsive from "../../../../../hooks/useResponsive";
function InsightCardWrapper({ children }: React.PropsWithChildren) {
  const { isMobile, isTablet, isLaptop } = useResponsive();
  const gap = isMobile ? "0.5em" : isTablet ? "1em" : isLaptop ? "2em" : "3em";
  const grid = isLaptop ? 2 : 4;
  return (
    <div
      style={{
        display: "grid",
        gap: gap,
        gridTemplateColumns: `repeat(${grid}, 1fr)`,
        width: "100%",
        marginTop: "1em",
      }}
    >
      {children}
    </div>
  );
}

export default InsightCardWrapper;
