import { useState } from "react";
import { Text, Stack, Tooltip } from "@mantine/core";
import { ServiceCategory } from "../../../../../enums/enums";
import { ActitonButton } from "../../admin/users/users";
import CustomTable from "../../../shared/table/customTable";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
import EmptyRequestImage from "../../../../assets/images/emptyRequestImage.png";
import { useGetWorkspaceRequests } from "../../../../../hooks/requests/user.requests.hook";
import { useCurrentWorkspace } from "../../../../../hooks/user/user.workspace.hook";
import { CustomCheckBox } from "../../../shared/inputs";
import UserLayout from "../../../../layouts/userLayout";
import { formatDate } from "../../../shared/reusableFunctions";
import RequestStatusText from "../../../shared/requestStatus";
import { useNavigate } from "react-router-dom";

function RequestedServices() {
  const navigate = useNavigate();
  const { workspaceId } = useCurrentWorkspace();
  const [page, setPage] = useState(1);
  const { data, isPending } = useGetWorkspaceRequests(workspaceId!, page, 10);

  const {
    count: requestsCount,
    currentPage: requestsCurrentPage,
    totalPages: requestsTotalPage,
    data: requests,
  } = data?.data || {};

  const handleNavigate = (id: string) => {
    navigate(`/requested/${id}`);
  };

  const TableData = requests?.map((request: any, index: number) => {
    const requesterName = request?.requester?.businessName
      ? request?.requester?.userId?.businessName
      : request?.requester?.userId?.firstName +
        " " +
        request?.requester?.userId?.lastName;

    const requesterEmail = request?.requester?.userId?.email;

    return {
      checbox: <CustomCheckBox />,
      name: (
        <Tooltip label={requesterEmail}>
          <span>{requesterName}</span>
        </Tooltip>
      ),
      serviceCategory: (
        <Stack gap={"0"}>
          <Text>
            {request?.serviceCategory?.charAt(0).toUpperCase() +
              request?.serviceCategory?.slice(1).toLowerCase()}
          </Text>
          <Text fz={"sm"}>
            {request?.serviceCategory === ServiceCategory.CERTIFICATE
              ? request?.certificates[0]?.certificateType
                  ?.charAt(0)
                  .toUpperCase() +
                request?.certificates[0]?.certificateType
                  ?.slice(1)
                  .toLowerCase()
              : request?.documents[0]?.documentType?.charAt(0).toUpperCase() +
                request?.documents[0]?.documentType?.slice(1).toLowerCase()}
          </Text>
        </Stack>
      ),
      date: formatDate(request?.createdAt),
      status: <RequestStatusText status={request?.status} />,
      action: (
        <ActitonButton
          onClick={() => {
            handleNavigate(request?.id);
          }}
        />
      ),
    };
  });

  const labels = [
    <CustomCheckBox />,
    "requester",
    "service",
    "date",
    "status",
    "details",
    "",
  ];
  return (
    <UserLayout title="Requested Services">
      <CustomTable
        isTableLoading={isPending}
        currentPage={requestsCurrentPage}
        totalPages={requestsTotalPage}
        setPage={setPage}
        count={requestsCount}
        title={"Requested Services"}
        bodyRows={TableData}
        labels={labels}
        minWidth={"850px"}
        emptyState={{
          title: "No Request Found",
          description:
            "To get started on your verification process, click the button below",
          image: GhostIcon,
          height: "100px",
          width: "175px",
          buttonText: "Make Document Request",
          onclick: () => navigate("/service/document"),
        }}
      />
    </UserLayout>
  );
}

export default RequestedServices;
