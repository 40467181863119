import { IconProps } from "./overviewIcon";

export const AllRequestsIcon = ({
  className,
  width,
  height,
  fill,
}: IconProps) => (
  <svg
    width={width || "17"}
    height={height || "17"}
    className={className}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="mynaui:list">
      <path
        id="Vector"
        d="M5.66634 4.60416H14.1663M5.66634 8.5H14.1663M5.66634 12.3958H14.1663M2.83301 4.60416H3.54134M2.83301 8.5H3.54134M2.83301 12.3958H3.54134"
        stroke={fill || "#797979"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

AllRequestsIcon.displayName = "AllRequestsIcon";
