import API from "../../api/api";
import { toast } from "react-toastify";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";

const URL = "/admin/users";

export const useGetAllUsers = (page: number, limit: number) => {
  const getAllUsers = async () => {
    const response = await API.get(`${URL}?page=${page}&limit=${limit}`);
    return response;
  };
  const query = useQuery({
    queryFn: getAllUsers,
    queryKey: ["GetAllUsers", page, limit],
  });

  return query;
};

export const useGetUserById = (userId: string) => {
  const getUserById = async () => {
    const response = await API.get(`${URL}/${userId}`);
    return response;
  };
  const query = useQuery({
    queryFn: getUserById,
    queryKey: [`GetUserById-${userId}`],
  });

  return query;
};

export const useUpdateUserById = (userId: string) => {
  const queryClient = useQueryClient();
  const updateUserById = async (dto: any) => {
    const response = await API.put(`${URL}/update/${userId}`, dto);
    return response;
  };
  const mutation = useMutation({
    mutationFn: updateUserById,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["GetAllUsers"],
      });
      queryClient.invalidateQueries({ queryKey: [`GetUserById-${userId}`] });
      toast.success("User updated successfully");
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });

  return mutation;
};

export const useDeactivateUser = (userId: string) => {
  const queryClient = useQueryClient();
  const deactivateUser = async () => {
    const response = await API.patch(`${URL}/deactivate/${userId}`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: deactivateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["GetAllUsers"],
      });
      queryClient.invalidateQueries({ queryKey: [`GetUserById-${userId}`] });
      toast.success("User deactivated successfully");
    },
  });

  return mutation;
};

export const useReactivateUser = (userId: string) => {
  const queryClient = useQueryClient();
  const reactivateUser = async () => {
    const response = await API.patch(`${URL}/activate/${userId}`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: reactivateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["GetAllUsers"],
      });
      queryClient.invalidateQueries({ queryKey: [`GetUserById-${userId}`] });
      toast.success("User reactivated successfully");
    },
  });

  return mutation;
};
