import Skeleton from "react-loading-skeleton";
import useResponsive from "../../../../hooks/useResponsive";

function BarChartLoaderVertical() {
  const { isTablet, isMobile, isLaptop } = useResponsive();
  const bar = Array.from({ length: isMobile ? 5 : isTablet ? 7 : 8 });
  const generateRandomHeight = () => {
    return Math.floor(Math.random() * 400 + 1);
  };
  const width = isMobile ? 50 : isTablet ? 55 : isLaptop ? 40 : 65;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        gap: "1em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          gap: "1em",
          borderRadius: "10px",
        }}
      >
        {bar.map((_, index) => (
          <Skeleton key={index} height={generateRandomHeight()} width={width} />
        ))}
      </div>
    </div>
  );
}

export default BarChartLoaderVertical;
