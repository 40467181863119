import { Box, NavLink, Stack, Group } from "@mantine/core";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import UserLayout from "../../../../layouts/userLayout";
import ChangePassword from "./password";
import Notifications from "./notifications";
import Profile from "./profile";
import { WorkspaceDetails, WorkspaceMembers } from "./workspace";

function Settings() {
  const [activeTab, setActiveTab] = useState("profile");
  const [isTabOpen, setIsTabOpen] = useState(true);
  const navigate = useNavigate();

  const settingsTabs = useMemo(() => {
    return [
      { label: "Profile", tab: "profile" },
      { label: "Password", tab: "password" },
      { label: "Notification", tab: "notification" },
      {
        label: "Workspace",
        tab: "workspace",
        initiallyOpen: isTabOpen,
        children: [
          { label: "Details", tab: "details" },
          { label: "Members", tab: "members" },
        ],
      },
    ];
  }, [isTabOpen]);

  useEffect(() => {
    const currentTab = new URLSearchParams(window.location.search).get("tab");

    if (currentTab) {
      setActiveTab(currentTab);
      navigate(`?tab=${currentTab}`);
    }
    if (!currentTab) {
      setActiveTab("profile");
      navigate("?tab=profile");
    }
  }, [navigate]);

  const handleTabClick = useCallback(
    (tab: string) => {
      setActiveTab(tab);
      navigate(`?tab=${tab}`);
    },
    [navigate]
  );

  const handleChildTabClick = useCallback(
    (parentTab: string, childTab: string) => {
      const tab = `${parentTab}-${childTab}`;
      setActiveTab(tab);
      navigate(`?tab=${tab}`);
    },
    [navigate]
  );

  const handleToggleOpen = useCallback(() => {
    setIsTabOpen(!isTabOpen);
  }, [isTabOpen]);

  return (
    <UserLayout title="Settings">
      <Box
        py={"lg"}
        style={{
          backgroundColor: "#FFF",
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          borderRadius: "16px",
          boxShadow: "2px 2px 16px 0px #00000014",
        }}
      >
        <Box
          style={{
            backgroundColor: "#FFF",
            display: "flex",
            width: "100%",
            maxWidth: "1000px",
            gap: 84,
          }}
        >
          <Box
            p={"md"}
            style={{
              borderRight: "none",
              width: "160px",
              height: "100%",
            }}
          >
            {settingsTabs.map((tab, index) =>
              tab.children ? (
                <div>
                  <NavLink
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={handleToggleOpen}
                    label={tab.label}
                    key={index}
                    variant="default"
                    type="button"
                  />
                  {tab.initiallyOpen && (
                    <div
                      style={{
                        marginLeft: "2em",
                      }}
                    >
                      {tab.children.map((childTab, index) => (
                        <NavLink
                          onClick={() =>
                            handleChildTabClick(tab.tab, childTab.tab)
                          }
                          key={index}
                          label={childTab.label}
                          active={`${tab.tab}-${childTab.tab}` === activeTab}
                          style={{ marginBottom: "0.5em", borderRadius: "5px" }}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <NavLink
                  onClick={() => handleTabClick(tab.tab)}
                  key={index}
                  label={tab.label}
                  active={tab.tab === activeTab}
                  style={{ marginBottom: "0.5em", borderRadius: "5px" }}
                />
              )
            )}
          </Box>
          <Box style={{ flex: 1 }} p="md">
            <>
              {activeTab === "profile" && <Profile />}
              {activeTab === "password" && <ChangePassword />}
              {activeTab === "notification" && <Notifications />}
              {activeTab === "workspace-details" && <WorkspaceDetails />}
              {activeTab === "workspace-members" && <WorkspaceMembers />}
            </>
          </Box>
        </Box>
      </Box>
    </UserLayout>
  );
}

export default React.memo(Settings);
