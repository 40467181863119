import { Table, Text } from "@mantine/core";
import PropTypes from "prop-types";

function TransparentTable({
  labels,
  values,
}: PropTypes.InferProps<typeof TransparentTable.propTypes>) {
  if (
    !Array.isArray(labels) ||
    !Array.isArray(values) ||
    labels.length !== values.length
  ) {
    throw new Error("Labels and values must be arrays of the same length.");
  }
  return (
    <Table>
      <Table.Tbody>
        {labels.map((label, index) => (
          <Table.Tr key={index} style={{ border: "none" }}>
            <Table.Td>
              <Text fw={400} size="sm" c={"colors.light-grey.8"}>
                {label}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text size="sm" fw={600} c={"dark.4"}>
                {values[index]}
              </Text>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
}

TransparentTable.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  ).isRequired,
};

export default TransparentTable;
