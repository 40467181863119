import { Group, Text, UnstyledButton } from "@mantine/core";
import { ReactNode } from "react";
import { PdfIcon } from "../../../../assets/icons/file";

interface IRequestDataLayout {
  label: string;
  data: ReactNode;
}

const RequestDataLayout = ({ label, data }: IRequestDataLayout) => {
  return (
    <Group justify="space-between" gap={5}>
      <Text size="sm" c={"dark.3"}>
        {label}
      </Text>
      <Text fw={500} size="sm" c={"dark.6"}>
        {data}
      </Text>
    </Group>
  );
};

export const PDFDownloadButton = (props: any) => {
  return (
    <UnstyledButton {...props}>
      <Group gap={"xs"}>
        <PdfIcon width={12} />
        <Text size="sm" fw={600} c={"dark.6"}>
          {props?.children ?? "Download"}
        </Text>
      </Group>
    </UnstyledButton>
  );
};

export default RequestDataLayout;
