import {
  Button,
  Container,
  SimpleGrid,
  Stack,
  Divider,
  Box,
  Title,
  em,
  Group,
} from "@mantine/core";
import { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import GreyBackground from "../backgrounds/greyBackground";
import PriceCard from "../cards/priceCard";
import WaecImg from "../../assets/images/waec-card.png";
import UniversityImg from "../../assets/images/university-card.png";
import NecoImg from "../../assets/images/neco-card.png";
import LandingSectionHeader from "../sectionHeading/landing";
import { certificates } from "./certificateCards";

// const certificates = [
//   {
//     ServiceName: "Waec",
//     Price: 8000,
//     Duration: "Duration 2-4 working days",
//     Description: "Verify the validity of a potential employee's",
//     Image: WaecImg,
//     Link: "/about",
//   },
//   {
//     ServiceName: "University Degree",
//     Price: 8000,
//     Duration: "Duration 2-4 working days",
//     Description: "Verify the validity of a potential employee's",
//     Image: UniversityImg,
//     Link: "/about",
//   },
//   {
//     ServiceName: "Neco Result",
//     Price: 8000,
//     Duration: "Duration 2-4 working days",
//     Description: "Verify the validity of a potential employee's",
//     Image: NecoImg,
//     Link: "/about",
//   },
//   {
//     ServiceName: "Neco Result",
//     Price: 8000,
//     Duration: "Duration 2-4 working days",
//     Description: "Verify the validity of a potential employee's",
//     Image: NecoImg,
//     Link: "/about",
//   },
//   {
//     ServiceName: "University Degree",
//     Price: 8000,
//     Duration: "Duration 2-4 working days",
//     Description: "Verify the validity of a potential employee's",
//     Image: UniversityImg,
//     Link: "/about",
//   },
//   {
//     ServiceName: "Waec",
//     Price: 8000,
//     Duration: "Duration 2-4 working days",
//     Description: "Verify the validity of a potential employee's",
//     Image: WaecImg,
//     Link: "/about",
//   },
// ];

const documents = [
  {
    ServiceName: "Voter's Card",
    Price: 8000,
    Duration: "Duration 2-4 working days",
    Description: "Verify the validity of a potential employee's",
    Image: WaecImg,
    Link: "/about",
  },
  {
    ServiceName: "International Passport",
    Price: 8000,
    Duration: "Duration 2-4 working days",
    Description: "Verify the validity of a potential employee's",
    Image: UniversityImg,
    Link: "/about",
  },
  {
    ServiceName: "Driver's License",
    Price: 8000,
    Duration: "Duration 2-4 working days",
    Description: "Verify the validity of a potential employee's",
    Image: NecoImg,
    Link: "/about",
  },
  {
    ServiceName: "Neco Result",
    Price: 8000,
    Duration: "Duration 2-4 working days",
    Description: "Verify the validity of a potential employee's",
    Image: NecoImg,
    Link: "/about",
  },
  {
    ServiceName: "National Identification Number (NIN)",
    Price: 8000,
    Duration: "Duration 2-4 working days",
    Description: "Verify the validity of a potential employee's",
    Image: UniversityImg,
    Link: "/about",
  },
];

function Services() {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const [isCertificateOpen, setIsCertificateOpen] = useState(true);

  return (
    <GreyBackground>
      <Container size={"lg"}>
        <Stack align="center">
          <LandingSectionHeader
            headerText="Certificate Verification Services"
            subText="Our solution stands out with its versatility, accommodating various "
          />
          <Box
            pl={{ base: "", sm: "4em" }}
            pr={{ base: "", sm: "4em" }}
            size={"lg"}
            w={"100%"}
          >
            <Group mt={"5em"} justify={isMobile ? "space-between" : ""}>
              <Stack>
                <Button
                  bg={"none"}
                  c={isCertificateOpen ? "orange" : "dark.5"}
                  onClick={() => {
                    setIsCertificateOpen(true);
                  }}
                >
                  <Title fz={{ base: "0.825em", sm: "1.3em" }}>
                    Certificate Verification
                  </Title>
                </Button>
                {isCertificateOpen && (
                  <Divider color={"orange"} w={"100%"} size={4} />
                )}
              </Stack>

              <Stack>
                <Button
                  bg={"none"}
                  c={isCertificateOpen ? "dark.5" : "orange"}
                  onClick={() => {
                    setIsCertificateOpen(false);
                  }}
                >
                  <Title fz={{ base: "0.825em", sm: "1.3em" }}>
                    Document Verification
                  </Title>
                </Button>
                {!isCertificateOpen && (
                  <Divider color={"orange"} w={"100%"} size={4} />
                )}
              </Stack>
            </Group>
            <Divider c={"gray"} w={"100%"} size={1} />
          </Box>
          <SimpleGrid
            cols={{ base: 1, sm: 2, md: 3 }}
            spacing={"xl"}
            mt={50}
            mb={32}
          >
            {isCertificateOpen
              ? certificates.map((certificate, index) => (
                  <PriceCard
                    key={index}
                    actionTexts={[
                      certificate.Duration,
                      certificate.Description,
                    ]}
                    imgSrc={certificate.Image}
                    serviceType={certificate.ServiceName}
                    price={{ currency: "N", amount: certificate.Price }}
                    to={certificate.Link}
                  />
                ))
              : documents.map((certificate, index) => (
                  <PriceCard
                    key={index}
                    actionTexts={[
                      certificate.Duration,
                      certificate.Description,
                    ]}
                    imgSrc={certificate.Image}
                    serviceType={certificate.ServiceName}
                    price={{ currency: "N", amount: certificate.Price }}
                    to={certificate.Link}
                  />
                ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </GreyBackground>
  );
}

export default Services;
