import { Group, Text } from "@mantine/core";
import { Button } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import LessThanIcon from "../../../assets/svg/lessThanIcon.svg";
import GreaterThanIcon from "../../../assets/svg/greaterThanIcon.svg";
import useResponsive from "../../../../hooks/useResponsive";

function PaginationComponent({
  currentPage,
  totalPages,
  setPage,
}: PropTypes.InferProps<typeof PaginationComponent.propTypes>) {
  const { isMobile, isTablet, isLaptop } = useResponsive();
  const [hovered, setHovered] = useState<number | null>(null);
  const navigate = useNavigate();
  const handleChangePage = (page: number) => {
    setPage(page);
    navigate(`?page=${page}`);
  };

  const handleGoBack = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
      navigate(`?page=${currentPage - 1}`);
    }
  };

  const handleGoForward = () => {
    if (currentPage < totalPages) {
      setPage(currentPage + 1);
      navigate(`?page=${currentPage + 1}`);
    }
  };

  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = isMobile ? 3 : isTablet ? 5 : isLaptop ? 7 : 10;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Text
          onMouseEnter={() => setHovered(i)}
          onMouseLeave={() => setHovered(null)}
          bg={
            hovered === i
              ? "orange.1"
              : i === currentPage
              ? "light-grey.4"
              : "transparent"
          }
          c={i === currentPage ? "orange.4" : "light-grey.8"}
          fw={400}
          p={"0.1em 0.5em"}
          key={i}
          style={{
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleChangePage(i)}
        >
          {i}
        </Text>
      );
    }
    return pages;
  };

  useEffect(() => {
    const currentPage = new URLSearchParams(window.location.search).get("page");
    if (currentPage) {
      setPage(Number(currentPage));
    }
  }, [setPage]);

  return (
    // <Group justify={isTablet ? "center" : "space-between"} align="center">
    <Group justify={"space-between"} align="center">
      {/* {!isTablet && ( */}
      <Text c={"light-grey.8"}>
        {/* Showing {currentPage} to {totalPages} of {totalPages} */}
      </Text>
      {/* // )} */}
      <Group gap={"0.5em"} align="center">
        <Button
          p={"0.5em 1.5em"}
          disabled={currentPage === 1}
          variant="default"
          c={"white"}
          onClick={handleGoBack}
          style={{
            border: "none",
          }}
        >
          <img src={LessThanIcon} alt="LessThanIcon" />
        </Button>
        {renderPagination()}
        <Button
          p={"0.5em 1.5em"}
          disabled={currentPage === totalPages}
          variant="default"
          onClick={handleGoForward}
          style={{
            border: "none",
          }}
        >
          <img src={GreaterThanIcon} alt="GreaterThanIcon" />
        </Button>
      </Group>
      {/* {!isLaptop && ( */}

      <Text c={"dark.4"} fw={600}>
        {/* Showing Entries {currentPage} */}
      </Text>
      {/* // )} */}
    </Group>
  );
}

PaginationComponent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default PaginationComponent;
