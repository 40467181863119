import { Text } from "@mantine/core";
import Skeleton from "react-loading-skeleton";
const FormLoader = ({
  count = 7,
  title,
}: {
  count?: number;
  title: string;
}) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            padding: "2.5em",
            marginBottom: "2em",
            maxWidth: "500px",
            width: "100%",
            boxShadow: " 0px 4px 16px 0px #0000000D",
            border: "1.30px solid #E8E8E8",
            borderRadius: "2em",
          }}
        >
          <Text fw={600} mb={"1em"} fz={"24px"}>
            {title}
          </Text>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "45%", marginBottom: "1em" }}>
              <Skeleton height={40} />
            </div>
            <div style={{ width: "45%" }}>
              <Skeleton height={40} />
            </div>
          </div>

          {Array(count)
            .fill(0)
            .map(() => (
              <div style={{ width: "100%", marginBottom: "1em" }}>
                <Skeleton height={40} />
              </div>
            ))}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "1em",
            }}
          >
            <div style={{ width: "60%" }}>
              <Skeleton height={40} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormLoader;
