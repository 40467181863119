import { ReactComponent as LinkedInIcon } from "../svg/linkedin-icon.svg";
import { ReactComponent as FacebookIcon } from "../svg/facebook-icon.svg";
import { ReactComponent as XIcon } from "../svg/X-icon.svg";
import { ReactComponent as InstagramIcon } from "../svg/instagram-icon.svg";
import { ReactComponent as ChevronDown } from "../svg/chevronDownIcon.svg";
import { ReactComponent as CheckIcon } from "../svg/check-icon.svg";

export {
  LinkedInIcon,
  FacebookIcon,
  XIcon,
  InstagramIcon,
  ChevronDown,
  CheckIcon,
};
