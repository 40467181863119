import * as Yup from "yup";
import { WorkspaceType, IWorkspaceType } from "../../enums/enums";

export const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .trim(),
  password: Yup.string().required("Password is required"),
});
export type SignInDto = Yup.InferType<typeof SignInSchema>;

export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
  code: Yup.string().optional(),
  userId: Yup.string().optional(),
});
export type ResetPasswordDto = Yup.InferType<typeof ResetPasswordSchema>;

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .trim(),
});
export type ForgotPasswordDto = Yup.InferType<typeof ForgotPasswordSchema>;

export const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .when("accountType", {
      is: (accountType: IWorkspaceType) => accountType === "PERSONAL",
      then: () => Yup.string().required("First Name is required"),
    }),
  lastName: Yup.string()
    .trim()
    .when("accountType", {
      is: (accountType: IWorkspaceType) => accountType === "PERSONAL",
      then: () => Yup.string().required("Last Name is required"),
    }),
  businessName: Yup.string()
    .trim()
    .when("accountType", {
      is: (accountType: IWorkspaceType) => accountType === "CORPORATE",
      then: () => Yup.string().required("Business Name is required"),
    })
    .optional(),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .min(6, "Password must be at least 6 characters")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  phoneNo: Yup.string().required("Phone Number is required").trim().min(11),
  country: Yup.string().required("Country is required").trim(),
  state: Yup.string().required("State is required").trim(),
  city: Yup.string().trim(),
  accountType: Yup.string()
    .trim()
    .oneOf(Object.values(WorkspaceType))
    .required("Account Type is required"),
  acceptTerms: Yup.boolean()
    .oneOf([true], "Terms and Conditions must be accepted")
    .required("Terms and Conditions must be accepted"),
});
export type SignUpDto = Yup.InferType<typeof SignUpSchema>;
