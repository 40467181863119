import API from "../api/api";
import { useState, useEffect } from "react";
import { useMutation } from "react-query";

const URL = "/upload";

export const useUploadFile = () => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("idle");
  const controller = new AbortController();

  const {
    mutate,
    status: axiosStatus,
    isLoading,
    data,
    error,
    mutateAsync,
  } = useMutation((data: any) => {
    return API.post(`${URL}`, data, {
      headers: { "content-type": "multipart/form-data" },
      signal: controller.signal,
      timeout: 600000,
      onUploadProgress: (event: any) => {
        setProgress(Math.round((event?.loaded * 100) / event?.total || 1));
      },
    });
  });

  useEffect(() => {
    if (axiosStatus === "loading") {
      setStatus("loading");
    } else if (axiosStatus === "success") {
      setStatus("success");
    } else if (axiosStatus === "error") {
      setStatus("error");
    } else if (axiosStatus === "idle") {
      setStatus("idle");
    }
  }, [isLoading, error, progress, axiosStatus, status]);

  const cancelUpload = () => {
    controller.abort();
    setStatus("error");
  };

  return {
    mutate,
    status,
    isLoading,
    data,
    error,
    mutateAsync,
    progress,
    setStatus,
    cancel: cancelUpload,
  };
};
