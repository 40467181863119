import { Container, Flex, Group, Image, Stack } from "@mantine/core";
import LandingSectionHeader from "../sectionHeading/landing";
import AccessImage from "../../assets/images/access-logo.png";
// import Eagric from "../../assets/images/eagric-logo.png";
import ZenithImage from "../../assets/images/zenith-logo.png";
// import FirstCentralImage from "../../assets/images/first-central-logo.png";
import WhiteSoulImage from "../../assets/images/white-soul-logo.png";
import FidelityImage from "../../assets/images/fidelity-logo.svg";
import LeadingPLCImage from "../../assets/images/leasing.png";
import FemabImage from "../../assets/images/femab.png";
import TariusImage from "../../assets/images/tarius.png";
import StanBicBankImage from "../../assets/images/stanbic-IBTC-logo.jpg";
import useResponsive from "../../../hooks/useResponsive";

import classes from "./css.module.css";

const sponsors = [
  {
    name: "Stanbic Bank",
    image: StanBicBankImage,
  },
  {
    name: "Access Bank",
    image: AccessImage,
  },
  {
    name: "Zenith Bank",
    image: ZenithImage,
  },

  {
    name: "Fidelity Bank",
    image: FidelityImage,
  },
  {
    name: "Leading PLC",
    image: LeadingPLCImage,
  },
  {
    name: "White Soul Group",
    image: WhiteSoulImage,
  },
  {
    name: "Femab",
    image: FemabImage,
  },
  {
    name: "Tarius",
    image: TariusImage,
  },

  // {
  //   name: "Learning PLC",
  //   image: Eagric,
  // },

  // {
  //   name: "First Central Credit Bureau",
  //   image: FirstCentralImage,
  // },
];

function ReputableBrands() {
  const { isMobile } = useResponsive();
  return (
    <div
      style={{
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container size={"lg"}>
        <LandingSectionHeader headerText="Trusted by Reputable Brands" />
        <Group
          style={{
            overflow: "hidden",
          }}
          wrap="nowrap"
        >
          <Group
            wrap="nowrap"
            gap={50}
            style={{
              overflow: "hidden",
              position: "relative",
            }}
            mt={"2em"}
          >
            {sponsors.map((sponsor, index) => (
              <div className={classes.slideLeft}>
                <Flex key={index} align="center" justify="center" h={80}>
                  <Image
                    w={isMobile ? 100 : 200}
                    src={sponsor.image}
                    alt={sponsor.name}
                  />
                </Flex>
              </div>
            ))}
          </Group>
        </Group>
      </Container>
    </div>
  );
}

export default ReputableBrands;
