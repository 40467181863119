import API from "../../api/api";
import { toast } from "react-toastify";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";

const URL = "/service";
const admiinService = "admin/services";

export const useGetAllServicesByAdmin = (page: number, limit: number) => {
  const getAllServices = async () => {
    const response = await API.get(
      `${admiinService}?page=${page}&limit=${limit}`
    );
    return response;
  };
  const query = useQuery({
    queryFn: getAllServices,
    queryKey: ["GetAllServices", page, limit],
  });

  return query;
};

export const useGetServiceById = (serviceId: string) => {
  const getServiceById = async () => {
    const response = await API.get(`${admiinService}/${serviceId}`);
    return response;
  };
  const query = useQuery({
    queryFn: getServiceById,
    queryKey: [`GetServiceById-${serviceId}`],
  });

  return query;
};

export const useUpdateServiceById = (serviceId: string) => {
  const queryClient = useQueryClient();
  const updateServiceById = async (dto: any) => {
    const response = await API.put(`${admiinService}/update/${serviceId}`, dto);
    return response;
  };
  const mutation = useMutation({
    mutationFn: updateServiceById,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["GetAllServices"],
      });
      queryClient.invalidateQueries({
        queryKey: [`GetServiceById-${serviceId}`],
      });
      toast.success("Service updated successfully");
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });

  return mutation;
};

export const useDeactivateService = ({ serviceId }: { serviceId: string }) => {
  const queryClient = useQueryClient();
  const deactivateService = async () => {
    const response = await API.patch(`${URL}/deactivate/${serviceId}`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: deactivateService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["GetAllServices"],
      });
      queryClient.invalidateQueries({
        queryKey: [`GetServiceById-${serviceId}`],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });

  return mutation;
};

export const useActivateService = ({ serviceId }: { serviceId: string }) => {
  const queryClient = useQueryClient();
  const activateService = async () => {
    const response = await API.patch(`${URL}/activate/${serviceId}`);
    return response;
  };
  const mutation = useMutation({
    mutationFn: activateService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["GetAllServices"],
      });
      queryClient.invalidateQueries({
        queryKey: [`GetServiceById-${serviceId}`],
      });
    },
  });

  return mutation;
};
