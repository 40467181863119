import { Box, Text, Stack } from "@mantine/core";
const PrivacyPolicy = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <Box pr={isMobile ? 0 : 80}>
      <Text
        mb={24}
        style={{
          fontWeight: 700,
          fontSize: isMobile ? "24px" : "36px",
          lineHeight: isMobile ? "32px" : "44px",
        }}
      >
        Privacy Policy
      </Text>
      <Stack gap={16}>
        <Box>
          <Text fw={600} mb={8}>
            1. Introduction{" "}
          </Text>
          <Text c="#364152" ta="justify">
            Welcome to Kpali.ng.
            <br />
            <br />
            Kpali (“us”, “we”, or “our”) operates Kpali.ng (referred to as
            “Service”).
            <br />
            <br />
            Our Privacy Policy governs your visit to Kpali.ng, and explains how
            we collect, safeguard and disclose information that results from
            your use of our Service.
            <br />
            <br />
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions.
            <br />
            <br />
            Our Terms and Conditions (“Terms”) govern all use of our Service and
            together with the Privacy Policy constitutes your agreement with us
            (“agreement”).
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            2. Definitions
          </Text>
          <Text c="#364152" ta="justify">
            <strong>SERVICE </strong> means the kpali.ng website operated by
            Kpali.
            <br />
            <br />
            <strong>PERSONAL DATA </strong> means data about a living individual
            who can be identified from data (or from those and other information
            either in our possession or likely to come into our possession).
            <br />
            <br />
            <strong> USAGE DATA </strong> is data collected automatically either
            generated by the use of Service or from Service infrastructure
            itself (for example, the duration of a page visit).
            <br />
            <br />
            <strong> COOKIES </strong>are small files stored on your device
            (computer or mobile device).
            <br />
            <br />
            <strong> DATA CONTROLLER </strong> means a natural or legal person
            who (either alone or jointly or in common with other persons)
            determines the purposes for which and the manner in which any
            personal data are, or are to be, processed. For the purpose of this
            Privacy Policy, we are a Data Controller of your data.
            <br />
            <br />
            <strong> DATA PROCESSORS (OR SERVICE PROVIDERS) </strong> means any
            natural or legal person who processes the data on behalf of the Data
            Controller. We may use the services of various Service Providers in
            order to process your data more effectively.
            <br />
            <br />
            <strong> DATA SUBJECT </strong> is any living individual who is the
            subject of Personal Data.
            <br />
            <br />
            <strong> THE USER </strong> is the individual using our Service. The
            User corresponds to the Data Subject, who is the subject of Personal
            Data.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            3. Information Collection and Use
          </Text>
          <Text c="#364152" ta="justify">
            We collect several different types of information for various
            purposes to provide and improve our Service.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            4. Types of Data Collected
          </Text>

          <Text fw={600} mb={8}>
            Personal Data
          </Text>
          <Text c="#364152" ta="justify">
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”).
            <br />
            Personally identifiable information may include, but is not limited
            to:
            <Text my={8}>0.1. Email address</Text>
            <Text mb={8}>0.2. First name and last name</Text>
            <Text my={8}> 0.3. Phone number</Text>
            <Text mb={8}>
              0.4. Address, Country, State, ZIP/Postal code, City.
            </Text>
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by clicking on the unsubscribe
            button/link.
            <br />
            <br />
            <strong>Usage Data</strong>
            <br />
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through any
            device (“Usage Data”).
            <br />
            <br />
            This Usage Data may include information such as your computer’s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
            <br />
            <br />
            When you access Service with a device, this Usage Data may include
            information such as the type of device you use, your device unique
            ID, the IP address of your device, your device operating system, the
            type of Internet browser you use, unique device identifiers and
            other diagnostic data.
            <br />
            <br />
            <strong>Tracking Cookies Data</strong>
            <br />
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
            <br />
            <br />
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
            <br />
            <br />
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
            <br />
            <br />
            <strong>Examples of Cookies we use:</strong>
            <Text my={8}>
              0.1. Session Cookies: We use Session Cookies to operate our
              Service.
            </Text>
            <Text mb={8}>
              0.2. Preference Cookies: We use Preference Cookies to remember
              your preferences and various settings.
            </Text>
            <Text my={8}>
              {" "}
              0.3. Security Cookies: We use Security Cookies for security
              purposes.
            </Text>
            <strong> Other Data </strong>
            <br /> While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, passport
            details, citizenship, place of residence and actual address,
            telephone number (work, mobile), details of documents on education,
            qualification, professional training, employment agreements,{" "}
            <a
              href="https://policymaker.io/non-disclosure-agreement/"
              target="_blank"
              rel="noreferrer"
            >
              NDA agreements
            </a>
            , information on bonuses and compensation, information on marital
            status, family members, office location and other data.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            5. Use of Data
          </Text>
          <Text c="#364152" ta="justify">
            Kpali.ng uses the collected data for various purposes:
            <Text my={8}>0.1. to provide and maintain our Service;</Text>
            <Text mb={8}>0.2. to notify you about changes to our Service;</Text>
            <Text my={8}>
              0.3. to allow you to use our Service when you choose to do so;
            </Text>
            <Text my={8}>0.4. to provide customer support;</Text>
            <Text my={8}>
              0.5. to gather analysis or valuable information so that we can
              improve our Service;
            </Text>
            <Text my={8}>0.6. to monitor the usage of our Service;</Text>
            <Text my={8}>
              0.7. to carry out our obligations and enforce our rights arising
              from any contracts entered into between you and us, including for
              billing and collection;
            </Text>
            <Text my={8}>
              0.8. to provide you with news, special offers and general
              information about services and events which we offer that are
              similar to those that you have already purchased or enq
            </Text>
            <Text my={8}>0.9. for any other purpose with your consent.</Text>
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            6. Retention of Data
          </Text>
          <Text c="#364152" ta="justify">
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
            <br />
            <br />
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            7. Disclosure of Data
          </Text>
          <Text c="#364152" ta="justify">
            We may disclose personal information that we collect, or you
            provide:
            <Text my={8}>
              <strong>0.1 Business Transaction.</strong>
            </Text>
            If we or our subsidiaries are involved in a merger, acquisition or
            asset sale, your Personal Data may be transferred.
            <Text my={8}>
              <strong>Other Cases.</strong>
            </Text>
            <Text mb={8}>We may disclose your information also:</Text>
            <Text my={8}>
              0.2.1. to contractors, service providers, and other third parties
              we use to support our business;
            </Text>
            <Text my={8}>
              0.2.2. to fulfill the purpose for which you provide it;
            </Text>
            <Text my={8}>
              0.2.3. for any other purpose disclosed by us when you provide the
              information;
            </Text>
            <Text my={8}> 0.2.4. with your consent in any other cases;</Text>
            <Text my={8}>
              0.2.5. if we believe disclosure is necessary or appropriate to
              protect the rights, property, or safety of the Company, our
              customers, or others.
            </Text>
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            8. Security of Data
          </Text>
          <Text c="#364152" ta="justify">
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            9. Service Providers
          </Text>
          <Text c="#364152" ta="justify">
            {" "}
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), provide Service on our behalf,
            perform Service-related services or assist us in analysing how our
            Service is used.
            <br />
            <br />
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            10. Analytics
          </Text>
          <Text c="#364152" ta="justify">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            11. Behavioral Remarketing
          </Text>
          <Text c="#364152" ta="justify">
            We may use remarketing services to advertise on third party websites
            to you after you visited our Service. We and our third-party vendors
            use cookies to inform, optimise and serve ads based on your past
            visits to our Service.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            12. Payments
          </Text>
          <Text c="#364152" ta="justify">
            We provide paid services within Service. In that case, we use
            third-party services for payment processing (e.g. payment
            processors).
            <br />
            <br />
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Verve and
            Mastercard. PCI-DSS requirements help ensure the secure handling of
            payment information.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            8. Changes to This Privacy Policy{" "}
          </Text>
          <Text c="#364152" ta="justify">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            <br />
            <br />
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
            <br />
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            14. Contact Us
          </Text>
          <Text c="#364152" ta="justify">
            If you have any questions about this Privacy Policy, please contact
            us by email: info@kpali.ng.
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default PrivacyPolicy;
