import { useState } from "react";
import { Text } from "@mantine/core";
import AdminLayout from "../../../../layouts/adminLayout";
import { ActitonButton } from "../users/users";
import { CustomCheckBox, CustomSearchInput } from "../../../shared/inputs";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
import CustomTable from "../../../shared/table/customTable";
import { useGetAvailableAgents } from "../../../../../hooks/admin/agents.hook";

function Agents() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetAvailableAgents(page, 10, search);
  const availableAgents = data?.data?.data;
  const availableAgentsCount = data?.data?.count;
  const availableAgentsTotalPages = data?.data?.totalPages;
  const availableAgentsCurrentPage = data?.data?.currentPage;

  const TableData = availableAgents?.map((agent: any, index: number) => {
    return {
      checbox: <CustomCheckBox />,
      name: agent?.businessName || agent?.firstName + " " + agent?.lastName,
      email: agent?.email,
      phone: agent?.phoneNo,
      state: agent?.state?.split(",")[1],
      action: <ActitonButton />,
    };
  });

  return (
    <AdminLayout title="Agents">
      <CustomTable
        title={"Available Agents"}
        rightItem={
          <CustomSearchInput
            setSearch={setSearch}
            placeholder={"Serch by name, state, email, phone number"}
          />
        }
        isTableLoading={isLoading}
        currentPage={availableAgentsCurrentPage}
        totalPages={availableAgentsTotalPages}
        setPage={setPage}
        count={availableAgentsCount}
        bodyRows={TableData}
        minWidth={"850px"}
        labels={[
          <CustomCheckBox />,
          "Name",
          "Email",
          "Phone",
          "State",
          "Action",
        ]}
        emptyState={{
          title: "No Agent Found",
          description:
            "They are currently no agents available on Kpali, please onboard new agents to get started.",
          image: GhostIcon,
          height: "100px",
          width: "175px",
        }}
      />
    </AdminLayout>
  );
}

export default Agents;
