import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import styles from "./form.module.css";

function GetInTouchSimple() {
  const form = useForm({
    initialValues: {
      firstname: "",
      phonenumber: "",
      subject: "",
      message: "",
    },
    validate: {
      firstname: (value: string) => value.trim().length < 2,
      phonenumber: (value: string) =>
        !/^(080|081|090|070|091)\d{8}$/.test(value.replace("+234", "0")),
      subject: (value: string) => value.trim().length === 0,
      message: (value: string) => value.trim().length === 0,
    },
  });

  return (
    <form
      style={{
        flex: 1,
        backgroundColor: "white",
        padding: "2.5em",
        borderRadius: "1em",
        border: "1px #E8E8E8 solid",
      }}
      onSubmit={form.onSubmit(() => {})}
    >
      <Title order={2} size="h3" fw={900} ta="left">
        Send Message
      </Title>

      <TextInput
        placeholder="Firstname"
        name="firstname"
        mt={{ base: "md", sm: "md" }}
        required
        classNames={{ input: styles.textarea }}
        {...form.getInputProps("firstname")}
      />

      <SimpleGrid cols={{ base: 1, sm: 2 }} mt={"sm"}>
        <TextInput
          placeholder="Phone number"
          name="phonenumber"
          required
          error={form.errors.phonenumber && "Invalid email"}
          classNames={{ input: styles.textarea }}
          {...form.getInputProps("phonenumber")}
        />
        <TextInput
          placeholder="Subject"
          name="subject"
          required
          classNames={{ input: styles.textarea }}
          {...form.getInputProps("subject")}
        />
      </SimpleGrid>

      <Textarea
        mt="md"
        placeholder="Message"
        name="message"
        maxRows={10}
        minRows={5}
        autosize
        required
        classNames={{ input: styles.textarea }}
        {...form.getInputProps("message")}
      />

      <Group justify="start" mt="xl">
        <Button type="submit" size="md">
          Send message
        </Button>
      </Group>
    </form>
  );
}

export default GetInTouchSimple;
