import { Container, Image, Accordion, Box } from "@mantine/core";
import OrangeArrowIcon from "../../assets/svg/orangeArrowIcon.svg";
import LandingSectionHeader from "../sectionHeading/landing";

const data = [
  {
    question: "We prioritize accuracy and reliability, working to thwart any ",
    answer:
      "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.",
  },
  {
    question: "We prioritize accuracy and reliability, working to thwart any ",
    answer:
      "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.",
  },
  {
    question: "We prioritize accuracy and reliability, working to thwart any ",
    answer:
      "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.",
  },
  {
    question: "We prioritize accuracy and reliability, working to thwart any ",
    answer:
      "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.",
  },
  {
    question: "We prioritize accuracy and reliability, working to thwart any ",
    answer:
      "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.",
  },
  {
    question: "We prioritize accuracy and reliability, working to thwart any ",
    answer:
      "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.",
  },
  {
    question: "We prioritize accuracy and reliability, working to thwart any ",
    answer:
      "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.",
  },
];

function FaqQuestions() {
  return (
    <Box pb={{ base: "2em", sm: "5em" }}>
      <Container size="lg">
        <LandingSectionHeader headerText="Frequently asked questions (FAQs)" />
        <Accordion
          mt={{ base: "2em", sm: "5em" }}
          //   ml={{ base: "2em", sm: "5em" }}
          variant="separated"
          chevronPosition="right"
          //   disableChevronRotation
          chevron={<Image w={"20"} h={"13"} src={OrangeArrowIcon} />}
        >
          {data.map((item, index) => (
            <Accordion.Item
              value={index.toString()}
              key={index}
              pl={"1em"}
              bg={"white"}
              p={"0.5em"}
              style={{ border: "1px #D3D0D0 solid" }}
            >
              <Accordion.Control>{item.question}</Accordion.Control>
              <Accordion.Panel>{item.answer}</Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </Box>
  );
}

export default FaqQuestions;
