import { Box, Text, Stack } from "@mantine/core";
const TOS = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <Box pr={isMobile ? 0 : 80}>
      <Text
        mb={24}
        style={{
          fontWeight: 700,
          fontSize: isMobile ? "24px" : "36px",
          lineHeight: isMobile ? "32px" : "44px",
        }}
      >
        TERMS AND CONDITIONS
      </Text>
      <Stack gap={16}>
        <Box>
          <Text fw={600} mb={8}>
            1. Introduction
          </Text>
          <Text c="#364152" ta="justify">
            Welcome to Kpali.ng (“Company”, “we”, “our”, “us”)! These Terms of
            Service (“Terms”, “Terms of Service”) govern your use of our website
            kpali.ng (together or individually “Service”) operated by Kpali.ng.{" "}
            <br />
            <br />
            Our Privacy Policy also governs your use of our Service and explains
            how we collect, safeguard and disclose information that results from
            your use of our web pages.
            <br />
            <br />
            Your agreement with us includes these Terms and our Privacy Policy
            (“Agreements”). You acknowledge that you have read and understood
            Agreements, and agree to be bound by them.
            <br />
            <br />
            If you do not agree with (or cannot comply with) Agreements, then
            you may not use the Service, but please let us know by emailing
            info@kpali.ng so we can try to find a solution. These Terms apply to
            all visitors, users and others who wish to access or use Service.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            2. Communications
          </Text>
          <Text c="#364152" ta="justify">
            By using our Service, you agree to subscribe to newsletters,
            marketing or promotional materials and other information we may
            send. However, you may opt out of receiving any, or all, of these
            communications from us by unsubscribing.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            3. Purchases
          </Text>
          <Text c="#364152" ta="justify">
            If you wish to purchase any service made available through Service
            (“Purchase”), you may be asked to supply certain information
            relevant to your Purchase including but not limited to, your credit
            or debit card number and the expiration date of your card.
            <br />
            <br />
            You represent and warrant that:
            <br />
            <br />
            (i) you have the legal right to use any card(s) or other payment
            method(s) in connection with any Purchase; and that
            <br />
            <br /> (ii) the information you supply to us is true, correct and
            complete.
            <br />
            <br /> We may employ the use of third party services for the purpose
            of facilitating payment and the completion of Purchases. By
            submitting your information, you grant us the right to provide the
            information to these third parties subject to our Privacy Policy.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            4. Contests, Sweepstakes and Promotions
          </Text>
          <Text c="#364152" ta="justify">
            Any promotions made available through Service may be governed by
            rules that are separate from these Terms of Service.
            <br />
            <br />
            If you participate in any Promotions, please review the applicable
            rules as well as our Privacy Policy. If the rules for a Promotion
            conflict with these Terms of Service, Promotion rules will apply.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            5. Content
          </Text>
          <Text c="#364152" ta="justify">
            Content found on or through this Service are the property of
            Kpali.ng or used with permission. You may not distribute, modify,
            transmit, reuse, download, copy, or use said Content, whether in
            whole or in part, for commercial purposes or for personal gain,
            without express advance written permission from us.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            6. Prohibited Uses
          </Text>
          <Text c="#364152" ta="justify">
            You may use Service only for lawful purposes and in accordance with
            Terms. You agree not to use Service:
            <br />
            <br />
            0.1. In any way that violates any applicable national or
            international law or regulation.
            <br />
            <br />
            0.2. For the purpose of exploiting, harming, or attempting to
            exploit or harm minors in any way by exposing them to inappropriate
            content or otherwise.
            <br />
            <br />
            0.3. To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail”, “chain letter,”
            “spam,” or any other similar solicitation.
            <br />
            <br />
            0.4. To impersonate or attempt to impersonate Company, a Company
            employee, another user, or any other person or entity.
            <br />
            <br />
            0.5. In any way that infringes upon the rights of others, or in any
            way is illegal, threatening, fraudulent, or harmful, or in
            connection with any unlawful, illegal, fraudulent, or harmful
            purpose or activity.
            <br />
            <br />
            0.6. To engage in any other conduct that restricts or inhibits
            anyone’s use or enjoyment of Service, or which, as determined by us,
            may harm or offend Company or users of Service or expose them to
            liability.
            <br />
            <br />
            <Text fw={600} mb={8}>
              <strong>Additionally, you agree not to:</strong>
            </Text>
            <br />
            {/* <br /> */}
            0.1. Use Service in any manner that could disable, overburden,
            damage, or impair Service or interfere with any other party’s use of
            Service, including their ability to engage in real time activities
            through Service.
            <br />
            <br />
            0.2. Use any robot, spider, or other automatic device, process, or
            means to access Service for any purpose, including monitoring or
            copying any of the material on Service.
            <br />
            <br />
            0.3. Use any manual process to monitor or copy any of the material
            on Service or for any other unauthorized purpose without our prior
            written consent.
            <br />
            <br />
            0.4. Use any device, software, or routine that interferes with the
            proper working of Service.
            <br />
            <br />
            0.5. Introduce any viruses, trojan horses, worms, logic bombs, or
            other material which is malicious or technologically harmful.
            <br />
            <br />
            0.6. Attempt to gain unauthorized access to, interfere with, damage,
            or disrupt any parts of Service, the server on which Service is
            stored, or any server, computer, or database connected to Service.
            <br />
            <br />
            0.7. Attack Service via a denial-of-service attack or a distributed
            denial-of-service attack.
            <br />
            <br />
            0.8. Take any action that may damage or falsify Company rating.
            <br />
            <br />
            0.9. Otherwise attempt to interfere with the proper working of
            Service.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            7. Analytics
          </Text>
          <Text c="#364152" ta="justify">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            8. Accounts
          </Text>
          <Text c="#364152" ta="justify">
            When you create an account with us, you guarantee that you are above
            the age of 18, and that the information you provide us is accurate,
            complete, and current at all times. Inaccurate, incomplete, or
            obsolete information may result in the immediate termination of your
            account on Service.
            <br />
            <br />
            You are responsible for maintaining the confidentiality of your
            account and password, including but not limited to the restriction
            of access to your computer and/or account. You agree to accept
            responsibility for any and all activities or actions that occur
            under your account and/or password, whether your password is with
            our Service or a third-party service. You must notify us immediately
            upon becoming aware of any breach of security or unauthorized use of
            your account.
            <br />
            <br />
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than you,
            without appropriate authorization. You may not use as a username any
            name that is offensive, vulgar or obscene.
            <br />
            <br />
            We reserve the right to refuse service, terminate accounts, remove
            or edit content, or cancel orders at our sole discretion.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            9. Intellectual Property
          </Text>
          <Text c="#364152" ta="justify">
            Service and its original content (excluding Content provided by
            users), features and functionality are and will remain the exclusive
            property of Kpali.ng and its licensors. Service is protected by
            copyright, trademark, and other laws of and foreign countries. Our
            trademarks may not be used in connection with any product or service
            without the prior written consent of Kpali.ng.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            10. Copyright Policy
          </Text>
          <Text c="#364152" ta="justify">
            We respect the intellectual property rights of others. It is our
            policy to respond to any claim that Content posted on Service
            infringes on the copyright or other intellectual property rights
            (“Infringement”) of any person or entity.
            <br />
            <br />
            If you are a copyright owner, or authorized on behalf of one, and
            you believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement, please submit your claim via
            email to info@kpali.ng, with the subject line: “Copyright
            Infringement” and include in your claim a detailed description of
            the alleged Infringement as detailed below, under “DMCA Notice and
            Procedure for Copyright Infringement Claims”
            <br />
            <br />
            You may be held accountable for damages (including costs and
            attorneys’ fees) for misrepresentation or bad-faith claims on the
            infringement of any Content found on and/or through Service on your
            copyright.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            11. Error Reporting and Feedback
          </Text>
          <Text c="#364152" ta="justify">
            You may provide us either directly at{" "}
            <a href="mailto:infor@kpali.ng">info@kpali.ng</a> or via third party
            sites and tools with information and feedback concerning errors,
            suggestions for improvements, ideas, problems, complaints, and other
            matters related to our Service (“Feedback”).
            <br />
            <br />
            You acknowledge and agree that:
            <br />
            <br />
            (i) you shall not retain, acquire or assert any intellectual
            property right or other right, title or interest in or to the
            Feedback;
            <br />
            <br />
            (ii) Company may have development ideas similar to the Feedback;
            <br />
            <br />
            (iii) Feedback does not contain confidential information or
            proprietary information from you or any third party; and
            <br />
            <br />
            (iv) Company is not under any obligation of confidentiality with
            respect to the Feedback.
            <br />
            <br />
            In the event the transfer of the ownership to the Feedback is not
            possible due to applicable mandatory laws, you grant Company and its
            affiliates an exclusive, transferable, irrevocable, free-of-charge,
            sub-licensable, unlimited and perpetual right to use (including
            copy, modify, create derivative works, publish, distribute and
            commercialize) Feedback in any manner and for any purpose.
            <br />
            <br />
            You acknowledge and agree that the company shall not be responsible
            or liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any such content, goods or services available on or through any such
            third party web sites or services.
            <br />
            <br />
            We strongly advise you to read the terms of service and privacy
            policies of any third party web sites or services that you visit.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            12. Disclaimer Of Warranty
          </Text>
          <Text c="#364152" ta="justify">
            These services are provided by the company on an “as is” and “as
            available” basis. company makes no representations or warranties of
            any kind, express or implied, as to the operation of their services,
            or the information, content or materials included therein. You
            expressly agree that your use of these services, their content, and
            any services or items obtained from us is at your sole risk.
            <br />
            <br />
            Neither company nor any person associated with the company makes any
            warranty or representation with respect to the completeness,
            security, reliability, quality, accuracy, or availability of the
            services. Without limiting the foregoing, neither company nor anyone
            associated with company represents or warrants that the services,
            their content, or any services or items obtained through the
            services will be accurate, reliable, error-free, or uninterrupted,
            that defects will be corrected, that the services or the server that
            makes it available are free of viruses or other harmful components
            or that the services or any services or items obtained through the
            services will otherwise meet your needs or expectations.
            <br />
            <br />
            Company hereby disclaims all warranties of any kind, whether express
            or implied, statutory, or otherwise, including but not limited to
            any warranties of merchantability, non-infringement, and fitness for
            particular purpose.
            <br />
            <br />
            The foregoing does not affect any warranties which cannot be
            excluded or limited under applicable law.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            13. Limitation Of Liability
          </Text>
          <Text c="#364152" ta="justify">
            Except as prohibited by law, you will hold us and our officers,
            directors, employees, and agents harmless for any indirect,
            punitive, special, incidental, or consequential damage, however it
            arises (including attorneys’ fees and all related costs and expenses
            of litigation and arbitration, or at trial or on appeal, if any,
            whether or not litigation or arbitration is instituted), whether in
            an action of contract, negligence, or other tortious action, or
            arising out of or in connection with this agreement, including
            without limitation any claim for personal injury or property damage,
            arising from this agreement and any violation by you of any federal,
            state, or local laws, statutes, rules, or regulations, even if
            company has been previously advised of the possibility of such
            damage. except as prohibited by law, if there is liability found on
            the part of the company, it will be limited to the amount paid for
            the products and/or services, and under no circumstances will there
            be consequential or punitive damages. Some states do not allow the
            exclusion or limitation of punitive, incidental or consequential
            damages, so the prior limitation or exclusion may not apply to you
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            14. Termination
          </Text>
          <Text c="#364152" ta="justify">
            If you wish to terminate your account, you may simply discontinue
            using Service.
            <br />
            <br />
            All provisions of Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            15. Governing Law
          </Text>
          <Text c="#364152" ta="justify">
            These Terms shall be governed and construed in accordance with the
            laws of Nigeria, which governing law applies to agreement without
            regard to its conflict of law provisions.
            <br />
            <br />
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service and supersede and replace any prior agreements we might have
            had between us regarding Service.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            16. Amendments To Terms
          </Text>
          <Text c="#364152" ta="justify">
            We may amend Terms at any time by posting the amended terms on this
            site. It is your responsibility to review these Terms periodically.
            Your continued use of the Platform following the posting of revised
            Terms means that you accept and agree to the changes. You are
            expected to check this page frequently so you are aware of any
            changes, as they are binding on you.
            <br />
            <br />
            By continuing to access or use our Service after any revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, you are no longer authorized to use
            Service.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            17. Acknowledgement
          </Text>
          <Text c="#364152" ta="justify">
            BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
            THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
            THEM.
          </Text>
        </Box>
        <Box>
          <Text fw={600} mb={8}>
            18. Contact Us
          </Text>
          <Text c="#364152" ta="justify">
            Please send your feedback, comments, requests for support by email:{" "}
            <a href="mailto:info@kpali.ng">info@kpali.ng</a>,{" "}
            <a href="support@kpali.ng">support@kpali.ng</a>,
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default TOS;
