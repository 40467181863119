import useResponsive from "../../../../../hooks/useResponsive";
function InsightWrapper({ children }: React.PropsWithChildren) {
  const { isMobile, isTablet } = useResponsive();
  const margin = isMobile ? "0.5em 0.25em" : isTablet ? "0.75em 0.5em" : "1rem";
  return (
    <div
      style={{
        margin,
        height: "100%",
        maxWidth: "100%",
        display: "flex",
        gap: "2em",
        justifyContent: "flex-start",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {children}
    </div>
  );
}

export default InsightWrapper;
