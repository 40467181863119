import "chart.js/auto";
import propTypes from "prop-types";
import { Chart } from "react-chartjs-2";
import { randomColor } from "../../../../../../constants/colors";

function BarChart({
  isLoading,
  labels,
  data,
  callback,
}: propTypes.InferProps<typeof BarChart.propTypes>) {
  const defaultBackgroundColor = ["#4E5D67", "#EB862A", "#287F71", "#ABBDD3"];

  if (labels.length > defaultBackgroundColor.length) {
    for (let i = defaultBackgroundColor.length; i < labels.length; i++) {
      defaultBackgroundColor.push(randomColor());
    }
  }

  return (
    <Chart
      type="bar"
      data={{
        labels,
        datasets: [
          {
            borderWidth: 1,
            borderRadius: 10,
            backgroundColor: defaultBackgroundColor,
            maxBarThickness: 60,
            data,
          },
        ],
      }}
      options={{
        plugins: {
          tooltip: {
            callbacks: {
              label: callback,
            },
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            suggestedMin: 0,
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
        },
      }}
    />
  );
}

BarChart.propTypes = {
  isLoading: propTypes.bool,
  labels: propTypes.array as propTypes.Validator<string[]>,
  data: propTypes.array,
  callback: propTypes.func.isRequired,
};

export default BarChart;
