import API from "../../api/api";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";

const URL = "/admin/agents/available";

export const useGetAvailableAgents = (
  page: number,
  limit: number,
  search: string = ""
) => {
  const getAvailableAgents = async () => {
    const response = await API.get(`${URL}?page=${page}&limit=${limit}
    &search=${search}
    `);
    return response;
  };
  const query = useQuery({
    queryFn: getAvailableAgents,
    queryKey: ["GetAvailableAgents", page, limit, search],
  });

  return query;
};
