import { LoadingOverlay } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFirebaseAuth } from "../services/firebase";

//this HOC checks if a user is logged in
//if NOT, user is redirected to login

interface IProps {
  children?: React.ReactNode;
}

export function withAuth<T extends IProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentwithAuth = (props: T) => {
    const navigate = useNavigate();
    const [, setRedirectUrl] = useLocalStorage<string | null>({
      key: "kpali-redirect-url",
      defaultValue: null,
    });
    const [user, loading] = useAuthState(getFirebaseAuth);
    useEffect(() => {
      if (loading) return; // Do nothing while loading

      if (!user) {
        const redirectUrl = window.location.pathname;
        setRedirectUrl(redirectUrl);
        navigate({
          pathname: "/login",
          search: redirectUrl,
        });
      } // If not authenticated, force log in
    }, [user, loading, navigate, setRedirectUrl]);

    if (user) {
      return <WrappedComponent {...(props as T)} />;
    }

    // Session is being fetched, or no user.
    // If no user, useEffect() will redirect.
    return <LoadingOverlay visible />;
  };

  ComponentwithAuth.displayName = `withAuth(${displayName})`;

  return ComponentwithAuth;
}
