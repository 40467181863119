import { Text } from "@mantine/core";
import { IRequestStatus, RequestStatus } from "../../../enums/enums";

const RequestStatusText = ({ status }: { status: IRequestStatus }) => {
  let bgColor, textColor;

  switch (status) {
    case RequestStatus.COMPLETED:
      bgColor = "#E2F8E5";
      textColor = "#319755";
      break;
    case RequestStatus.PENDING:
      bgColor = "#E9AA4C38";
      textColor = "#DD8705";
      break;
    case RequestStatus.REJECTED:
      bgColor = "#FFD3D9";
      textColor = "#C22839";
      break;
    case RequestStatus.CANCELLED:
      bgColor = "#FFD3D9";
      textColor = "#C22839";
      break;
    case RequestStatus.ONGOING_VERIFICATION:
      bgColor = "#FFD3D9";
      textColor = "#C22839";
      break;
    case RequestStatus.AWAITING_ASSIGNMENT:
      bgColor = "#FFD3D9";
      textColor = "#C22839";
      break;
    default:
      bgColor = "#FFD3D9";
      textColor = "#C22839";
  }

  return (
    <Text
      style={{
        borderRadius: "7.5px",
      }}
      component="span"
      bg={bgColor}
      c={textColor}
      p={"0.25em 0.5em"}
    >
      {status
        ?.split("_")
        .join(" ")
        ?.split(" ")
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")}
    </Text>
  );
};

export default RequestStatusText;
