import { Stack, Text, Anchor } from "@mantine/core";
import { toast } from "react-toastify";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthLayout from "../../layouts/auth";
import { CustomTextInput } from "../shared/inputs";
import OrangeButton from "../shared/buttons/orangeButton";
import { useForgotPassword } from "../../../hooks/auth/auth.hook";
import {
  ForgotPasswordSchema,
  ForgotPasswordDto,
} from "../../../schema/auth/auth.";

function ForgotPassword() {
  const { mutate, isPending } = useForgotPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordDto>({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const onSubmit: SubmitHandler<ForgotPasswordDto> = (data) => {
    mutate(data);
  };

  return (
    <AuthLayout title="Reset Password">
      <Stack w={"100%"}>
        <CustomTextInput
          mobileWhite
          label={"Email"}
          placeholder={"Email address"}
          register={register("email")}
          error={errors.email?.message}
        />
        <OrangeButton
          loading={isPending}
          buttonText={"Reset password"}
          onClick={handleSubmit(onSubmit)}
        />
        <Text c={{ base: "white", sm: "white", md: "dark" }}>
          Remember password? &nbsp;
          <Anchor href="/login">Login</Anchor>
        </Text>
      </Stack>
    </AuthLayout>
  );
}

export default ForgotPassword;
