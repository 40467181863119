import React, { useMemo, ReactNode, ReactElement } from "react";
import { LoadingOverlay } from "@mantine/core";
import { useCurrentWorkspace } from "../../../../../../hooks/user/user.workspace.hook";
import { WorkspaceRole, IWorkspaceRole } from "../../../../../../enums/enums";
import EmptyState from "../../../../shared/emptyState";
import LockedIcon from "../../../../../assets/svg/lockedIcon.svg";

type WorkspaceRBACKeys =
  | "canDeleteWorkspace"
  | "canViewWorkspaceMembers"
  | "canMakeRequests";

const workspaceRoleActions: Record<WorkspaceRBACKeys, IWorkspaceRole[]> = {
  canDeleteWorkspace: [WorkspaceRole.OWNER],
  canViewWorkspaceMembers: [WorkspaceRole.OWNER, WorkspaceRole.ADMIN],
  canMakeRequests: [
    WorkspaceRole.OWNER,
    WorkspaceRole.ADMIN,
    WorkspaceRole.REQUESTER,
  ],
};

const workspaceRoleAccessCheck = (
  workspaceRole: IWorkspaceRole,
  action: WorkspaceRBACKeys
) => {
  const roleActions = workspaceRoleActions[action];
  if (!roleActions) return false;
  return roleActions.some((role) => workspaceRole === role);
};

const UnauthorizedAccess = () => {
  return (
    <EmptyState
      image={LockedIcon}
      height="200px"
      width="210px"
      title="Unauthorized Access"
      description="You do not have the required permissions to perform this action."
    />
  );
};

interface WorkspaceRBACProps {
  action: WorkspaceRBACKeys;
  renderNoAccess?: ReactNode;
  children: ReactElement;
}

function WorkspaceRBAC({
  action,
  renderNoAccess,
  children,
}: WorkspaceRBACProps) {
  const { workspaceUserRole } = useCurrentWorkspace();

  const response = useMemo(() => {
    if (!workspaceUserRole) return <LoadingOverlay visible />;
    const hasAccess = workspaceRoleAccessCheck(workspaceUserRole, action);
    if (!hasAccess) {
      return renderNoAccess ? <UnauthorizedAccess /> : null;
    }
    return children;
  }, [workspaceUserRole, action, children, renderNoAccess]);
  return response;
}

export default React.memo(WorkspaceRBAC);
