import API from "../../api/api";
import { useQuery } from "@tanstack/react-query";

const URL = "/admin/workspace";

export const useGetAllWorkspaces = (page: number, limit: number) => {
  const getAllWorkspaces = async () => {
    const response = await API.get(`${URL}?page=${page}&limit=${limit}`);
    return response;
  };
  const query = useQuery({
    queryFn: getAllWorkspaces,
    queryKey: ["GetAllWorkspaces", page, limit],
  });

  return query;
};
