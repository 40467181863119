export interface IconProps {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
}

export const OverviewIcon = ({ className, width, height, fill }: IconProps) => (
  <svg
    width={width || "18"}
    height={height || "18"}
    className={className}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M11.2857 1.23459H15.8571C16.1602 1.23459 16.4509 1.3518 16.6653 1.56045C16.8796 1.76909 17 2.05207 17 2.34714V6.79736C17 7.09243 16.8796 7.37541 16.6653 7.58405C16.4509 7.7927 16.1602 7.90991 15.8571 7.90991H11.2857C10.9826 7.90991 10.6919 7.7927 10.4776 7.58405C10.2633 7.37541 10.1429 7.09243 10.1429 6.79736V2.34714C10.1429 2.05207 10.2633 1.76909 10.4776 1.56045C10.6919 1.3518 10.9826 1.23459 11.2857 1.23459ZM2.14286 1.23459H6.71429C7.01739 1.23459 7.30808 1.3518 7.52241 1.56045C7.73674 1.76909 7.85714 2.05207 7.85714 2.34714V6.79736C7.85714 7.09243 7.73674 7.37541 7.52241 7.58405C7.30808 7.7927 7.01739 7.90991 6.71429 7.90991H2.14286C1.83975 7.90991 1.54906 7.7927 1.33474 7.58405C1.12041 7.37541 1 7.09243 1 6.79736V2.34714C1 2.05207 1.12041 1.76909 1.33474 1.56045C1.54906 1.3518 1.83975 1.23459 2.14286 1.23459ZM11.2857 10.135H15.8571C16.1602 10.135 16.4509 10.2522 16.6653 10.4609C16.8796 10.6695 17 10.9525 17 11.2476V15.6978C17 15.9929 16.8796 16.2758 16.6653 16.4845C16.4509 16.6931 16.1602 16.8103 15.8571 16.8103H11.2857C10.9826 16.8103 10.6919 16.6931 10.4776 16.4845C10.2633 16.2758 10.1429 15.9929 10.1429 15.6978V11.2476C10.1429 10.9525 10.2633 10.6695 10.4776 10.4609C10.6919 10.2522 10.9826 10.135 11.2857 10.135ZM2.14286 10.135H6.71429C7.01739 10.135 7.30808 10.2522 7.52241 10.4609C7.73674 10.6695 7.85714 10.9525 7.85714 11.2476V15.6978C7.85714 15.9929 7.73674 16.2758 7.52241 16.4845C7.30808 16.6931 7.01739 16.8103 6.71429 16.8103H2.14286C1.83975 16.8103 1.54906 16.6931 1.33474 16.4845C1.12041 16.2758 1 15.9929 1 15.6978V11.2476C1 10.9525 1.12041 10.6695 1.33474 10.4609C1.54906 10.2522 1.83975 10.135 2.14286 10.135Z"
      stroke={fill || "#797979"}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

OverviewIcon.displayName = "OverviewIcon";
