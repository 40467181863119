import { Autocomplete } from "@mantine/core";
import { Search } from "tabler-icons-react";
import PropTypes from "prop-types";
import styles from "./inputs.module.css";

export function CustomSearchInput({
  disabled,
  placeholder,
  radius,
  size,
  iconLeft,
  iconRight,
  mobileWhite,
  label,
  data,
  setSearch,
  notRequired,
}: PropTypes.InferProps<typeof CustomSearchInput.propTypes>) {
  const handleSearch = (query: string) => {
    setSearch(query);
  };

  return (
    <Autocomplete
      disabled={disabled ? true : false}
      c={mobileWhite ? { base: "white", sm: "white", md: "dark.4" } : "dark.4"}
      label={label ? label : ""}
      leftSection={iconLeft ? iconLeft : null}
      rightSection={
        iconRight ? (
          iconRight
        ) : (
          <Search
            color="#797979"
            style={{
              backgroundColor: "#F2F2F2",
            }}
            size={15}
          />
        )
      }
      data={data ? data : []}
      size={size ? size : "md"}
      radius={radius ? radius : "md"}
      onChange={(query) => handleSearch(query)}
      placeholder={placeholder ? placeholder : ""}
      classNames={{
        input: styles.input,
        wrapper: styles.wrapper,
        label: styles.label,
      }}
    />
  );
}

CustomSearchInput.propTypes = {
  setSearch: PropTypes.func.isRequired,
  data: PropTypes.array,
  label: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  radius: PropTypes.string,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  mobileWhite: PropTypes.bool,
  disabled: PropTypes.bool,
  notRequired: PropTypes.bool,
};

export default CustomSearchInput;
