import API from "../../api/api";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import {
  UpdateProfileDto,
  ChangePasswordDto,
  UpdateNotificationDto,
} from "../../schema/user/user";

const URL = "/users";

export const useGetUserProfile = () => {
  const queryClient = useQueryClient();
  const getUserProfile = async () => {
    const response = await API.get(`${URL}/profile/me`);
    return response;
  };
  const query = useQuery({
    queryFn: getUserProfile,
    queryKey: ["GetUserProfile"],
  });
  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["GetUserProfile"],
      queryFn: getUserProfile,
    });
  }, [queryClient]);

  return query;
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  const updateProfile = async (data: UpdateProfileDto) => {
    const response = await API.put(`${URL}/profile/me`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      toast.success("Profile updated successfully");
      queryClient.invalidateQueries({
        queryKey: ["GetUserProfile"],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useChangePassword = () => {
  const changePassword = async (data: ChangePasswordDto) => {
    const response = await API.put(`${URL}/changepassword`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      toast.success("Password changed successfully");
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};

export const useUpdateNotification = () => {
  const queryClient = useQueryClient();
  const updateNotification = async (data: UpdateNotificationDto) => {
    const response = await API.put(`${URL}/profile/me/notifications`, data);
    return response;
  };
  const mutation = useMutation({
    mutationFn: updateNotification,
    onSuccess: () => {
      toast.success("Notification updated successfully");
      queryClient.invalidateQueries({
        queryKey: ["GetUserProfile"],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });
  return mutation;
};
